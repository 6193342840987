import {Component} from '@angular/core';
import {GedBaseComponent, IGedBaseComponent} from '../../../../../../core/base/components/ged-base/ged-base.component';
import {EquipmentModel} from '../../../../../../core/models/equipment.model';
import {FeiColumn} from '../../../../../../core/base/interfaces/fei-column.interface';
import {AuthService} from '../../../../../../core/services/auth.service';
import {GedService} from '../../../../admin/playground/docuware-playground/ged.service';
import {MatDialog} from '@angular/material/dialog';
import {FileSaverService} from 'ngx-filesaver';
import {GED_CONFIG} from '../../../../../../core/enums/ged-type.enum';
import {EquipmentService} from '../../equipment.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";

@Component({
    selector: 'vex-equipment-details-documents',
    templateUrl: './equipment-details-documents.component.html',
    styleUrls: ['./equipment-details-documents.component.scss']
})
export class EquipmentDetailsDocumentsComponent extends GedBaseComponent<EquipmentModel> implements IGedBaseComponent {

    documentTypes = ['Fiche technique', 'Plan', 'Photo', 'Autres'];
    feisColumns: Array<FeiColumn> = [
        {label: 'Nom du fichier', column: 'NOM_DU_DOCUMENT'},
        {label: 'Type de document', column: 'DOCUMENT_TYPE'},
        {label: 'Nombre de pages', column: 'DWPAGECOUNT'},
        {label: 'Date du dépôt', column: 'DATE_DU_DEPOT', type: 'date'},
        {
            label: '',
            column: 'actions',
            canUpdate: false,
            canDelete: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_EQUIPMENT),
            canDownload: this.authService.hasPermission(ENUM_PERMISSIONS.READ_EQUIPMENT)
        }
    ];
    displayedColumns = [];
    canAddDoc: boolean = false;

    constructor(public service: EquipmentService, private authService: AuthService, private gedService: GedService, public dialog: MatDialog, public fileSaver: FileSaverService) {
        super(GED_CONFIG.equipment, gedService, service, dialog, fileSaver);
        this.checkPermissions();
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    checkPermissions() {
        // TODO PERMISSION : Temporarily enabled, to be removed when permissions are implemented
        this.canAddDoc = true;

        this.authService.getCurrentUserPermissions$().subscribe((permissions) => {
            /* TODO PERMISSION

            if (permissions.includes(ENUM_PERMISSIONS.UPLOAD_HM_EQUIPMENT_DOCUMENT)) {
              this.canAddDoc = true;
            }*/
        });
    }

    initForm() {
        this.form = new FormGroup({
            documentType: new FormControl(null, [Validators.required]),
            predikId: new FormControl(this.id, [Validators.required]),
        });
        this.loading = false;
    }

}
