import {Component, OnInit} from '@angular/core';
import {scaleIn400ms} from '../../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../../@vex/animations/scale-fade-in.animation';
import {EquipmentService} from '../../equipment.service';
import {MatDialog} from '@angular/material/dialog';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import {
    EquipmentCreateUpdateDeleteComponent
} from '../../equipment-create-update-delete/equipment-create-update-delete.component';
import {MatTableDataSource} from '@angular/material/table';
import {FeiColumn} from '../../../../../../core/base/interfaces/fei-column.interface';
import icAdd from '@iconify/icons-ic/twotone-add';
import {
    EquipmentLocationsCreateUpdateDeleteComponent
} from './equipment-locations-create-update-delete/equipment-locations-create-update-delete.component';
import {
    EquipmentActivityCreateUpdateDeleteComponent
} from './equipment-activity-create-update-delete/equipment-activity-create-update-delete.component';
import {ENUM_PERMISSIONS} from '../../../../../../core/enums/permission.enum';
import {QuoteStatus} from "../../../../../../core/enums/quote-status.enum";
import {
    QuotesChangeStatusModalComponent
} from "../../../../achat/quotes/quotes-modals/quotes-change-status-modal/quotes-change-status-modal.component";
import {QuoteModel} from "../../../../../../core/models/quote-model";
import {
    EquipmentSetEndServiceDateComponent
} from "./equipment-set-end-service-date/equipment-set-end-service-date.component";
import {EquipmentModel} from "../../../../../../core/models/equipment.model";
import {MotifRelationModel} from "../../../../../../core/models/generic-motif.model";

@Component({
    selector: 'vex-equipment-details-resume',
    templateUrl: './equipment-details-resume.component.html',
    styleUrls: ['./equipment-details-resume.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class EquipmentDetailsResumeComponent implements OnInit {
    icAdd = icAdd;
    ENUM_PERMISSIONS = ENUM_PERMISSIONS;
    equipment:EquipmentModel

    dataSourceAmortization: MatTableDataSource<any>;
    displayedColumnsAmortization = [];
    feisColumnsAmortization: Array<FeiColumn> = [
        {label: 'Date de début', column: 'startDate', type: 'date'},
        {label: 'Date de fin', column: 'endDate', type: 'date'},
        {label: 'Nombre de jours', column: 'nbOfDay'},
        {label: 'Montant de l\'amortissement', column: 'amortizationAmount'},
        {label: 'Cumul de l\'amortissement', column: 'accumulatedAmount'},
        {label: 'Valeur nette de l\'équipement', column: 'netValue'},
    ];

    listOfLocationId: string[] = [];

    dataSourceLocationHistory: MatTableDataSource<any>;
    displayedColumnsLocationHistory = [];
    feisColumnsLocationHistory: Array<FeiColumn> = [
        {label: 'Identifiant technique', column: 'technicalIdentifier'},
        {label: 'Bâtiment', column: 'building', propertyDisplay: 'building.label'},
        {label: 'Salle', column: 'room', propertyDisplay: 'room.label'},
        {label: 'Etage', column: 'stage', propertyDisplay: 'room.stage.label'},
        {label: 'Surface Totale', column: 'surface', type: 'custom', status: 'enabled'},
        {label: 'Nombre de lits', column: 'nbOfBeds', propertyDisplay: 'room.nbOfBed'},
        {label: 'Date d\'ajout', column: 'addedAt', type: 'date'},
        {label: 'Date de suppression', column: 'deletedAt', type: 'date'},
    ];

    feisColumnsActivity: Array<FeiColumn> = [
        {label: 'Bâtiment', column: 'building', propertyDisplay: 'building.technicalIdentifier'},
        {label: 'Activité', column: 'activity', propertyDisplay: 'activity.label'},
        {label: 'Pourcentage', column: 'percent'},
        {label: '', column: 'actions'}
    ];
    displayedColumnsActivity = [];
    dataSourceActivity: MatTableDataSource<any>;
    displayPercentWarning = false;
    displayLocation = false;
    displayHistoryLocation = false;
    isEditable = false;
    showMotif = false;

    constructor(
        public service: EquipmentService,
        private dialog: MatDialog
    ) {
        this.displayedColumnsAmortization = this.feisColumnsAmortization.map(x => x.column);
        this.displayedColumnsLocationHistory = this.feisColumnsLocationHistory.map(x => x.column);
        this.displayedColumnsActivity = this.feisColumnsActivity.map(x => x.column);
    }

    ngOnInit(): void {
        this.initData();
    }

    initData() {
        this.service.entity$.subscribe(equipment => {
            this.equipment = equipment;
            this.equipment.operationalStatus.metrikStatus.displayLabel = this.equipment.operationalStatus.metrikStatus.reason ? `${this.equipment.operationalStatus.metrikStatus.type} - ${this.equipment.operationalStatus.metrikStatus.reason}` : this.equipment.operationalStatus.metrikStatus.type;
            this.dataSourceAmortization = new MatTableDataSource<any>(equipment?.amortization);
            this.dataSourceLocationHistory = new MatTableDataSource<any>(equipment?.locationHistory);
            this.dataSourceActivity = new MatTableDataSource<any>(equipment?.activity);
            this.displayPercentWarning = equipment?.activity.length > 0 ? equipment?.activity.reduce((p, c) => p + c.percent, 0) !== 100 : true;
            this.displayHistoryLocation = !equipment?.genericEquipment?.isFixed ;
            this.displayLocation = !equipment?.location;

            this.isEditable = ! equipment?.operationalStatus?.endServiceDate;
            if (!this.isEditable) { this.hideActionButtons(); }

            this.showMotif = this.checkEndServiceDate(equipment);
        });
    }

    async updateItem() {
        this.dialog.open(EquipmentCreateUpdateDeleteComponent, {
            data: {
                defaults: await this.service.entity,
                mode: CrudModeEnum.Update
            }
        });
    }

    async addLocation() {
        this.dialog.open(EquipmentLocationsCreateUpdateDeleteComponent, {
            data: {
                listOfLocationId: this.listOfLocationId,
                defaults: await this.service.entity,
                mode: CrudModeEnum.Create
            }
        }).afterClosed().subscribe(location => {
            if (location) {
                this.initData();
            }
        });
    }

    // async deleteItem(element: any) {
    //     this.dialog.open(EquipmentLocationsCreateUpdateDeleteComponent, {
    //         data: {
    //             name: `${element.technicalIdentifier}`,
    //             location: element,
    //             defaults: await this.service.entity,
    //             mode: CrudModeEnum.Delete
    //         }
    //     }).afterClosed().subscribe(location => {
    //         if (location) {
    //             this.initData();
    //         }
    //     });
    // }
    // async updateItemLocation($event: any) {
    //     const entity = await this.service.entity;
    //
    //     this.dialog.open(EquipmentLocationsCreateUpdateDeleteComponent, {
    //         data: {
    //             listOfLocationId: this.listOfLocationId,
    //             defaults: entity,
    //             location: entity.location,
    //             mode: CrudModeEnum.Update
    //         }
    //     }).afterClosed().subscribe((location) => {
    //         if (location) {
    //             this.initData();
    //         }
    //     });
    // }

    async updateItemActivity($event: any) {
        const equipment = await this.service.entity;
        this.dialog.open(EquipmentActivityCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Update,
                equipment,
                defaults: $event
            }
        }).afterClosed().subscribe((activity) => {
            if (activity) {
                this.initData();
            }
        });
    }

    async deleteItemActivity($event: any) {
        const equipment = await this.service.entity;
        this.dialog.open(EquipmentActivityCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Delete,
                defaults: $event,
                equipment,
                name: `${$event?.activity?.label}`
            }
        }).afterClosed().subscribe((activity) => {
            if (activity) {
                this.initData();
            }
        });

    }

    async createActivity() {
        const equipment = await this.service.entity;
        this.dialog.open(EquipmentActivityCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Create,
                equipment
            }
        }).afterClosed().subscribe((activity) => {
            if (activity) {
                this.initData();
            }
        });
    }

    displayMessage() {
        let message = '';
        if (this.displayPercentWarning) {
            message += 'Le pourcentage doit être égal à 100 %.';
        }
        if (this.displayLocation) {
            message += '\n Veuillez affecter au préalable un emplacement pour pouvoir ajouter une activité. ';
        }

        return message;
    }

    async setEndServiceDate() {
        this.dialog.open(EquipmentSetEndServiceDateComponent, {
            data: {
                defaults: await this.service.entity,
                mode: CrudModeEnum.Create
            }
        }).afterClosed().subscribe((equipment: EquipmentModel) => {
            if (equipment) {
                this.initData();
            }
        });
    }

    hideActionButtons(){
        const indexActivity = this.feisColumnsActivity.findIndex(x => x.column === 'actions');
        if (indexActivity !== -1) {
            this.feisColumnsActivity.splice(indexActivity, 1);
            this.displayedColumnsActivity = this.feisColumnsActivity.map(x => x.column);
        }
    }

    checkEndServiceDate(equipment: EquipmentModel){
        if (equipment.operationalStatus?.endServiceDate) {
            const today = this.normalizeDate(new Date());
            const endServiceDate = this.normalizeDate(new Date (equipment.operationalStatus?.endServiceDate));
            return endServiceDate && endServiceDate < today;
        } else if (equipment.operationalStatus?.metrikStatus.reason) {
            return true;
        }
        return false;
    }
    normalizeDate(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

}
