<ng-container class="h10">

    <base-form-input
            label='Statut de fonctionnement'
            controlName='metrikStatus'
            type='autocomplete'
            [items$]="metrikStatus$"
            (autocompleteChange)="metrikStatusAutocompleteChange($event)"
            displayProperty="displayLabel"
    >
    </base-form-input>

    <div
            *ngIf="form.controls.metrikStatus?.value?.isDateNeeded && !isDisabledDate"
            class="flex flex-col sm:flex-row" >
        <base-form-input
                class='sm:mr-3 flex-auto'
                label="Date de début de fonctionnement"
                type='date'
                controlName='metrikStartDate'>
        </base-form-input>
        <base-form-input
                class='sm:ml-3 flex-auto'
                type='date'
                label='Date de fin de fonctionnement'
                controlName='metrikEndDate'
                [minDate]="minEndDate"
        >
        </base-form-input>
    </div>

</ng-container>
