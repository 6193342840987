import {RandomUtils} from "../utils/random-utils";

export enum TicketStatusEnum {
    DRAFT = 'DRAFT',
    WAITING = 'WAITING',
    IN_PROGRESS = 'IN_PROGRESS',
    CLOSED = 'CLOSED',
    CANCEL = 'CANCEL',
}

export interface TicketEquipmentTranslateModel {
    status: TicketStatusEnum;
    translation: string;
}

export const ticketStatusTranslation = (status: TicketStatusEnum) => {
    switch (status) {
        case TicketStatusEnum.DRAFT.toString():
            return 'Brouillon';
        case TicketStatusEnum.WAITING.toString():
            return 'En attente de prise en charge';
        case TicketStatusEnum.IN_PROGRESS.toString():
            return 'En cours de traitement';
        case TicketStatusEnum.CLOSED.toString():
            return 'Clôturé';
        case TicketStatusEnum.CANCEL.toString():
            return 'Abandonné';
        default:
            return null;
    }
};

export const ticketStatusObject = (status: TicketStatusEnum) => {
    return {
        _id: RandomUtils.generateId(),
        value: status,
        label: ticketStatusTranslation(status)
    };
};

export enum OutOfCatalogStatusEnum {
    NEW = 'NEW',
    REJECTED = 'REJECTED',
    IN_PROGRESS = 'IN_PROGRESS',
    WAITING_CREATOR = 'WAITING_CREATOR',
    FINALIZED  = 'FINALIZED',
}

export enum ReadAsEnum {
    CREATOR = 'CREATOR',
    CREATOR_REFERENT = 'CREATOR_REFERENT',
    REFERENT_METIER = 'REFERENT_METIER',
    APPROVISIONNEUR = 'APPROVISIONNEUR',
    REFERENT_TECHNIC = 'REFERENT_TECHNIC',
    CREATOR_APPROVISIONNEUR = 'CREATOR_APPROVISIONNEUR',
    FORBIDDEN = 'FORBIDDEN',
    VALIDATOR_QUOTE = 'VALIDATOR_QUOTE',
    CREATOR_VALIDATOR_QUOTE = 'CREATOR_VALIDATOR_QUOTE',
}

