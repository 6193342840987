import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivityModel } from '../../../../../../../core/models/activity.model';
import { Observable } from 'rxjs';
import icClose from '@iconify/icons-ic/twotone-close';
import { CrudModeEnum } from '../../../../../../../core/base/enum/crud-mode.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormModalBaseComponent
} from '../../../../../../../core/base/components/form-modal-base/form-modal-base.component';
import { BuildingModel } from '../../../../../../../core/models/building.model';
import { BuildingService } from '../../../../building/building.service';
import { EquipmentModel } from '../../../../../../../core/models/equipment.model';
import { EquipmentService } from '../../../equipment.service';
import { ErrorApi } from '../../../../../../../core/models/api/error-api';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vex-equipment-activity-create-update-delete',
  templateUrl: './equipment-activity-create-update-delete.component.html',
  styleUrls: ['./equipment-activity-create-update-delete.component.scss']
})
export class EquipmentActivityCreateUpdateDeleteComponent implements OnInit {
  form: FormGroup;
  mode: CrudModeEnum;

  icClose = icClose;

  loading = false;
  defaults: any;
  successCreateMessage: string;
  successUpdateMessage: string;
  successDeleteMessage: string;
  errorCreateMessage: string;
  errorUpdateMessage: string;
  errorDeleteMessage: string;

  CrudModeEnum = CrudModeEnum;

  activity = [];
  activity$: Observable<any>;
  building: any;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      public dialogRef: MatDialogRef<FormModalBaseComponent<EquipmentModel>>,
      public service: EquipmentService,
      private buildingService: BuildingService
  ) { }

  ngOnInit(): void {
    this.mode = this.data.mode;
    this.defaults = this.data.defaults;
    this.building = this.data.equipment?.location?.building;

    this.form = new FormGroup({
      _id: new FormControl(this.defaults?._id),
      building: new FormControl( this.building, Validators.required),
      activity: new FormControl(this.defaults?.activity, Validators.required),
      percent: new FormControl(this.defaults?.percent, Validators.required)
    });

    this.activityAutocompleteChange(this.building?._id);

    // this.form.controls.building.valueChanges.subscribe(building => {
    //   if (building) {
    //     this.activityAutocompleteChange(building._id);
    //   } else {
    //     this.activity = [];
    //     this.form.controls.activity.reset();
    //   }
    // })
  }
  // Le filtre ici (activityAutocompleteChange(id)) pour le some doit être changé ? en attendant, un else est ajouté pour éviter l'erreur
  activityAutocompleteChange(id) {
    this.buildingService.findBuildingById(id).subscribe(building => {
      this.activity = building.data.activity.map(activity => {
        if (!this.data.equipment.activity.some( x => x.activity['_id'] === activity.activity._id )) {
          return activity.activity
        }
        else {
          return activity.activity;
        }
      }).filter(anyValue => typeof anyValue !== 'undefined');
    });
  }


  isCreateMode() {
    return this.mode === CrudModeEnum.Create;
  }

  isUpdateMode() {
    return this.mode === CrudModeEnum.Update;
  }

  isDeleteMode() {
    return this.mode === CrudModeEnum.Delete;
  }

  save() {
    if (this.isCreateMode()) {
      this.createItem();
    } else if (this.isUpdateMode()) {
      this.updateItem();
    }
  }

  createItem() {
    if (this.form.valid) {
      this.service.createActivity(this.data.equipment._id, this.form.getRawValue()).subscribe(
          res => {
            this.close(res.data);
          }, err => {
            this.setErrorsMessages(err.error.errors);
          }
      );
    } else {
      this.form.markAllAsTouched();
    }
  }

  updateItem() {
    console.log(this.form);
    if (this.form.valid) {
      this.service.updateActivity(this.data.equipment._id, this.form.getRawValue()).subscribe(
          res => {
            this.close(res.data);
          }, err => {
            this.setErrorsMessages(err.error.errors);
          }
      );
    } else {
      this.form.markAllAsTouched();
    }
  }

  deleteItem() {
    if (this.defaults) {
      this.service.deleteActivity(this.data.equipment._id, this.defaults._id).subscribe(
          res => {
            this.close(res);
          }, err => {
          }
      );
    }
  }

  close(res?) {
    this.dialogRef.close(res);
  }

  setErrorsMessages(errors: Array<ErrorApi>) {
    errors?.forEach(error => {
      let err = this.form.controls[error.property].errors;
      if (err === null) {
        err = {messages: []};
      }
      err.messages.push(error.message);
      this.form.controls[error.property].setErrors(err);
    });
    this.form.markAllAsTouched();
  }


}
