<div class="mt-6 flex flex-col md:flex-row md:items-start">
    <div class="flex-auto">
        <div @fadeInRight *ngIf='errors.length > 0' class="card border-l-4 border-red text-orange-700 p-4" role="alert">
            <div class="flex flex-row w-full justify-between">
                <p class="font-bold">Avertissement :</p>
<!--                <button class="text-secondary ml-4" mat-dialog-close mat-icon-button type="button">-->
<!--                    <mat-icon [icIcon]="icClose" color="warn"></mat-icon>-->
<!--                </button>-->
            </div>
            <ul class="list-disc pl-10">
                <li *ngFor="let error of errors">
                    {{error}}
                </li>
            </ul>
        </div>
        <div id="building-details-room-card" class="card mt-6">
            <div class='px-6 h-16 border-b sticky left-0' fxLayout='row' fxLayoutAlign='start center'>
                <h2 class='title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l' fxFlex='none'
                    fxHide.xs>
                    Salles
                </h2>
                <div
                    class='bg-card rounded-full border px-4'
                    fxFlex='400px'
                    fxFlex.lt-md='auto'
                    fxHide.xs
                    fxLayout='row'
                    fxLayoutAlign='start center'>
                    <ic-icon [icIcon]='icSearch' size='20px'></ic-icon>
                    <input
                        class='px-4 py-3 border-0 outline-none w-full bg-transparent'
                        placeholder='Recherche...'
                        #searching
                    >
                    <button *ngIf='searching.value' matSuffix mat-icon-button aria-label='EFFACER'
                            (click)='clearTextFilter()'>
                        <ic-icon [icIcon]='icClose' size='20px'></ic-icon>
                    </button>
                </div>

                <span fxFlex></span>

                <button class='ml-4' fxFlex='none' fxHide.gt-xs mat-icon-button type='button'>
                    <mat-icon [icIcon]='icSearch'></mat-icon>
                </button>

                <button *hasPermission="[ENUM_PERMISSION.UPDATE_BUILDING]"
                    (click)='createRoom()'
                    class='ml-4'
                    color='primary'
                    fxFlex='none'
                    mat-mini-fab
                    matTooltip='Ajouter une salle'
                    type='button'>
                    <mat-icon [icIcon]='icAdd'></mat-icon>
                </button>

                <button *hasPermission="[ENUM_PERMISSION.UPDATE_BUILDING]"
                    (click)='importItems()'
                    class='ml-4'
                    color='primary'
                    fxFlex='none'
                    mat-mini-fab
                    matTooltip='Importer'
                    type='button'>
                    <mat-icon>upload_file</mat-icon>
                </button>

            </div>

            <!-- TODO PERMISSION [updatePermission]="[ENUM_PERMISSION.UPDATE_HM_BUILDING_ROOM]"
                [deletePermission]="[ENUM_PERMISSION.UPDATE_HM_BUILDING_ROOM]"-->
            <base-data-table
                [dataSource]='dataSourceRoom'
                [displayedColumns]='displayedColumnsRoom'
                [feisColumns]='feisColumnsRoom'
                [showPaginator]='true'
                (onUpdateItem)='updateItemRoom($event)'
                (onDeleteItem)='deleteItemRoom($event)'
                (onRowClick)='openItemFloor($event)'
                (onPageEvent)='pageEvent($event)'
                (onSortEvent)='sortEvent($event)'
                [count]='count'
                [pageSize]='pageSize'
            ></base-data-table>
        </div>
    </div>
</div>
