import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icCheck from '@iconify/icons-ic/twotone-check';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icAdd from '@iconify/icons-ic/twotone-add';
import icWork from '@iconify/icons-ic/twotone-work';
import icMail from '@iconify/icons-ic/twotone-mail';
import icPerson from '@iconify/icons-ic/twotone-person';
import icPersonOutline from '@iconify/icons-ic/twotone-person-outline';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import { UserProfileService } from '../../user-profile.service';
import { UserProfileModel } from '../../../../../../core/models/user-profile.model';
import { MatTableDataSource } from '@angular/material/table';
import { FeiColumn } from '../../../../../../core/base/interfaces/fei-column.interface';
import { PageEvent } from '@angular/material/paginator';
import { AffectationModel } from '../../../../../../core/models/affectation.model';
import {
    UserProfileDetailsResumeAffectationFormComponent
} from './user-profile-details-resume-affectation-form/user-profile-details-resume-affectation-form.component';
import { MatDialog } from '@angular/material/dialog';
import { scaleIn400ms } from '../../../../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../../../../@vex/animations/fade-in-right.animation';
import { stagger40ms } from '../../../../../../../@vex/animations/stagger.animation';
import { fadeInUp400ms } from '../../../../../../../@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from '../../../../../../../@vex/animations/scale-fade-in.animation';
import { EmployeePositionModel } from '../../../../../../core/models/employee-position.model';
import {
    UserProfileDetailsResumePosteFormComponent
} from './user-profile-details-resume-poste-form/user-profile-details-resume-poste-form.component';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';
import {
    UserProfileCreateUpdateDeleteComponent
} from '../../user-profile-create-update-delete/user-profile-create-update-delete.component';
import { ENUM_PERMISSIONS } from '../../../../../../core/enums/permission.enum';
import { userProfileValidatorLevelTransalation } from '../../../../../../core/enums/user-profile-validator-seuil.enum';
import { SnackbarService } from '../../../../../../core/services/snackbar.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../../../core/services/auth.service';
import { DialogConfirmComponent } from '../../../../../../../@vex/components/dialog-confirm/dialog-confirm.component';
import icClose from '@iconify/icons-ic/twotone-close';
import { isFirstLetterVowel } from '../../../../../../../@vex/utils/method-over-text';
import { EstablishmentService } from '../../../establishment/establishment.service';

@Component({
    selector: 'vex-user-profile-details-resume',
    templateUrl: './user-profile-details-resume.component.html',
    styleUrls: ['./user-profile-details-resume.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class UserProfileDetailsResumeComponent implements OnInit, OnDestroy {

    protected subscription = new Subscription();

    ENUM_PERMISSION = ENUM_PERMISSIONS;

    icWork = icWork;
    icPhone = icPhone;
    icPersonAdd = icPersonAdd;
    icCheck = icCheck;
    icClose = icClose;
    icMail = icMail;
    icAccessTime = icAccessTime;
    icAdd = icAdd;
    icPerson = icPerson;
    icPersonOutline = icPersonOutline;
    icAssignment = icAssignment;
    icBusiness = icBusiness;

    object: UserProfileModel;
    loading = false;
    dataSourceAffectation = new MatTableDataSource<AffectationModel>();
    sortFieldAffectation: string;
    sortOrderAffectation: string;
    pageIndexAffectation: number;
    pageSizeAffectation = 0;
    displayedColumnsAffectation = ['actions'];
    dataSourceEmployeePosition = new MatTableDataSource<EmployeePositionModel>();
    sortFieldEmployeePosition: string;
    sortOrderEmployeePosition: string;
    displayedColumnsEmployeePosition = ['actions'];

    displayPostePercentWarning = false;
    displayAffectationWarning = false;

    translateValidatorMatriceLevel = '';

    feisColumnsAffectation: Array<FeiColumn> = [
        {
            label: '',
            column: 'actions',
            canUpdate: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_INTERNAL_CONTACT),
            canDelete: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_INTERNAL_CONTACT)
        },
        {label: 'Etablissement', column: 'establishment', propertyDisplay: 'establishment.label'},
        {label: 'Responsable', column: 'responsable', propertyDisplay: 'responsable.fullName', titleCase: true},
    ];

    feisColumnsEmployeePosition: Array<FeiColumn> = [
        {label: 'Libellé', column: 'label'},
        {label: 'Pourcentage', column: 'percentage'},
        {
            label: '',
            column: 'actions',
            canUpdate: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_INTERNAL_CONTACT),
            canDelete: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_INTERNAL_CONTACT)
        }
    ];

    // GED DATA
    gedForm: FormGroup;
    currentUser: UserProfileModel;
    entity: UserProfileModel;


    constructor(public userProfileService: UserProfileService,
                private activatedRoute: ActivatedRoute,
                private snackbarService: SnackbarService,
                private establishmentService: EstablishmentService,
                private authService: AuthService,
                private dialog: MatDialog) {
        this.displayedColumnsAffectation = this.feisColumnsAffectation.map(x => x.column);
        this.displayedColumnsEmployeePosition = this.feisColumnsEmployeePosition.map(x => x.column);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.gedForm = new FormGroup({
            username: new FormControl(null, [Validators.required]),
            password: new FormControl(null, [Validators.required])
        });
        this.initData();
    }

    initData(): void {

        // this.subscription.add(this.userProfileService.getGedCredential().subscribe(res => {
        //     this.gedForm.reset();
        //     this.gedForm.controls.username.setValue(res.data);
        //     // this.gedForm.controls.password.setValue(res.data);
        //     this.loading = false;
        // }));
        this.subscription.add(
            this.userProfileService.entity$.subscribe(entity => {
                this.entity = entity;
                if (entity?._id) {
                    this.gedForm.reset();
                    this.gedForm.controls.username.setValue(entity.gedCredential?.username);
                    this.dataSourceAffectation = new MatTableDataSource<any>(entity?.affectations);
                    this.dataSourceEmployeePosition = new MatTableDataSource<any>(entity?.employeePositions);
                    this.displayPostePercentWarning = entity?.employeePositions?.reduce((p, c) => p + c.percentage, 0) !== 100;
                    this.displayAffectationWarning = entity?.affectations.length === 0;
                    this.translateValidatorMatriceLevel = userProfileValidatorLevelTransalation(entity?.validatorMatriceLevel);
                }
            })
        );

        this.subscription.add(this.authService.currentUserValue$()
            .subscribe(user => {
                this.currentUser = user;
            }));

    }

    async validGedForm() {

        const entity = await this.userProfileService.entity;


        this.userProfileService.setCredentialGed(entity._id, this.gedForm.getRawValue())
            .subscribe(_ => {
                this.initData();
                // this.authService.updateGetCredential(res.data.gedCredential.username);
                this.snackbarService.success('Modification effectuée');
            });
    }

    pageEventAffectation(event: PageEvent) {
        this.pageIndexAffectation = event.pageIndex + 1;
        this.pageSizeAffectation = event.pageSize;
        this.initData();
    }

    sortEventAffectation(event: any) {
        this.sortFieldAffectation = event.active;
        this.sortOrderAffectation = event.direction;
        this.dataSourceAffectation.data.sort((a: any, b: any) => {
            if (event.active === 'responsable') {
                if (!a.responsable) {
                    return +1;
                }
                if (!b.responsable) {
                    return -1;
                }
                if (event.direction === 'asc') {
                    return a?.responsable.fullName.localeCompare(b?.responsable.fullName);
                } else {
                    return b?.responsable.fullName.localeCompare(a?.responsable.fullName);
                }
            }
            if (event.active === 'establishment') {
                if (event.direction === 'asc') {
                    return a.establishment.label.localeCompare(b.establishment.label);
                } else {
                    return b.establishment.label.localeCompare(a.establishment.label);
                }
            }
        });
        this.initData();
    }

    async updateItem() {
        this.dialog.open(UserProfileCreateUpdateDeleteComponent, {
            data: {
                defaults: await this.userProfileService.entity,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((userProfile: UserProfileModel) => {
            if (userProfile) {
                this.initData();
            }
        });
    }

    async createItemAffectation() {
        this.dialog.open(UserProfileDetailsResumeAffectationFormComponent, {
            data: {
                userProfile: await this.userProfileService.entity,
                mode: CrudModeEnum.Create,
            }
        }).afterClosed().subscribe((res) => {
            if (res?.statusCode === 201) {
                this.initData();
            }
        });
    }

    async updateItemAffectation(element: any) {
        this.dialog.open(UserProfileDetailsResumeAffectationFormComponent, {
            data: {
                userProfile: await this.userProfileService.entity,
                defaults: element,
                mode: CrudModeEnum.Update,
            }
        }).afterClosed().subscribe((res) => {
            if (res) {
                this.initData();
            }
        });
    }

    async deleteItemAffectation(element: any) {
        const openDeleteDialog = () => {
            this.dialog.open(UserProfileDetailsResumeAffectationFormComponent, {
                data: {
                    affectationId: element._id,
                    defaults: element,
                    userProfile: this.entity,
                    mode: CrudModeEnum.Delete,
                    title: `Suppression ${isFirstLetterVowel(element.establishment.label) ? 'd\'' : 'de '}${element.establishment.label}`,
                }
            }).afterClosed().subscribe((res) => {
                if (res) {
                    this.initData();
                }
            });
        };

        if (this.entity.affectAllEstablishments) {
            const dialogRef = this.dialog.open(DialogConfirmComponent, {
                data: {
                    description: 'La suppression de cette affectation va annuler l\'affectation automatique de votre profil à tout nouvel établissement.',
                    cancelText: 'Annuler',
                    validText: 'Valider',
                    title: 'Suppression d\'affectation',
                    call$: of(true) // Observable.of(true) if you're using RxJS 5.x
                }
            });

            dialogRef.afterClosed().subscribe((confirmed) => {
                if (confirmed) {
                    openDeleteDialog();
                }
            });
        } else {
            openDeleteDialog();
        }
    }

    sortEventEmployeePosition(event: any) {
        this.dataSourceEmployeePosition.data.sort((a: any, b: any) => {
            console.log(event, a);
            if (event.active === 'label') {
                if (!a.label) {
                    return +1;
                }
                if (!b.label) {
                    return -1;
                }
                if (event.direction === 'asc') {
                    return a?.label.localeCompare(b?.label);
                } else {
                    return b?.label.localeCompare(a?.label);
                }
            }
            if (event.active === 'pourcentage') {
                if (event.direction === 'asc') {
                    return a.pourcentage - b.pourcentage;
                } else {
                    return b.pourcentage - a.pourcentage;
                }
            }
        });
        this.initData();
    }

    async updateItemEmployeePosition(element: any) {
        this.dialog.open(UserProfileDetailsResumePosteFormComponent, {
            data: {
                defaults: element,
                userProfile: await this.userProfileService.entity,
                mode: CrudModeEnum.Update,
            }
        }).afterClosed().subscribe((res) => {
            if (res) {
                this.initData();
            }
        });
    }

    async deleteItemEmployeePosition(element: any) {
        this.dialog.open(UserProfileDetailsResumePosteFormComponent, {
            data: {
                userProfile: await this.userProfileService.entity,
                employeePosition: element,
                mode: CrudModeEnum.Delete,
            }
        }).afterClosed().subscribe((res) => {
            if (res) {
                this.initData();
            }
        });
    }

    async createItemEmployeePosition() {
        this.dialog.open(UserProfileDetailsResumePosteFormComponent, {
            data: {
                userProfile: await this.userProfileService.entity,
                mode: CrudModeEnum.Create,
            }
        }).afterClosed().subscribe((res) => {
            if (res) {
                this.initData();
            }
        });
    }

}
