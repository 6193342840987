import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MAX_SCROLL_PAGES } from '../../../../config/base-table.config';
import { RandomUtils } from '../../../../utils/random-utils';

interface MyObject {
    _id: number;
    label: string;
    selected?: boolean;
}

@Component({
    selector: 'form-multi-select-search',
    templateUrl: './form-search-multiple.component.html',
    styleUrls: ['./form-search-multiple.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormMultiSelectSearchComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() formGroup!: FormGroup;
    @Input() controlName!: string;
    @Input() label = 'Recherche et sélectionnez...';
    @Input() appearance: 'outline' | 'fill' = 'outline';
    @Input() displayProperty = '';
    @Input() withoutPadding = false;
    @Input() placeholder = 'Rechercher...';
    @Input() displayFn!: (data: any) => string;
    @Input() filteredStates$: Observable<MyObject[]>;
    @Input() defaultErrorMessage: any;
    @Input() invalidPatternMessage = 'Le modèle est invalide';
    @Input() customErrorMessage = '';
    @Input() selectedItems: Array<any> = [];
    @Input() isStaticData = false;

    @Output() onSelectItems = new EventEmitter();
    @Output() onScroll = new EventEmitter();
    @Output() autocompleteChange = new EventEmitter<string>();

    isLoading = false;
    hasData = true;

    searchControl = new FormControl('');
    selectAllChecked = false;

    items: MyObject[] = [];
    filteredItems: MyObject[] = [];
    formGroupSubscription!: Subscription;
    private destroy$ = new Subject<void>();

    @ViewChild('trigger', { read: ElementRef }) trigger: ElementRef;
    menuWidth: number;

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.cdr.detectChanges();
        this.adjustMenuWidth();
    }

    adjustMenuWidth(): void {
        if (this.trigger) {
            this.menuWidth = this.trigger.nativeElement.offsetWidth - 5;
        }
        this.cdr.detectChanges();
    }

    ngOnInit() {

        console.log('this.displayProperty', this.displayProperty);
        console.log('this.items', this.selectedItems);



        let lastSearchTerm = '';

        if (this.isStaticData) {
            this.filteredStates$.subscribe(items => {
                console.log('items', items);

                this.items = items.map(item => {

                    const isSelected = this.selectedItems.some(selectedItem => selectedItem._id === item._id);

                    return { ...item, selected: (item.selected || isSelected) || false };
                });

                //
                // if (formControl.value?.length > 0) {
                //     const selectedIds = formControl.value.map(element => element._id);
                //     this.items = this.items.map(item => ({ ...item, selected: selectedIds.includes(item._id) ? true : item.selected }));
                // }

                this.filteredItems = this.getSortedItems(this.items);
                this.updateFormControl(false);
            });

            this.searchControl.valueChanges.pipe(
                startWith(''),
                map(searchTerm => {
                    this.filteredItems = this.filterItem(this.getSortedItems(this.items), searchTerm);
                })
            ).subscribe();
        } else {
            this.filteredStates$.subscribe(items => {
                this.isLoading = false;

                const selectedMap = new Map(this.items.map(item => [item._id, item]));

                this.items = items.map(item => {
                    const isSelected = selectedMap.has(item._id);
                    const existingItem = selectedMap.get(item._id);

                    return {
                        ...item,
                        selected: item.selected ?? false,
                        ...(isSelected && existingItem ? existingItem : {})
                    };
                });

                this.filteredItems = lastSearchTerm
                    ? this.filterItem(this.getSortedItems(this.items), lastSearchTerm)
                    : this.getSortedItems(this.items);

                this.hasData = this.filteredItems.length > 0;
                this.cdr.detectChanges();
            });

            this.searchControl.valueChanges
                .pipe(
                    startWith(''),
                    map(searchTerm => {
                        lastSearchTerm = searchTerm;
                        const search = this.filterItem(this.getSortedItems(this.items), searchTerm);

                        if (search.length === 0) {
                            this.isLoading = true;
                            this.hasData = false;
                            this.autocompleteChange.emit(searchTerm);
                        }

                        this.filteredItems = this.filterItem(this.getSortedItems(this.items), searchTerm);
                        this.hasData = this.filteredItems.length > 0;
                    })
                )
                .subscribe(() => {
                    this.cdr.detectChanges();
                });
        }

        this.formGroupSubscription = this.formGroup.valueChanges.subscribe(() => {
            this.resetSelectionsIfFormReset();
        });
    }

    ngOnDestroy() {
        if (this.formGroupSubscription) {
            this.formGroupSubscription.unsubscribe();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    filterItem(items: MyObject[], searchTerm: string): MyObject[] {
        if (!searchTerm) {
            return items;
        }

        const lowerSearchTerm = this.normalizeString(searchTerm);
        return items.filter(item => this.normalizeString(item[this.displayProperty]).includes(lowerSearchTerm));
    }

    protected normalizeString(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }

    getSelectedItemsText(): string {
        const selectedItems = this.getSortedItems(this.items).filter(item => item.selected).map(item => item[this.displayProperty]);
        return selectedItems.length > 0 ? selectedItems.join(', ') : null;
    }

    updateFormControl(emitEvent: boolean = true) {
        const selectedItems = this.items.filter(item => item.selected);
        const formControl = this.formGroup.get(this.controlName);

        if (formControl) {
            formControl.setValue(selectedItems, { emitEvent: false });
        }
    }

    updateSelectedItems(item: MyObject, checked: boolean) {
        item.selected = checked;
        this.items = this.items.map(i => i._id === item._id ? item : i);
        this.selectAllChecked = this.items.every(i => i.selected);

        // emit event to update parent

        this.onSelectItems.emit(this.items.filter(i => i.selected));

        this.updateFormControl();
    }

    toggleSelectAll(checked: boolean) {
        this.items.forEach(item => (item.selected = checked));
        this.filteredItems = this.getSortedItems(this.items);
        this.selectAllChecked = checked;
        this.updateFormControl();
    }

    clearSearch() {
        this.searchControl.setValue('');
        this.filteredItems = this.getSortedItems(this.items);
    }

    getSortedItems(items: any[]) {
        return items.filter(x => !!x[this.displayProperty]).sort((a, b) => a[this.displayProperty].localeCompare(b[this.displayProperty]));
    }

    resetSelectionsIfFormReset() {
        const control = this.formGroup.get(this.controlName);
        if (control && !control.value) {
            this.resetSelections();
        }
    }

    resetSelections() {
        this.items.forEach(item => item.selected = false);
        this.selectAllChecked = false;
        this.clearSearch();
        this.updateFormControl();
    }

    onScrollLine(event: number) {
        const threshold = 0.2;
        const totalItems = this.items.length;
        const scrollPosition = event;

        const lowerThreshold = totalItems * threshold;
        const upperThreshold = totalItems * (1 - threshold);

        if (scrollPosition < lowerThreshold) {
            // this.getOnScrollLine.emit('previous');
        } else if (scrollPosition > upperThreshold) {
            this.onScroll.emit('next');
        }
    }

}
