<vex-page-layout>
    <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center start'>
<!--        <div class='w-full flex flex-col sm:flex-row justify-between container'>-->
<!--            <div>-->
<!--                <h1 class='title mt-0 mb-1'>Etablissement</h1>-->
<!--                <vex-breadcrumbs [crumbs]="['Etablissement']"></vex-breadcrumbs>-->
<!--            </div>-->
<!--        </div>-->
    </vex-page-layout-header>

    <!-- PREVIOUS PERMISSION
    [createPermission]="[ENUM_PERMISSIONS.CREATE_CP_ESTABLISHMENT]"
    [importPermission]="[ENUM_PERMISSIONS.IMPORT_CP_ESTABLISHMENT]"
    -->
    <vex-page-layout-content class='-mt-6'>
        <div class='card overflow-auto -mt-16'>
            <base-data-table-header
                label='Établissement(s)'
                [loading]='loading'
                [showArchives]="canShowArchivePermissions"
                (onSearchChange)='onSearchChange($event)'
                (onShowArchived)="onShowArchived($event)"
                (onClearTextFilter)='clearTextFilter()'
                (onCreate)='createItem()'
                [canImport]='true'
                (onImport)='importItem()'
                [createPermission]="[ENUM_PERMISSIONS.CREATE_ESTABLISHMENT]"
                [importPermission]="[ENUM_PERMISSIONS.CREATE_ESTABLISHMENT]"
                [canCreate]="canCreatePermissions"
            ></base-data-table-header>

            <!-- PREVIOUS PERMISSION
            [deletePermission]="[ENUM_PERMISSIONS.DELETE_CP_ESTABLISHMENT]"
            [updatePermission]="[ENUM_PERMISSIONS.UPDATE_CP_ESTABLISHMENT_INFO]"
            -->
            <base-data-table
                [count]='count'
                [pageSize]='pageSize'
                [sortField]='sortField'
                [sortOrder]='sortOrder'
                [dataSource]='dataSource'
                [displayedColumns]='displayedColumns'
                [feisColumns]='feisColumns'
                (onPageEvent)='pageEvent($event)'
                (onSortEvent)='sortEvent($event)'
                (onUpdateItem)='updateItem($event)'
                (onDeleteItem)='deleteItem($event)'
                (onArchiveItem)='archiveItem($event)'
                [routerLinkDetailRow]='routerLinkDetailRow'
            ></base-data-table>
        </div>
    </vex-page-layout-content>


</vex-page-layout>

<!--<ng-template #contentPopupTemplate>-->
<!--    <form [formGroup]='popupForm'>-->
<!--        <base-form-input-->
<!--                label='Identifiant technique'-->
<!--                type='text'-->
<!--                displayProperty='label'-->
<!--                controlName='establishment'-->
<!--        >-->
<!--        </base-form-input>-->
<!--    </form>-->
<!--</ng-template>-->
