import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    GenericProductService
} from '../../../../../../pages/application/settings/generic-product/generic-product.service';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import icSearch from '@iconify/icons-ic/twotone-search';
import icClose from '@iconify/icons-ic/twotone-close';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import { AuthService } from '../../../../../services/auth.service';

export interface AllGenerics {
    type: string;
    items: any[];
}

@Component({
    selector: 'catalog-search-bar-base',
    templateUrl: './catalog-search-bar-base.component.html',
    styleUrls: ['./catalog-search-bar-base.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CatalogSearchBarBaseComponent implements OnInit {
    @Output() fillterEvent = new EventEmitter<any>();
    @Input() showFilterButton: boolean = true;
    @Input() showOnlyPerimeterCategories: boolean = false;
    @Input() showCatalogPermission?: boolean = true;
    @Input() canSearchPermission?: boolean = true;

    // popupFilter
    @Input() popupFilter = false;
    @Input() contentPopupTemplate: TemplateRef<any>;
    @Input() countPopupFilter = 0;
    @Input() popupWidth = 400;
    @Input() popupTitle: string = "Filtres avancés";

    @Input() popupForm: FormGroup = new FormGroup({});


    @ViewChild('defaultPopupTemplate') defaultPopupTemplateRef: TemplateRef<any>;


    searchWidthFull = true;
    icSearch = icSearch;
    icFilterList = icFilterList;
    popupStatus: Array<string>;
    icClose = icClose;
    genericControl = new FormControl();
    search = new FormControl();

    generic$: Observable<any[]>;

    // matchips
    count: number = 0;


    constructor(
        private genericProductService: GenericProductService,
        private authService: AuthService,
    ) {
        this.genericControl.valueChanges.pipe(
            startWith(''),
            debounceTime(200),
            distinctUntilChanged()).subscribe((value) => {
            this.genericsAutocompleteChange(value);
        })
    }
    ngAfterViewInit() {
        if (!this.contentPopupTemplate) {
            this.contentPopupTemplate = this.defaultPopupTemplateRef;
        }
    }

    ngOnInit() {

        this.search.valueChanges
            .pipe(debounceTime(300))
            .subscribe(searchText => this.emitSearchData());

        this.genericsAutocompleteChange('');

    }

    emitSearchData() {
        const isProduct = this.checkType(this.genericControl.value);

        const searchData = {
            genericProduct: isProduct && this.genericControl.value ? this.genericControl.value._id : null,
            genericPrestation: !isProduct && this.genericControl.value ? this.genericControl.value._id : null,
            search: this.search.value,
            filters: this.popupForm.value,
        };
        const filteredSearchData = Object.fromEntries(
            Object.entries(searchData).filter(([key, value]) => value !== null)
        );
        this.fillterEvent.emit(filteredSearchData);
    }

    checkType(value) {
        switch (true) {
            case typeof value?.stockable === 'boolean': // is product
                return true;
            case value && value?.stockable === undefined: // is prestation
                return false;
            default: // is empty
                return null;
        }
    }

    genericsAutocompleteChange(state: any = '') {
        this.generic$ = this.genericProductService.findAllGenerics(null, 100, 'label', 'asc', state, { isInUserPerimeter: true }).pipe(
            map(response => {
                const generics = response.data;
                return [
                    {
                        type: 'Articles',
                        items: generics.filter(g => typeof g?.stockable === 'boolean'),
                    },
                    {
                        type: 'Prestations',
                        items: generics.filter(g => g?.stockable === undefined),
                    }
                ];
            })
        );
    }

    clearTextFilter() {
        this.search.reset();
    }

    displayGeneric(item: any): string {
        return item && item.label ? item.label : '';
    }

    clearFilter(): void {
        this.popupForm.reset();
        this.updateCount();
    }

    clearSelection(): void {
        this.genericControl.setValue(null);
        this.emitSearchData();

    }

    validateFilter() {
        // Mettez à jour le compteur
        this.updateCount();
        this.emitSearchData();
    }


    updateCount() {
        this.count = Object.values(this.popupForm.value).filter((value) => value === true).length;
    }

}
