import {Injectable} from '@angular/core';
import {
    ProductCharacteristicModel,
    ProductCompatibleEquipmentModel,
    ProductModel
} from 'src/app/core/models/product.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BaseService} from 'src/app/core/base/base.service';
import {Observable} from 'rxjs';
import {ObjectApiInterface} from '../../../../core/base/interfaces/object-api.interface';
import {catchError, map} from 'rxjs/operators';
import {TvaService} from '../../settings/tva/tva.service';

@Injectable({
    providedIn: 'root'
})
export class ProductService extends BaseService<ProductModel> {
    defaultSort = 'createdAt';

    constructor(public http: HttpClient, private tvaService: TvaService) {
        super(http, 'product');
    }

    findAll(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: any): Observable<ObjectApiInterface<any[]>> {
        // // TODO: add priceTTC in product model with aggregation


        if (sortField === 'priceTTC') {
            sortField = 'priceHT';
        }
        return super.findAll(page, perPage, sortField, sortOrder, search, filters);
    }


    findAllService(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: any): Observable<ObjectApiInterface<any[]>> {
        // // TODO: add priceTTC in product model with aggregation


        if (sortField === 'priceTTC') {
            sortField = 'priceHT';
        }
        return this.http.get<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/list-service`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '500')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
        );
    }

    // getOrderProductsPrestations(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: ProductModel | any): Observable<ObjectApiInterface<any[]>> {
    //     if (sortField === 'priceTTC') {
    //         sortField = 'priceHT';
    //     }
    //     return super.getOrderProductsPrestations(page, perPage, sortField, sortOrder, search, filters);
    // }

    findFiltered(page?: number, perPage?: number, sortField: string = this.defaultSort, sortOrder: string = 'desc', search?: string, filters?: any): Observable<ObjectApiInterface<any[]>> {
        return this.http.get<ObjectApiInterface<ProductModel[]>>(`${this.baseUrlApi}/list`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '30')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
        );
    }

    findById(id: string): Observable<ObjectApiInterface<ProductModel>> {
        return super.findById(id).pipe(map(data => {
            // if (data.data?.tva?.taux) data.data.priceTTC = data.data.priceHT + data.data.priceHT * data.data?.tva?.taux / 100;
            return data;
        }));
    }


    getsupplierRelation(_id): Observable<ObjectApiInterface<ProductModel[]>> {
        return this.http.get<ObjectApiInterface<ProductModel[]>>(`${this.baseUrlApi}/getsupplierRelation/${_id}`);
    }

    getGenericProductRelation(_id): Observable<ObjectApiInterface<ProductModel[]>> {
        return this.http.get<ObjectApiInterface<ProductModel[]>>(`${this.baseUrlApi}/getGenericProductRelation/${_id}`);
    }

    update(entity: ProductModel): Observable<ObjectApiInterface<ProductModel>> {
        return super.update(entity).pipe(map(data => {
            const tvaRate = this.tvaService.getRateByDate(data.data?.tva);
            if (tvaRate?.rate) {
                data.data.priceTTC = data.data.priceHT + data.data.priceHT * tvaRate.rate / 100;
            }
            this._entity.next(data.data);
            return data;
        }));
    }

    addCharacteristic(productId: string, data: any): Observable<ObjectApiInterface<ProductModel>> {
        return this.http.post<ObjectApiInterface<ProductModel>>(`${this.baseUrlApi}/${productId}/addCharacteristic`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    updateCharacteristic(productId: string, characteristicId: string, data: ProductCharacteristicModel): Observable<ObjectApiInterface<ProductModel>> {
        return this.http.post<ObjectApiInterface<ProductModel>>(`${this.baseUrlApi}/${productId}/updateCharacteristic/${characteristicId}`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }


    deleteCharacteristic(productId: string, dataId: any): Observable<ObjectApiInterface<ProductModel>> {
        return this.http.delete<ObjectApiInterface<ProductModel>>(`${this.baseUrlApi}/${productId}/deleteCharacteristic/${dataId}`)
            .pipe(map(x => {
                this._entity.value.characteristics = this._entity.value.characteristics.filter(y => y._id !== dataId)
                return x;
            }));
    }

    addComplatibleEquipment(productId: string, data: any) {
        return this.http.post<ObjectApiInterface<ProductModel>>(`${this.baseUrlApi}/${productId}/addCompatibleEquipment`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }

    deleteCompatibleEquipment(productId: string, compatibleEquipmentid: any) {
        return this.http.delete<ObjectApiInterface<ProductCompatibleEquipmentModel>>(`${this.baseUrlApi}/${productId}/deleteCompatibleEquipment/${compatibleEquipmentid}`)
            .pipe(map(x => {
                const entity = this._entity.value;
                entity.compatibleEquipment = entity.compatibleEquipment.filter(y => y._id !== compatibleEquipmentid);
                this._entity.next(entity);
                return x;
            }));
    }

    addGenerics(data: any) {
        return this.http.post<ObjectApiInterface<any>>(`${this.baseUrlApi}/addGenerics`, data)
            .pipe(map(x => {
                // this._entity.next(x.data);
                return x;
            }));
    }

    // import(entity: ProductModel): Observable<ObjectApiInterface<ProductModel>> {
    //      return this.http.post<ObjectApiInterface<ProductModel>>(`${this.baseUrlApi}/importProductContract`, entity);
    // }


    importProductContractItems(entity: ProductModel, contractId: string): Observable<any> {
        return this.http.post<ObjectApiInterface<ProductModel>>(`${this.baseUrlApi}/importProductContract/${contractId}`, entity);
    }

    downloadExcelTemplateProductContract(contractId: string): Observable<ObjectApiInterface<ProductModel>> {
        return this.http.get<ObjectApiInterface<ProductModel>>(`${this.baseUrlApi}/downloadProductContractTemplate/${contractId}`);
    }


    archive(entity: ProductModel): Observable<ObjectApiInterface<ProductModel>> {
        console.log("par là");

        return this.http.get<ObjectApiInterface<ProductModel>>(`${this.baseUrlApi}/archive/${entity._id}`).pipe(
            map((data) => {
                console.log(data);
                this._entity.next(data.data);
                return data;
            }),
            catchError((error) => {
                console.error('Erreur lors de l\'archivage :', error);
                throw error;
            })
        );
    }

    unarchive(entity: ProductModel): Observable<ObjectApiInterface<ProductModel>> {
        return this.http.get<ObjectApiInterface<ProductModel>>(`${this.baseUrlApi}/unarchive/${entity._id}`);
    }

    // getGenProductByGenEngagement(genEngagementId: string): Observable<ObjectApiInterface<GenericProductModel[]>> {
    //     console.log('genEngagementId', genEngagementId);
    //     return this.http.get<ObjectApiInterface<GenericProductModel[]>>(`${this.baseUrlApi}/getGenProductByGenEngagement/${genEngagementId}`);
    // }

    getProductsInTechnicalPerimeters(contractIds: any, itemIds: any) {
        return this.http.post<ObjectApiInterface<any>>(`${this.baseUrlApi}/getProductsInTechnicalPerimeters`, {contractIds, itemIds})
            .pipe(map(x => {
                // this._entity.next(x.data);
                return x;
            }));
    }

    validateGenericAdministration(data: any[]) {
        return this.http.patch<ObjectApiInterface<any>>(`${this.baseUrlApi}/validateGenericAdministration`, data)
            .pipe(map(x => {
                // this._entity.next(x.data);
                return x;
            }));
    }

}


