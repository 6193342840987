<ng-template #contractTemplate>
    <vex-page-layout >
        <vex-page-layout-header class='pb-4' fxLayout='column' fxLayoutAlign='center start'>
        </vex-page-layout-header>

        <vex-page-layout-content class='-mt-6'>
            <div class='card overflow-auto -mt-16'>
                <base-data-table-header
                        [label]='entityName'
                        [loading]='loading'
                        (onClearTextFilter)='clearTextFilter()'
                        (onSearchChange)='onSearchChange($event)'
                        (onCreate)='createItem()'
                        [createPermission]="[ENUM_PERMISSION.CREATE_CONTRACT]"
                        [templates]="[{ template: advancedFilters, position: 2, place: 'before', isShowing: this.authService.isUserDeclarant()}]"
                ></base-data-table-header>

                <base-data-table
                        [count]='count'
                        [pageSize]='pageSize'
                        [sortField]='sortField'
                        [sortOrder]='sortOrder'
                        [dataSource]='dataSource'
                        [displayedColumns]='displayedColumns'
                        [feisColumns]='feisColumns'
                        [routerLinkDetailRow]='routerLinkDetailRow'
                        [stickyTop]='true'
                        (onPageEvent)='pageEvent($event)'
                        (onSortEvent)='sortEvent($event)'
                        (onUpdateItem)='updateItem($event)'
                        (onDeleteItem)='deleteItem($event)'
                ></base-data-table>
            </div>
        </vex-page-layout-content>

        <app-legend [legendItems]="legendData"></app-legend>
    </vex-page-layout>
    <ng-template #advancedFilters>
        <button mat-raised-button color="primary" type="button" (click)="toggleSidenav()">
            {{ 'Filtres avancés' | uppercase }}
        </button>
    </ng-template>
</ng-template>

<ng-template #filters>
    <ng-container *ngIf="isFilterOpen">
        <contract-filter [sidenav]='sideNav' (onClear)="clearTextFilter()" (onFilter)="applyAdvancedFilter($event)">
        </contract-filter>
    </ng-container>
</ng-template>

<app-sidenav
    #sidenav
    [content]="contractTemplate"
    [contentSidenav]="filters">
</app-sidenav>
