import { EquipmentModel } from './equipment.model';
import { OutOfCatalogStatusEnum, ReadAsEnum, TicketStatusEnum } from '../enums/ticketStatusEnum';
import { EstablishmentModel } from './establishment.model';
import { BuildingModel } from './building.model';
import { BuildingRoomModel } from './building-room.model';
import { BaseDataTableModel } from '../base/models/base-data-table.model';
import { UserProfileModel } from './user-profile.model';
import { RequirementModel } from './requirement.model';
import { CorrectiveInterventionModel } from './corrective-intervention.model';
import { ContractModel } from './contract.model';
import { EquipmentTypeModel } from './equipment-type.model';
import { TicketTypeEnum } from '../enums/ticket-type.enum';
import { ProductModel } from './product.model';
import { CustomerGroupComptableLine } from './customer-group.model';
import { PrestationModel } from './prestation.model';
import { CategoryModel } from './category.model';
import { OrderModel } from './order.model';
import { QuoteModel } from './quote-model';
import { BasketRowStatusEnum } from '../enums/basketRowStatusEnum';
import { TicketSubStatusEnum } from '../enums/ticketSubStatusEnum';
import { AddressModel } from './address.model';
import { GenericProductModel } from './generic-product.model';
import { GenericEquipmentModel } from './generic-equipment.model';
import { TicketIncidentSubStatusEnum } from '../enums/ticketIncidentSubStatus';
import { InterventionStatusEnum } from '../enums/intervention-status.enum';
import { GenericPrestationModel } from './generic-prestation.model';

export class TicketModel extends BaseDataTableModel {
    _id?: string;
    location?: EquipmentLocationModel;
    equipment?: EquipmentModel;
    orders?: OrderModel[];
    quotes?: QuoteModel[];
    type?: TicketTypeEnum;
    description?: string;
    locationDescription?: string;
    outOfCatalogDescription?: string;
    status?: TicketStatusEnum;
    subStatus?: TicketSubStatusEnum;
    incidentSubStatus?: TicketIncidentSubStatusEnum;
    displayStatus?: string;
    technicalIdentifier?: string;
    dateSend?: Date;
    createdBy?: UserProfileModel;
    requirements?: RequirementModel[];
    requirement?: RequirementModel;
    correctiveInterventions?: CorrectiveInterventionModel[];
    preventiveInterventions?: CorrectiveInterventionModel[];
    dateSendToSupplier?: Date;
    report?: string;
    statusHistory?: StatusHistory[];
    haveContract?: boolean;
    contract?: ContractModel[];
    reason?: string;
    technicalQualification?: CorrectiveInterventionModel;
    equipmentType?: EquipmentTypeModel;
    basket?: TicketBasketModel;
    category?: CategoryModel;
    isEditionAllowed?: boolean;
    isOrderable?: boolean;
    comments?: CommentModel[];

    // descriptionClient
    customersComments?: CustomerCommentModel[];

    lastStatusUpdate?: Date;
    establishment?: EstablishmentModel;
    categories?: string[];
    deliveryAddress?: AddressModel;
    destination?: string;
    isContract?: boolean;
    genericProduct?: GenericProductModel;
    genericEquipment?: GenericEquipmentModel;
    anomalyType?: any;
    selectedRecurringBasket?: any;
    isArchive?: boolean;
    isDelete?: boolean;


    readAs?: ReadAsEnum;
    outOfCatalogTreatment?: OutOfCatalogTreatmentModel;
    isFinalized?: boolean;
    isUpdatingLines?: boolean;
    orIsCreatedByCurrentUser?: boolean;
    createdAt?: Date;
    fullName?: string;
    hasOutOfCatalogTreatment?: boolean;
    equipmentBuildingReference?: string;

}

export class StatusHistory {
    status: TicketStatusEnum;
    title: string;
    subStatus?: TicketSubStatusEnum;
    text: string;
    createdAt: Date;
    createdBy: UserProfileModel;
}

export class OutOfCatalogTreatmentModel {
    description: string;
    status: OutOfCatalogStatusEnum;
    genericObject?: GenericProductModel | GenericPrestationModel;
    deliveryAddress?: AddressModel;
    destination?: string;
    reference?: ProductModel | PrestationModel;
    isCreatorTreatment?: boolean;
}

export class EquipmentLocationModel {
    establishment: EstablishmentModel;
    building: BuildingModel;
    room: BuildingRoomModel;
}

export class TicketCommentModel {
    _id?: string;
    comment: string;
    actions?: CommentActionModel[];
    createdBy?: UserProfileModel;
    createdAt?: Date;
    updatedAt?: Date;

    isUpdateFormOpen?: boolean;
    isUrgent?: boolean;

    isDelete?: boolean;
    isReplace?: boolean;

    isWaiting?: boolean;
    reference?: string;
}

export class TicketBasketModel {
    _id?: string;
    products: BasketProduct[];
    prestations: BasketPrestation[];
    updatedAt?: Date;
    createdAt?: Date;
}

export class BasketProduct extends ProductModel {
    item?: ProductModel | string;
    selectedContract: ContractModel;
    accountingAccount: CustomerGroupComptableLine;
    _idLine: string;
    dataTableSelectDisabled: boolean;
    stock?: number;
    spotPriceChange?: boolean;
    quoteId?: string;
    status?: BasketRowStatusEnum;
    isUrgent?: boolean;
    isAllowed: boolean;
    isOutOfCatalog?: boolean;
}

export class BasketPrestation extends PrestationModel {
    item?: string | PrestationModel;
    selectedContract: ContractModel;
    accountingAccount: CustomerGroupComptableLine;
    _idLine: string;
    dataTableSelectDisabled: boolean;
    spotPriceChange?: boolean;
    quoteId?: string;
    status?: BasketRowStatusEnum;
    isUrgent?: boolean;
    isAllowed: boolean;
    isOutOfCatalog?: boolean;

    interventionId?: string;

    interventionStatus?: InterventionStatusEnum;

}

export enum CommentTicketEnum {
    TICKET_CREATOR = 'TICKET_CREATOR',
    REFERENT_TECHNIC = 'REFERENT_TECHNIC',
    REFERENT_QUALITY = 'REFERENT_QUALITY',
    TICKET_EMERGENCY = 'TICKET_EMERGENCY',
    REFERENT_METIER = 'REFERENT_METIER',
    PURCHASER = 'PURCHASER',
}

export interface CommentDataModel {
    name: string;
    type: CommentTicketEnum;
}

export interface CommentModel {
    _id: string;
    comment: string;
    actions: CommentActionModel[];
    createdBy: UserProfileModel;
    createdAt: Date;
    updatedAt: Date;
    isUpdateFormOpen: boolean;
    isUrgent: boolean;
}

export interface CustomerCommentModel {
    _id: string;
    comment: string;
    actions: CommentActionModel[];
    createdBy: UserProfileModel;
    createdAt: Date;
    updatedAt: Date;
    isUpdateFormOpen: boolean;
    isUrgent: boolean;
}

export interface CommentActionModel {
    type: CommentTicketEnum;
    visible: boolean;
    notification: boolean;
}
