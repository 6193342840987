
/*
* Fonction qui prend un tableau d'éléments de type T et une fonction de mapping de type (item: T) => U.
 */
export const safeMap = <T, U>(array: T[] | null | undefined, mapFn: (item: T) => U): U[] => {
    if (!Array.isArray(array) || array.length === 0) {
        return []; // Retourne un tableau vide si l'entrée est invalide
    }
    return array.filter((item) => item != null).map(mapFn);
};
