import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MenuItem} from '../interfaces/menu-item.interface';
import {trackById} from '../../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icMoveToInbox from '@iconify/icons-ic/twotone-move-to-inbox';
import icListAlt from '@iconify/icons-ic/twotone-list-alt';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icDoNotDisturb from '@iconify/icons-ic/twotone-do-not-disturb';
import icOfflineBolt from '@iconify/icons-ic/twotone-offline-bolt';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icLock from '@iconify/icons-ic/twotone-lock';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icOutlineSettings from '@iconify/icons-ic/outline-settings';
import {Icon} from '@visurel/iconify-angular';
import {PopoverRef} from '../../../../components/popover/popover-ref';
import {AuthService} from '../../../../../app/core/services/auth.service';
import {Observable} from 'rxjs';
import {UserModel} from '../../../../../app/core/models/user.model';
import {UserProfileModel} from '../../../../../app/core/models/user-profile.model';
import {UserProfileMode} from '../../../../../app/core/enums/user-profile.mode.enum';
import {Router} from '@angular/router';
import {ENUM_PERMISSIONS} from '../../../../../app/core/enums/permission.enum';

export interface OnlineStatus {
    id: 'online' | 'away' | 'dnd' | 'offline';
    label: string;
    icon: Icon;
    colorClass: string;
}

@Component({
    selector: 'vex-toolbar-user-dropdown',
    templateUrl: './toolbar-user-dropdown.component.html',
    styleUrls: ['./toolbar-user-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

    switchModeLoading = false;
    displaySettings = false
    displaySettingsIcon = false;

    ENUM_PERMISSION = ENUM_PERMISSIONS;


    icListAlt = icListAlt;
    icMoveToInbox = icMoveToInbox;
    UserProfileMode = UserProfileMode;

    items: MenuItem[] = [
        // {
        //   id: '1',
        //   icon: icAccountCircle,
        //   label: 'Espace Admin',
        //   description: 'Personal Information',
        //   colorClass: 'text-teal',
        //   route: ''
        // },
        {
            id: '2',
            icon: icMoveToInbox,
            label: 'My Inbox',
            description: 'Messages & Latest News',
            colorClass: 'text-primary',
            route: ''
        },
        {
            id: '3',
            icon: icListAlt,
            label: 'Espace Admin',
            description: 'Réservé à la partie Core',
            colorClass: 'text-amber',
            route: ''
        },
        {
            id: '1',
            icon: icListAlt,
            label: 'Mon profile',
            description: 'Réservé à la partie Core',
            colorClass: 'text-amber',
            route: ''
        },
        // {
        //   id: '4',
        //   icon: icTableChart,
        //   label: 'Billing Information',
        //   description: 'Pricing & Current Plan',
        //   colorClass: 'text-purple',
        //   route: ''
        // }
    ];

    statuses: OnlineStatus[] = [
        {
            id: 'online',
            label: 'Online',
            icon: icCheckCircle,
            colorClass: 'text-green'
        },
        {
            id: 'away',
            label: 'Away',
            icon: icAccessTime,
            colorClass: 'text-orange'
        },
        {
            id: 'dnd',
            label: 'Do not disturb',
            icon: icDoNotDisturb,
            colorClass: 'text-red'
        },
        {
            id: 'offline',
            label: 'Offline',
            icon: icOfflineBolt,
            colorClass: 'text-gray'
        }
    ];

    activeStatus: OnlineStatus = this.statuses[0];

    trackById = trackById;
    icPerson = icPerson;
    icSettings = icOutlineSettings;
    icChevronRight = icChevronRight;
    icArrowDropDown = icArrowDropDown;
    icBusiness = icBusiness;
    icVerifiedUser = icVerifiedUser;
    icLock = icLock;
    icNotificationsOff = icNotificationsOff;
    icOutlineSettings = icOutlineSettings;

    user$ = new Observable<UserModel>();
    userProfile$ = new Observable<UserProfileModel>();
    accesCustomerGroupPermission: boolean = false;

    constructor(private cd: ChangeDetectorRef,
                private router: Router,
                public popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
                public authService: AuthService
    ) {
    }

    async ngOnInit() {
        this.user$ = this.authService.currentUserCoreValue();
        this.userProfile$ = this.authService.currentUserValue$();

        this.setDisplaySettings();


    }

    setDisplaySettings() {
        this.authService.currentUser.subscribe(user => {
            if (user && user.isPredik) {
                this.displaySettings = this.displaySettingsIcon =  this.accesCustomerGroupPermission =  true;

            }else {
                this.displaySettings = this.displaySettingsIcon =  this.accesCustomerGroupPermission =  false;
            }
        })
    }

    setStatus(status: OnlineStatus) {
        this.activeStatus = status;
        this.cd.markForCheck();
    }

    async close(item: MenuItem) {
        console.log(item);
        if (item.id === '3') {
            console.log('ici');
            this.switchModeLoading = true;
            this.authService.switchMode().subscribe(res => {
                console.log(res);
                window.location.reload();
            })
        } else {
            this.popoverRef.close();
        }
    }

    async switchMode() {
        this.switchModeLoading = true;
        this.authService.switchMode().subscribe(res => {
            console.log(res);
            window.location.reload();
        })
    }

    goCustumerGroupSettings() {
        this.router.navigate(['settings/customerGroup']).then(() => {
            this.popoverRef.close();
        });
    }
}
