export enum EquipmentStatusEnum {
    TO_COMPLETE = 'TO_COMPLETE',
    COMPLETED = 'COMPLETED',
}

export interface EquipmentStatusEnumTranslate {
    status: EquipmentStatusEnum;
    translation: string;
}


export const EquipmentStatusEnumTranslation = (status: EquipmentStatusEnum): string => {
    switch (status) {
        case EquipmentStatusEnum.TO_COMPLETE.toString():
            return 'À compléter';
        case EquipmentStatusEnum.COMPLETED.toString():
            return 'Complété';
        default:
            return null;
    }
}
