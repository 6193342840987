import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { CrudModeEnum } from '../../../enum/crud-mode.enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DEFAULT_SCROLL_SIZE } from '../../../../config/base-table.config';
import { map } from 'rxjs/operators';
import { Duplicate } from '../../../../utils/duplicate-utils';
import { ScrollManager } from '../../../../services/scroll-manager.service';
import { ObjectApiInterface } from '../../../interfaces/object-api.interface';
import { SnackbarService } from '../../../../services/snackbar.service';
import { AutocompleteService } from '../../../../services/scroll.service';

@Component({
    selector: 'base-sidenav',
    templateUrl: './sidenav-base-filter.component.html',
})
export  class SidenavBaseFilterComponent implements OnInit, OnDestroy {
    @Input() sidenav: MatSidenav;
    @Input() standardForms: TemplateRef<any>;
    @Output() onFilter = new EventEmitter<any>();
    @Output() onClear = new EventEmitter<any>();
    @Output() onValidate = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();
    mode: CrudModeEnum = CrudModeEnum.Filter;
    filters: any = {};
    forms: { [key: string]: FormGroup } = {};

    states: {
        [key: string]: {
            subject: BehaviorSubject<any[]>;
            paginationManager: ScrollManager;
            currentPage: number;
        };
    } = {};

    constructor(
        protected snackbarService: SnackbarService,
        protected autocompleteService: AutocompleteService,
    ) {
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {}

    initialize() {}
    buildCustomFilters(){ return null; }

    validate() {
        const customFilters = this.buildCustomFilters();

        const queryString = new URLSearchParams(customFilters).toString();
        const url = `https://predik.eu.loclx.io/dossier/entite/numeroEntite?${queryString}`;
        const maxUrlLength = 2000;

        if (url.length > maxUrlLength) {
            this.snackbarService.danger(
                `Les filtres sont trop volumineux pour une requête GET (${url.length} caractères). Veuillez réduire les critères de recherche.`
            );
            return;
        }

        this.filters = this.validateCustomFilters(customFilters);

        this.onFilter.emit(this.filters);

        this.close();
    }

    validateCustomFilters(customFilters: any): any {
        return Object.keys(customFilters).reduce((acc, key) => {
            const value = customFilters[key];

            if (this.isValidFilter(value, key)) {
                acc[key] = value;
            }

            return acc;
        }, {});
    }

    isValidFilter(value: any, key: string): boolean {
        const isDateFilterValid = (filter: any) => filter && (filter.min || filter.max);

        return value !== null &&
            value !== undefined &&
            value !== '' &&
            (
                typeof value === 'boolean' ||
                (Array.isArray(value) ? value.length > 0 : true) &&
                (
                    typeof value !== 'object' ||
                    (key.includes('Date') ? isDateFilterValid(value) :
                        Object.keys(value).some(k => value[k]))
                )
            );
    }

    clear() {
        Object.keys(this.forms).forEach(formKey => {
            const form = this.forms[formKey];
            if (form instanceof FormGroup) {
                Object.keys(form.controls).forEach(controlKey => {
                    const control = form.controls[controlKey];
                    control.setValue('');
                    control.markAsPristine();
                    control.markAsUntouched();
                });
            }
        });
        this.filters = {};
        this.onFilter.emit(this.filters);
        this.onClear.emit();
    }

    addForm(key: string, formGroup: FormGroup) {
        this.forms[key] = formGroup;
    }

    close() {
        this.sidenav.close();
    }

    getFormGroup(key: string) {
        return this.forms[key];
    }

    getItems$(key: string): Observable<any[]> {
        return this.autocompleteService.getItems$(key);
    }
}
