import {
    SidenavBaseFilterComponent
} from '../../../../../core/base/components/sidenav/base-filter/sidenav-base-filter.component';
import { AutocompleteService } from '../../../../../core/services/scroll.service';
import { AuthService } from '../../../../../core/services/auth.service';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SupplierService } from '../../supplier/supplier.service';
import { safeMap } from '../../../../../core/utils/array-utils';
import { SupplierModel } from '../../../../../core/models/supplier.model';
import { PrestationService } from '../prestation.service';
import { GenericPrestationService } from '../../../settings/generic-prestation/generic-prestation.service';
import { PrestationModel } from '../../../../../core/models/prestation.model';
import { GenericPrestationModel } from '../../../../../core/models/generic-prestation.model';

@Component({
    selector: 'prestation-filter',
    templateUrl: './prestation-filter.component.html',
    styleUrls: ['./prestation-filter.component.scss']
})
export class PrestationFilterComponent extends SidenavBaseFilterComponent {

    constructor(
        public authService: AuthService,
        public snackbarService: SnackbarService,
        public autocompleteService: AutocompleteService,
        public supplierService: SupplierService,
        public genericPrestations: GenericPrestationService,
        public prestationService: PrestationService,
    ) {
        super(snackbarService, autocompleteService);

        this.addForm('standardForm', new FormGroup({
            technicalIdentifier: new FormControl(),
            reference: new FormControl(),
            genericPrestations: new FormControl(),
            suppliers: new FormControl(),
            labels: new FormControl(),
            purchaseUnit : new FormControl(),
            isAvailable: new FormControl(),
        }));
    }

    buildCustomFilters(): any {
        const { labels, genericPrestations, suppliers, purchaseUnit, isAvailable, ...rest}
            = this.forms.standardForm.getRawValue();

        return {
            ...rest,
            genericPrestations: safeMap(genericPrestations, (x: GenericPrestationModel) => x._id),
            suppliers: safeMap(suppliers, (x: SupplierModel) => x._id),
            labelList: safeMap(labels, (x: PrestationModel) => x.label),
            purchaseUnit: purchaseUnit?.value,
            isAvailable: isAvailable?.value
        };
    }

    initialize() {
        this.autocompleteSuppliers();
        this.autocompleteGenericPrestations();
        this.autocompletePrestationFields('', 'label');
    }

    autocompleteSuppliers(search: string = '', pageToGo?: 'next' | 'previous') {
        this.autocompleteService.autocompleteInfiniteScroll(
            search,
            'suppliers',
            '_id',
            pageToGo,
            (page, size) =>
                this.supplierService.findAll(page, size, 'label', 'asc', search)
        )?.subscribe();
    }

    autocompleteGenericPrestations(search: string = '', pageToGo?: 'next' | 'previous') {
        this.autocompleteService.autocompleteInfiniteScroll(
            search,
            'genericPrestations',
            '_id',
            pageToGo,
            (page, size) =>
                this.genericPrestations.findAll(page, size, 'label', 'asc', search)
        )?.subscribe();
    }

    autocompletePrestationFields(search: string = '', filterName: string, pageToGo?: 'next' | 'previous') {
        const data = this.autocompleteService.autocompleteInfiniteScroll(
            search,
            filterName,
            filterName,
            pageToGo,
            (page, size) =>
                this.prestationService.findAll(page, size, filterName, 'asc', search, {
                    onlyDistinctField: filterName,
                    requiredFields: [filterName]
                })
        );

        data.subscribe();
    }

    onScrollLine(pageToGo: 'next' | 'previous', filterField: string) {
        switch (filterField) {
            case 'genericPrestations':
                this.autocompleteGenericPrestations('', pageToGo);
                break;
            case 'suppliers':
                this.autocompleteSuppliers('', pageToGo);
                break;
            case 'label':
                this.autocompletePrestationFields('', filterField, pageToGo);
                break;
            default:
                console.error('Unknown filterField:', filterField);
        }
    }
}
