import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { SafeHtml, SafeValue } from '@angular/platform-browser';

@Component({
  selector: 'vex-banner-message',
  templateUrl: './banner-message.component.html',
  styleUrls: ['./banner-message.component.scss']
})
export class BannerMessageComponent implements OnInit {

  @Input() text: string | SafeHtml;
  @Input() alertType: 'warn' | 'alert' | 'default' = 'default';
  @Input() textButton: string;
  @Input() showButton = false;
  @Input() noMargin = false;
  @Input() callButton: () => any;

  colorButton: ThemePalette = 'primary';

  private alertColors: { [key: string]: ThemePalette } = {
    alert: 'warn',
    warn: 'warn',
    default: 'primary'
  };

  constructor() { }

  ngOnInit(): void {
    this.setColorButton();
    // if text is SafeHtml convert to string
  }

  private setColorButton(): void {
    this.colorButton = this.alertColors[this.alertType] || this.alertColors.default;
  }

  getBorderClasses(): string {
    let classVar = 'card border-l-4 p-4 mt-4'
    if (this.noMargin) {
      classVar = 'border-l-4 p-4';
    }
    switch (this.alertType) {
      case 'alert':
        return `${classVar} border-red`;
      case 'warn':
        return `${classVar} border-yellow`;
      default:
        return `${classVar} border-blue`; // J'ai ajouté l'accent fermant ici
    }
  }
}
