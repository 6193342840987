import {Component, OnInit} from '@angular/core';
import {DetailsBaseComponent} from '../../../../../core/base/components/details-base/details-base.component';
import {ActivatedRoute} from '@angular/router';
import {UserProfileService} from '../user-profile.service';
import {UserProfileModel} from '../../../../../core/models/user-profile.model';
import {scaleIn400ms} from '../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../@vex/animations/scale-fade-in.animation';
import {CategoryService} from '../../../settings/category/category.service';
import {CustomerGroupService} from '../../../settings/customer-group/customer-group.service';
import {Location} from '@angular/common'
import {AuthService} from "../../../../../core/services/auth.service";
import {ENUM_PERMISSIONS} from "../../../../../core/enums/permission.enum";

@Component({
    selector: 'vex-user-profile-details',
    templateUrl: './user-profile-details.component.html',
    styleUrls: ['./user-profile-details.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class UserProfileDetailsComponent extends DetailsBaseComponent<UserProfileModel> implements OnInit {

    defaultTabs = [
        {
            label: 'INFORMATIONS',
            route: './',
            routerLinkActiveOptions: {exact: true},
            permission: ENUM_PERMISSIONS.READ_INTERNAL_CONTACT,
        },
        {
            label: 'PÉRIMÈTRE E-SHOP360',
            route: './technical-assignation',
            permission: ENUM_PERMISSIONS.READ_INTERNAL_CONTACT,
            additionalCondition: this.authService.currentUserValue().isPredik
        },
        {
            label: 'ROLES',
            route: './roles',
            permission: ENUM_PERMISSIONS.READ_INTERNAL_CONTACT,
            additionalCondition: this.authService.currentUserValue().isPredik
        },
        {
            label: 'VALIDATION DE COMMANDES',
            route: './command-validator',
            permission: ENUM_PERMISSIONS.READ_INTERNAL_CONTACT,
            additionalCondition: this.authService.currentUserValue().isPredik
        }
    ];
    constructor(
        public service: UserProfileService,
        public categoryService: CategoryService,
        private customerGroupService: CustomerGroupService,
        public activatedRoute: ActivatedRoute,
        private location: Location,
        public authService: AuthService
    ) {
        super(service, activatedRoute, authService);
    }

    ngOnInit() {
        super.ngOnInit();

        let init = false;

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            if (permissions.includes(ENUM_PERMISSIONS.READ_INTERNAL_CONTACT)) {
                this.categoryService.findByLevel(0).subscribe();
                this.service.entity$.subscribe(userProfile => {
                    if (userProfile?._id && !init) {
                        init = true;
                        // this.service.getValidator(userProfile._id).subscribe();
                    }
                });
            }
        });
    }

    back() {
        this.location.back();
    }
}
