export enum GenericMotifTypesEnum {
    REFUSAL = 'Refus',
    CLOSURE = 'Clôture',
    AMENDMENT = 'Avenant',
    LITIGATION = 'Litige',
    ORDER = 'Commande',
}

export enum GenericMotifModuleEnum {
    CONTRACT = 'Contrat',
    QUOTATION = 'Devis',
    E_TICKET = 'E-Ticket',
    E_SHOP = 'Eshop',
    ORDER = 'Commande',
    BILL = 'Facture',
}

export class GenericReasonRelation {
    genericMotifId: string;
    comment?: string;
    label: string;
}
