import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {
    GenericProductService
} from '../../../../../../pages/application/settings/generic-product/generic-product.service';
import {debounceTime, distinctUntilChanged, filter, map, startWith} from 'rxjs/operators';
import icSearch from '@iconify/icons-ic/twotone-search';
import icClose from '@iconify/icons-ic/twotone-close';
import {AuthService} from '../../../../../services/auth.service';

export interface AllGenerics {
    type: string;
    items: any[];
}

@Component({
    selector: 'catalog-big-search-bar-base',
    templateUrl: './catalog-big-search-bar-base.component.html',
    styleUrls: ['./catalog-big-search-bar-base.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CatalogBigSearchBarBaseComponent implements OnInit {
    @Output() fillterEvent = new EventEmitter<any>();
    @Input() showFilterButton = true;
    @Input() showOnlyPerimeterCategories = false;
    @Input() showCatalogPermission = true;
    @Input() canSearchPermission = true;

    // popupFilter
    @Input() popupFilter = false;
    @Input() contentPopupTemplate: TemplateRef<any>;
    @Input() countPopupFilter = 0;
    @Input() popupWidth = 400;
    @Input() popupTitle = 'Filtres avancés';
    @Input() popupForm: FormGroup = new FormGroup({});


    @ViewChild('defaultPopupTemplate') defaultPopupTemplateRef: TemplateRef<any>;


    icSearch = icSearch;
    popupStatus: Array<string>;
    icClose = icClose;
    genericControl = new FormControl();
    searchLabel = new FormControl();
    searchSupplier = new FormControl();

    generic$: Observable<any[]>;

    // matchips
    count = 0;


    constructor(
        private genericProductService: GenericProductService,
        private authService: AuthService,
    ) {
        this.genericControl.valueChanges.pipe(
            startWith(''),
            debounceTime(200),
            distinctUntilChanged()).subscribe((value) => {
                this.genericsAutocompleteChange(value);
        });
    }

    ngAfterViewInit() {
        if (!this.contentPopupTemplate) {
            this.contentPopupTemplate = this.defaultPopupTemplateRef;
        }
    }

    ngOnInit() {

        this.searchLabel.valueChanges
            .pipe(debounceTime(300))
            .subscribe(searchText => {
                    this.emitSearchData();
                }
            );

        this.searchSupplier.valueChanges
            .pipe(debounceTime(300))
            .subscribe(searchText => {
                    this.emitSearchData();
                }
            );

        this.genericsAutocompleteChange('');
    }

    emitSearchData() {

        const isProduct = this.checkType(this.genericControl.value);

        const searchData = {
            genericProduct: isProduct ? this.genericControl.value._id : null,
            genericPrestation: !isProduct && isProduct !== null ? this.genericControl.value._id : null,
            supplierSearch: this.searchSupplier.value,
            labelSearch: this.searchLabel.value,
            filters: this.popupForm.value,
        };
        const filteredSearchData = Object.fromEntries(
            Object.entries(searchData).filter(([key, value]) => value !== null)
        );
        this.fillterEvent.emit(filteredSearchData);
    }

    checkType(value) {
        switch (true) {
            case typeof value?.stockable === 'boolean': // is product
                return true;
            case value && value?.stockable === undefined: // is prestation
                return false;
            default: // is empty
                return null;
        }
    }
    genericsAutocompleteChange(state: any = '') {
        this.generic$ = this.genericProductService.findAllGenerics(null, 100, 'label', 'asc', state, { isInUserPerimeter: true }).pipe(
            map(response => {
                const generics = response.data;
                return [
                    {
                        type: 'Articles',
                        items: generics.filter(g => typeof g?.stockable === 'boolean'),
                    },
                    {
                        type: 'Prestations',
                        items: generics.filter(g => g?.stockable === undefined),
                    }
                ];
            })
        );
    }


    displayGeneric(item: any): string {
        return item && item.label ? item.label : '';
    }

    clearSelection(): void {
        this.genericControl.setValue(null);
        this.emitSearchData();

    }

    updateCount() {
        this.count = Object.values(this.popupForm.value).filter((value) => value === true).length;
    }
}
