<div class='card'>
    <div class='p-4 border-b'>
        <h2 class='title m-0 mr-2'>Actions</h2>
    </div>
    <div class='p-4 flex flex-col'>
        <div class='w-full' *ngFor="let action of actions">
            <button *ngIf='action.displayBtn' [disabled]='action.disabled' class='w-full mb-4' matSuffix mat-raised-button color='primary' (click)='action.click()'>
                {{ action.label }}
            </button>
        </div>
    </div>
</div>
