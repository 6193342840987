import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef
} from '@angular/core';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icEmail from '@iconify/icons-ic/twotone-email';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import {AbstractControl, FormGroupDirective, ValidationErrors, ValidatorFn} from '@angular/forms';
import {InputTypeInterface} from '../../interfaces/input-type.interface';
import {CrudModeEnum} from '../../enum/crud-mode.enum';
import {ValidatorErrorMessageInterface} from '../../interfaces/validator-error-message.interface';
import {Observable, Subscription, tap} from 'rxjs';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import {debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {requireMatchHelper} from '../../../helpers/requireMatch.helper';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { isArray } from 'chart.js/helpers';


/**
 * @description
 * @separator could any char between properties
 * @every is a boolean to know if we should add separator between each properties
 */
export interface IDisplayPropertyDecorator {
    separator: string;
    every: boolean;
}

@Component({
    selector: 'base-form-input',
    templateUrl: 'form-input-base.component.html',
    styleUrls: ['form-input-base.component.scss'],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'fr'},
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {
            provide: MAT_DATE_FORMATS, useValue: {
                parse: {
                    dateInput: 'DD/MM/YYYY',
                },
                display: {
                    dateInput: 'DD/MM/YYYY',
                    monthYearLabel: 'DD/MM/YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: '   DD/MM/YYYY'
                },
            }
        }
    ],
})


export class FormInputBaseComponent implements OnInit, OnChanges, OnDestroy {

    protected subscription = new Subscription();

    @Input() controlName: string;
    @Input() label: string;
    @Input() type: typeof InputTypeInterface = 'text';
    @Input() appearance: MatFormFieldAppearance = 'fill';
    @Input() icon: object | string;
    @Input() obscure = false;
    @Input() hint: string;
    @Input() minNumber = 0;
    @Input() maxNumber: number;
    @Input() saveButtonLabel = 'ENREGISTRER';
    @Input() updateButtonLabel = 'MODIFIER';
    @Input() customValidateButtonLabel: string;
    @Input() invalidPatternMessage = 'Valeur invalide';
    @Input() customErrorMessage = 'Valeur invalide';
    @Input() customMultipleSelectedTemplate: TemplateRef<any> = null;
    @Input() selectedItems: Array<any> = [];

    @Input() inputTextWidthAuto = false;
    @Input() inputTextHeightAuto = false;

    @Input() dateStartAt: Date;
    @Input() dateEndAt: Date;
    @Input() minDate: Observable<Date> | Date;
    @Input() maxDate: Date;

    @Input() loading = false;
    @Input() items: Array<any>;
    @Input() items$: Observable<any>;
    @Input() displayProperty: string;
    @Input() isStringArray = false;
    @Input() displayPropertyObject: (value: any) => string;
    @Input() autocompletePanelWidthAuto: any = false;
    @Input() autocompleteSearchIgnoreAccents = true;
    @Output() autocompleteChange = new EventEmitter();
    @Output() optionChange = new EventEmitter();
    @Output() onSelectItems = new EventEmitter();

    @Input() mode: CrudModeEnum;
    @Input() cancelLabel = 'ANNULER';
    @Input() showCancelButton = true;
    @Input() entityLabel: string;
    @Input() placeholder = '';

    @Input() disabled = false;
    @Input() readonly = false;

    @Output() onFilter = new EventEmitter();
    @Output() onCreate = new EventEmitter();
    @Output() onUpdate = new EventEmitter();
    @Output() onDelete = new EventEmitter();
    @Output() onImport = new EventEmitter();
    @Output() onLostFocus = new EventEmitter();
    @Output() onScroll = new EventEmitter();

    @Input() ngModel: any;
    @Input() isStaticData: boolean;

    @Input() disableAutoSelect = false;
    @Input() forceUpperCase = false;

    CrudModeEnum = CrudModeEnum;

    filteredStates$: Observable<any>;
    @Input() dateFilter;

    icMoreVert = icMoreVert;
    icArrowDropDown = icArrowDropDown;
    icClose = icClose;
    icPrint = icPrint;
    icDownload = icDownload;
    icDelete = icDelete;
    icPerson = icPerson;
    icPhone = icPhone;
    icEmail = icEmail;
    icVisibility = icVisibility;
    icVisibilityOff = icVisibilityOff;

    visible = false;
    isCleared = false;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    defaultErrorMessage: ValidatorErrorMessageInterface;

    chipsValues: any[] = [];

    @Input() isFromContract = false;
    @Output() dateChanged = new EventEmitter<MatDatepickerInputEvent<Date>>();

    @Input() defaultValue: any;
    @Input() isInteger = false;
    @Input() typeSelection: 'number' | 'date' = 'number';
    @Input() selection: 'between' | 'fixed' | 'greaterThen' | 'lessThen' = 'between';
    @Input() withoutPadding: false;

    @Input() isNegativeNumber = false;

    constructor(
        public fcd: FormGroupDirective,
    ) {
    }

    ngOnInit() {

        this.setupDatePickerFilter();
        // this.setValidatorErrorMessage();
        if (this.forceUpperCase) {
            this.setToUpperCase();
        }

        if (this.defaultValue !== undefined) {
            this.fcd.form.get(this.controlName)?.setValue(this.defaultValue);
        }

        if (this.isInteger) {
            this.fcd.form.get(this.controlName)?.setValidators([this.integerValidator()]);
        }
    }

    toggleInput() {
        if (this.visible) {
            this.type = 'password';
            this.visible = false;
        } else {
            this.type = 'text';
            this.visible = true;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.label) {
            this.setValidatorErrorMessage();
        }

        if (changes.disabled) {
            this.setDisabled();
        }

        if (changes.items || changes.items$) {
            this.setAutoCompleteState();
            this.setSelectState();
        }

        if (changes.defaultValue) {
            this.fcd.form.get(this.controlName)?.setValue(this.defaultValue);
        }

        if (changes.isInteger && this.isInteger) {
            this.fcd.form.get(this.controlName)?.setValidators([this.integerValidator()]);
        }
    }

    private setValidatorErrorMessage() {
        this.defaultErrorMessage = {
            required: `Le champ ${this.label} est obligatoire`,
            incorrect: `Le champ ${this.label} n'est pas correct`,
            email: `Le champ ${this.label} n'est pas valide`,
            min: `${this.label} doit être supérieur ou égale à`,
            max: `${this.label} doit être inférieur ou égale à`,
            minLength: `${this.label} doit être supérieur à`,
            maxLength: `${this.label} doit être inférieur à`,
            isInteger: `${this.label} doit être un nombre entier`,
        };
    }

    private setAutoCompleteState() {
        if (this.type === 'autocomplete' || this.type === 'autocomplete-multiple' || this.type === 'search-multiple') {
            if (this.items$) {

                this.fcd?.form?.controls[this.controlName].addValidators(requireMatchHelper);
                this.filteredStates$ = this.fcd?.form?.controls[this.controlName]?.valueChanges.pipe(
                    startWith(''),
                    debounceTime(300),
                    switchMap(state => this._filter(state))
                );
            }

            if (this.items) {

                this.fcd?.form?.controls[this.controlName].addValidators(this.requireMatchHelperItem());
                this.filteredStates$ = this.fcd.form.controls[this.controlName].valueChanges.pipe(
                    startWith(''),
                    debounceTime(500),
                    map(state => this._filter(state))
                );

            }
        }
    }

    private setSelectState() {
        if (this.type === 'select' && this.controlName) {
            this.setFirstItem(this.items);
        }
    }

    _filter(value: any): Observable<any> | Array<any> {
        // if value is not string, return all items


        if (typeof value !== 'string') {
            return this.items;
        }

        function removeAccents(string: string) {
            if (typeof string === 'string') {
                return string?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            }

            return string;
        }

        if (value && this.autocompleteSearchIgnoreAccents) {
            value = removeAccents(value);
        }

        // this.isCleared = false;
        if (this.items$) {
            if (!value) {
                return this.items$.pipe(
                    tap(x => this.setFirstItem(x))
                );
            }

            this.autocompleteChange.emit(value);

            if (this.displayProperty) {
                return this.items$.pipe(
                    map(x => x.filter(s => {
                        let target = this.getProperties(s).toLowerCase();

                        if (this.autocompleteSearchIgnoreAccents) {
                            target = removeAccents(target);
                            return target.includes(removeAccents(value.toString().toLowerCase()));
                        }

                        return target.includes(value.toString().toLowerCase());
                    })),
                    tap(x => this.setFirstItem(x))
                );
            }
            return this.items$.pipe(
                map(x => x.filter(s => {
                    let target = s.toLowerCase();

                    if (this.autocompleteSearchIgnoreAccents) {
                        target = removeAccents(target);
                        return target.includes(removeAccents(value.toString().toLowerCase()));
                    }

                    return target.includes(value.toString().toLowerCase());
                })),
                tap(x => this.setFirstItem(x))
            );
        }

        if (this.items) {

            this.setFirstItem(this.items);
            if (!value) {
                return this.items;
            }
            if (this.displayProperty) {
                return this.items.filter(x => {
                    let target = this.getProperties(x).toLowerCase();

                    if (this.autocompleteSearchIgnoreAccents) {
                        target = removeAccents(target);
                        return target.includes(removeAccents(value.toString().toLowerCase()));
                    }

                    return target.includes(value.toString().toLowerCase());
                });
            }
            return this.items.filter(x => {
                let target = x.toLowerCase();

                if (this.autocompleteSearchIgnoreAccents) {
                    target = removeAccents(target);
                    return target.includes(removeAccents(value.toString().toLowerCase()));
                }

                return target.includes(value.toString().toLowerCase());
            });
        }

    }

    _displayFn(data: any) {
        if (this.displayProperty) {
            return this.getProperties(data);
        }
        return data;
    }

    getProperties(data: any) {

        if (!data) {
            return '';
        }

        if (this.isStringArray) {
            if (isArray(data)){
                return data[0];
            }
            return data;
        }

        const propertiesList = this.displayProperty.split(',');

        return propertiesList.reduce((acc, propertyPath) => {
            const properties = propertyPath.split('.');
            return acc + ' ' + this.getLastProperty(data, properties);
        }, '');
    }

    getLastProperty(data: any, properties: string[]) {
        if (data && properties.length > 1) {
            let value = data;
            properties.forEach(property => {
                value = value[property.trim()];
            });
            if (Array.isArray(value) || typeof value === 'object') {
                return this.displayPropertyObject(data);
            }
            return value ?? '';
        } else if (data) {

            if (!!data[properties[0].trim()] &&
                (Array.isArray(data[properties[0].trim()]) || typeof data[properties[0].trim()] === 'object')
            ){
                return this.displayPropertyObject(data);
            }
            return data[properties[0].trim()] ?? '';
        }
        return '';
    }

    resetAutoComplete() {
        this.isCleared = true;
        this.chipsValues = [];
        this.fcd.form.controls[this.controlName].reset();
        this.autocompleteChange.emit('');
        this.optionChange.emit(null);
    }

    setupDatePickerFilter() {
        if (this.dateStartAt && this.dateEndAt) {
            this.dateFilter = (d: Date | null): boolean => {
                return d >= this.dateStartAt && d <= this.dateEndAt;
            };
        } else if (this.dateStartAt) {
            this.dateFilter = (d: Date | null): boolean => {
                return d >= this.dateStartAt;
            };
        } else if (this.dateEndAt) {
            this.dateFilter = (d: Date | null): boolean => {
                return d <= this.dateEndAt;
            };
        }
    }

    private setDisabled() {
        if (this.disabled) {
            this.fcd?.form?.controls[this.controlName]?.disable();
        } else {
            this.fcd?.form?.controls[this.controlName]?.enable();
        }
    }

    private requireMatchHelperItem() {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && this.items?.length === 0) {
                return {incorrect: true};
            }

            if (control.value
                && this.items?.length > 0
                && typeof control.value === 'string'
                && !this.items?.includes(control.value)) {
                return {incorrect: true};
            }
            return null;
        };
    }

    keydownAutocomplete($event: KeyboardEvent) {
        if (this.fcd.form.controls[this.controlName]?.value?.length === 1) {
            this.autocompleteChange.emit('');
        }
    }

    optionSelected($event) {
        if (this.type === 'autocomplete-multiple') {

            const selected = $event.option.value;
            const currentValue: any[] = this.fcd.form.get(this.controlName).value;
            // stop propagation

            if (this.isStringArray) {
                if (!this.chipsValues.some(item => item === selected)) {
                    this.chipsValues = [...this.chipsValues, selected]; // Utilisez la déstructuration pour ajouter l'élément
                    this.fcd.form.get(this.controlName).setValue(this.chipsValues); // Mettez à jour la valeur du FormControl
                }
            } else {
                if (!this.chipsValues.some(item => item.label === selected.label)) {
                    this.chipsValues = [...this.chipsValues, selected]; // Utilisez la déstructuration pour ajouter l'élément
                    this.fcd.form.get(this.controlName).setValue(this.chipsValues); // Mettez à jour la valeur du FormControl
                }
            }

        }

        this.optionChange.emit($event);
    }

    private setFirstItem(items: any[]) {
        if (items?.length === 1 && !this.fcd.form?.controls[this.controlName]?.value && !this.disableAutoSelect && !this.isCleared) {
            this.fcd.form?.controls[this.controlName].setValue(items[0]);
            this.fcd.form?.controls[this.controlName].updateValueAndValidity();
        }
    }

    setToUpperCase(): void {
        this.fcd.form.get(this.controlName).setValue(this.fcd.form.get(this.controlName).value?.toUpperCase(), {emitEvent: false});
        const sub = this.fcd.form.get(this.controlName).valueChanges.subscribe((value) => {
            this.fcd.form.get(this.controlName).setValue(value?.toUpperCase(), {emitEvent: false});
        });
        this.subscription.add(sub);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }


    clearChip(event) {
        if (this.isStringArray) {
            this.chipsValues = this.chipsValues.filter(item => item !== event);
        } else {
            this.chipsValues = this.chipsValues.filter(item => item.label !== event.label);
        }
        this.fcd.form.get(this.controlName).setValue(this.chipsValues);

    }

    signatureDateChanged(event: MatDatepickerInputEvent<Date>) {
        if (this.isFromContract) {
            this.dateChanged.emit(event);
        }
    }


    private integerValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const isValid = Number.isInteger(Number(control.value));
            return isValid ? null : { isInteger: true };
        };
    }
}
