import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {
  FormModalBaseComponent
} from "../../../../../../../core/base/components/form-modal-base/form-modal-base.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {GenericMotifService} from "../../../../../settings/generic-motif/generic-motif.service";
import {ActivatedRoute} from "@angular/router";
import {EquipmentModel} from "../../../../../../../core/models/equipment.model";
import {EquipmentService} from "../../../equipment.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GenericMotifModuleEnum, GenericMotifTypesEnum} from '../../../../../../../core/enums/generic-motif-types.enum';
import {ResponseTypeEnum} from "../../../../../../../core/base/enum/response-type.enum";
import {CrudModeEnum} from "../../../../../../../core/base/enum/crud-mode.enum";
import { MetrikStatusModuleEnum } from '../../../../../../../core/enums/metrik-status.enum';

@Component({
  selector: 'vex-equipment-set-end-service-date',
  templateUrl: './equipment-set-end-service-date.component.html',
  styleUrls: ['./equipment-set-end-service-date.component.scss'],
})
export class EquipmentSetEndServiceDateComponent extends FormModalBaseComponent<EquipmentModel> implements OnInit, OnDestroy {

  public isConfirmed = false;
  public today = new Date();
  public confirmationText = 'Cette action est irréversible.';
  public loadingSave = false;

  public readonly GenericMotifTypesEnum = GenericMotifTypesEnum;
  public readonly GenericMotifModuleEnum = GenericMotifModuleEnum;
  MetrikStatusModuleEnum = MetrikStatusModuleEnum;
  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<FormModalBaseComponent<EquipmentModel>>,
              public service: EquipmentService,
              private genericMotifService: GenericMotifService,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog,
  ) {
    super(data, dialogRef, service);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initData();
  }

  initData() {
    this.form = new FormGroup({
      endServiceDate: new FormControl(new Date(), [Validators.required] ),
    });
  }

  createItem(): void {
    if (this.form.valid) {

      if (!this.isConfirmed) {
        this.isConfirmed = true;
        return;
      }

      if (this.isConfirmed) {
        this.loading = true;

        const data = {
          endServiceDate: this.form.controls?.endServiceDate?.value,
          metrikStatusId: this.form.controls?.metrikStatus?.value?._id,
        };

        const sub = this.service.setEndServiceDate(this.defaults._id, data).subscribe(
            result => {
              this.afterCreateItem(result, null);
              this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Create, result.message);
            }, error => {
              this.afterCreateItem(null, error);
              this.setErrorsMessages(error.error.errors);
              this.loading = false;
            });

        this.subscription.add(sub);
      }

    } else {
      this.logFormErrors(this.form);
      this.form.markAllAsTouched();
      this.loading = false;
    }
  }

  afterCreateItem(result?: any, error?: any) {
    if (result) {
      this.close(true);
    } else {
      this.close(false);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
