import {Component, OnInit} from '@angular/core';
import {EstablishmentService} from '../../establishment.service';
import {scaleIn400ms} from '../../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../../@vex/animations/scale-fade-in.animation';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import {SupplierModel} from '../../../../../../core/models/supplier.model';
import {
    EstablishmentCreateUpdateDeleteComponent
} from '../../modals/establishment-create-update-delete/establishment-create-update-delete.component';
import {MatDialog} from '@angular/material/dialog';
import {FeiColumn} from '../../../../../../core/base/interfaces/fei-column.interface';
import {MatTableDataSource} from '@angular/material/table';
import {AddressModel} from '../../../../../../core/models/address.model';
import {PageEvent} from '@angular/material/paginator';
import {
    EstablishmentAddressCreateUpdateDeleteComponent
} from '../../modals/establishment-address-create-update-delete/establishment-address-create-update-delete.component';
import {ENUM_PERMISSIONS} from '../../../../../../core/enums/permission.enum';
import {userProfileValidatorLevelTransalation} from '../../../../../../core/enums/user-profile-validator-seuil.enum';
import {AuthService} from '../../../../../../core/services/auth.service';

@Component({
    selector: 'vex-establishment-details-resume',
    templateUrl: './establishment-details-resume.component.html',
    styleUrls: ['./establishment-details-resume.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class EstablishmentDetailsResumeComponent implements OnInit {

    translateValidatorMatriceLevel = '';

    displayedColumnsAddress = ['actions'];
    countAddress = 0;
    dataSourceAddress = new MatTableDataSource<AddressModel>();
    sortFieldAddress: string;
    sortOrderAddress: string;
    pageIndexAddress: number;
    pageSizeAddress: number;
    canUpdatePermission = false;
    canAddAddressPermission = false;
    canImportPermissions = false;

    feisColumnsAddress: Array<FeiColumn> = [
        {label: 'Type d\'adresse', column: 'type', propertyDisplay: null},
        {label: 'Adresse', column: 'street', propertyDisplay: null},
        {label: 'Complément d\'adress', column: 'additionalAddress', propertyDisplay: null},
        {label: 'Code postal', column: 'postalCode', propertyDisplay: null},
        {label: 'Ville', column: 'city', propertyDisplay: null},
        {label: 'Pays', column: 'country', propertyDisplay: null},
        {
            label: '',
            column: 'actions',
            canUpdate: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT),
            canDelete: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT)
        }
    ];

    loadingData = false;
    ENUM_PERMISSIONS = ENUM_PERMISSIONS;

    constructor(
        public establishmentService: EstablishmentService,
        public dialog: MatDialog,
        private authService: AuthService
    ) {
        this.displayedColumnsAddress = this.feisColumnsAddress.map(x => x.column);
    }

    ngOnInit() {
        this.initData();
    }

    async initData() {
        this.loadingData = true;
        // this.establishmentService.entity$.pipe(
        //     mergeMap((establishment: any) => {
        //       if (establishment?._id) {
        //         return this.establishmentService.findAllAddress(this.pageIndexAddress, this.pageSizeAddress, this.sortFieldAddress, this.sortOrderAddress, null, { establishmentId: establishment._id })
        //       }
        //       return new Observable<any>();
        //     })
        // ).subscribe((x) => {
        //   if (x?.data) {
        //     this.dataSourceAddress = new MatTableDataSource<any>(x.data);
        //     this.countAddress = x.totalData;
        //     this.loadingData = false;
        //   }
        // });

        this.establishmentService.entity$.subscribe((entity) => {
            this.translateValidatorMatriceLevel = userProfileValidatorLevelTransalation(entity?.validatorMatriceLevel);
        });


        this.initDataAddress();


        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canUpdatePermission = permissions.includes(ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT);
            this.canAddAddressPermission = permissions.includes(ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT);
            this.canImportPermissions = permissions.includes(ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT);
        });


    }

    initDataAddress() {
        this.establishmentService.addresses$.subscribe((x) => {
            if (x?.data) {
                this.dataSourceAddress = new MatTableDataSource<any>(x.data);
                this.countAddress = x.totalData;
                // this.loadingData = false;
            }
        });
    }

    async pageEventAddress(event: PageEvent) {
        console.log(event);
        this.pageIndexAddress = event.pageIndex + 1;
        this.pageSizeAddress = event.pageSize;
        const establishment = await this.establishmentService.entity;
        this.establishmentService.findAllAddress(this.pageIndexAddress, this.pageSizeAddress, this.sortFieldAddress, this.sortOrderAddress, null, {establishmentId: establishment._id}).subscribe();
        // this.initDataAddress();
    }

    async sortEventAddress(event: any) {
        this.sortFieldAddress = event.active;
        this.sortOrderAddress = event.direction;
        const establishment = await this.establishmentService.entity;
        this.establishmentService.findAllAddress(this.pageIndexAddress, this.pageSizeAddress, this.sortFieldAddress, this.sortOrderAddress, null, {establishmentId: establishment._id}).subscribe();
    }

    async updateItem() {
        this.dialog.open(EstablishmentCreateUpdateDeleteComponent, {
            data: {
                defaults: await this.establishmentService.entity,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                // this.initData();
            }
        });
    }

    async createAddress() {
        const entity = await this.establishmentService.entity;
        this.dialog.open(EstablishmentAddressCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                defaults: entity.addresses,
                mode: CrudModeEnum.Create
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });
    }

    async updateAddress(address: any) {
        const entity = await this.establishmentService.entity;
        this.dialog.open(EstablishmentAddressCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                defaults: address,
                mode: CrudModeEnum.Update
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });
    }

    async deleteAddress(address: any) {
        const {_id} = await this.establishmentService.entity;
        this.dialog.open(EstablishmentAddressCreateUpdateDeleteComponent, {
            data: {
                id: _id,
                defaults: address,
                name: `"type d'adresse"`,
                mode: CrudModeEnum.Delete
            }
        }).afterClosed().subscribe((res) => {
            if (res) {
                // this.initData();
            }
        });
    }

    async importAddress() {
        const entity = await this.establishmentService.entity;
        this.dialog.open(EstablishmentAddressCreateUpdateDeleteComponent, {
            data: {
                id: entity._id,
                defaults: entity.addresses,
                mode: CrudModeEnum.Import
            }
        }).afterClosed().subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.initData();
            }
        });

    }
}
