<base-sidenav (onClose)="close()" (onClear)="clear()" (onValidate)="validate()"
              [standardForms]="standardForms"></base-sidenav>


<ng-template #standardForms>
    <form [formGroup]="getFormGroup('standardForm')">

        <base-form-input
            label="Identifiant technique"
            appearance="outline"
            controlName="technicalIdentifier"
            withoutPadding="true"
        >
        </base-form-input>

        <base-form-input
            (autocompleteChange)='autocompleteContractSupplierContractRef($event)'
            [items$]="getItems$('supplierContractRef')"
            (onScroll)="onScrollLine($event, 'supplierContractRef')"
            appearance="outline"
            label="Référence du contrat fournisseur"
            displayProperty='supplierContractReference'
            controlName='supplierContractRef'
            type='search-multiple'
            withoutPadding="true"
        ></base-form-input>

        <base-form-input
            *ngIf="showEstablishments"
            (autocompleteChange)='autocompleteEstablishment($event)'
            [items$]="getItems$('establishments')"
            (onScroll)="onScrollLine($event, 'establishments')"
            appearance="outline"
            controlName='establishments'
            displayProperty='label'
            label='Etablissements'
            type='search-multiple'
            withoutPadding="true"
        ></base-form-input>

        <base-form-input
            (autocompleteChange)='autocompleteActivity($event)'
            [items$]="getItems$('activities')"
            (onScroll)="onScrollLine($event, 'activities')"
            appearance="outline"
            controlName='activities'
            displayProperty='label'
            label='Activités'
            type='search-multiple'
            withoutPadding="true"
        ></base-form-input>

        <base-form-input
            [items]="genericEngagementFormat"
            label="Type d'engagement"
            type="multiple"
            appearance="outline"
            displayProperty="label"
            controlName='genericEngagementFormat'
            withoutPadding="true"
        >
        </base-form-input>

        <base-form-input
            (autocompleteChange)='autocompleteSupplier($event)'
            [items$]="getItems$('suppliers')"
            (onScroll)="onScrollLine($event, 'suppliers')"
            appearance="outline"
            controlName='suppliers'
            displayProperty='label'
            label='Fournisseurs'
            type='search-multiple'
            withoutPadding="true"
        ></base-form-input>

        <base-form-input
            (autocompleteChange)='autocompleteBusinessReferentUsers($event)'
            [items$]="getItems$('businessReferentUsers')"
            (onScroll)="onScrollLine($event, 'businessReferentUsers')"
            label="Référents métiers"
            type="search-multiple"
            appearance="outline"
            displayProperty="fullName"
            controlName='businessReferentUsers'
            withoutPadding="true"
        >
        </base-form-input>

        <base-form-input
            [items]="contractStatusOptions"
            label="Statut du contrat"
            displayProperty="label"
            appearance="outline"
            withoutPadding="true"
            type="search-multiple"
            controlName="status">
        </base-form-input>
    </form>

    <ng-container [ngTemplateOutlet]="applicationPeriod"></ng-container>
</ng-template>

<ng-template #applicationPeriod>
    <form [formGroup]="getFormGroup('applicationPeriodForm')">
        <base-form-input
            appearance="outline"
            controlName='comparison'
            type="select-between"
            label="Période d'application (Date de début)"
            typeSelection='date'
            selection="between"
            withoutPadding="true"
        ></base-form-input>
    </form>
    <form [formGroup]="getFormGroup('endApplicationPeriodForm')">
        <base-form-input
            appearance="outline"
            controlName='comparison'
            type="select-between"
            label="Période d'application (Date de fin)"
            typeSelection='date'
            selection="between"
            withoutPadding="true"
        ></base-form-input>
    </form>
    <form [formGroup]="getFormGroup('noticePeriodForm')">
        <base-form-input
            appearance="outline"
            controlName='comparison'
            type="select-between"
            label="Période de préavis"
            typeSelection='date'
            selection="between"
            withoutPadding="true"
        ></base-form-input>
    </form>
    <form [formGroup]="getFormGroup('revisionPeriodForm')">
        <base-form-input
            appearance="outline"
            controlName='comparison'
            type="select-between"
            label="Période de révision"
            typeSelection='date'
            selection="between"
            withoutPadding="true"
        ></base-form-input>
    </form>
</ng-template>
