
export enum MetrikStatusModuleEnum {
    EQUIPMENT = 'Equipement',
}

export enum MetrikStatusTypeEnum {
    ACTIVE = 'Actif',
    INACTIVE = 'Inactif',
}


