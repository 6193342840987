import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit, Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import icSearch from '@iconify/icons-ic/twotone-search';
import icClose from '@iconify/icons-ic/twotone-close';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {GenericProductService} from "../../../pages/application/settings/generic-product/generic-product.service";


export interface AllGenerics {
    type: string;
    items: any[];
}

@Component({
    selector: 'vex-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,

})
export class SearchBarComponent implements OnInit {
    @Output() fillterEvent = new EventEmitter<any>();


    searchWidthFull = true;
    icSearch = icSearch;
    icFilterList = icFilterList;
    popupStatus: Array<string>;
    popupForm: FormGroup = new FormGroup({});
    icClose = icClose;
    genericControl = new FormControl();
    search = new FormControl();

    generic$: Observable<any[]>;

    // matchips
    count: number = 0;


    constructor(
        private genericProductService: GenericProductService
    ) {
        this.genericControl.valueChanges.pipe(
            startWith(''),
            debounceTime(200),
            distinctUntilChanged()).subscribe((value) => {
            this.genericsAutocompleteChange(value);
        });
    }

    ngOnInit() {


        this.search.valueChanges
            .pipe(debounceTime(300))
            .subscribe(searchText => this.emitSearchData());

        this.genericsAutocompleteChange('');

        this.popupForm = new FormGroup({
            isLastOrder: new FormControl(false),
            isInStock: new FormControl(false),
        });
    }

    emitSearchData() {
        const isProduct = this.genericControl.value?.stockable !== null;

        const searchData = {
            genericProduct: isProduct && this.genericControl.value ? this.genericControl.value._id : null,
            genericPrestation: !isProduct && this.genericControl.value ? this.genericControl.value._id : null,
            search: this.search.value,
            filters: this.popupForm.value,
        };
        const filteredSearchData = Object.fromEntries(
            Object.entries(searchData).filter(([key, value]) => value !== null)
        );
        this.fillterEvent.emit(filteredSearchData);
    }

    clearTextFilter() {
        this.search.reset();
    }

    displayGeneric(item: any): string {
        return item && item.label ? item.label : '';
    }

    clearFilter(): void {
        this.popupForm.reset();
        this.updateCount();
    }

    clearSelection(): void {
        this.genericControl.setValue(null);
        this.emitSearchData();
    }

    genericsAutocompleteChange(state: any = '') {
        this.generic$ = this.genericProductService.findAllGenerics(null, 100, 'label', 'asc', state, { isInUserPerimeter: true }).pipe(
            map(response => {
                const generics = response.data;
                return [
                    {
                        type: 'Articles',
                        items: generics.filter(g => typeof g?.stockable === 'boolean'),
                    },
                    {
                        type: 'Prestations',
                        items: generics.filter(g => g?.stockable === undefined),
                    }
                ];
            })
        );
    }

    validateFilter() {
        // Mettez à jour le compteur
        this.updateCount();
        this.emitSearchData();
    }


    updateCount() {
        this.count = Object.values(this.popupForm.value).filter((value) => value === true).length;

    }

}
