import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchInputDataHeaderStore {

    static instance: SearchInputDataHeaderStore;

    private searchSubject = new BehaviorSubject<string>('');
    search$ = this.searchSubject.asObservable();

    static getInstance() {
        if (!SearchInputDataHeaderStore.instance) {
            SearchInputDataHeaderStore.instance = new SearchInputDataHeaderStore();
        }
        return SearchInputDataHeaderStore.instance;
    }

    // Met à jour le champ de recherche
    updateSearch(value: string): void {
        this.searchSubject.next(value);
    }

    // Réinitialise le champ de recherche
    clearSearch(): void {
        this.searchSubject.next('');
    }
}
