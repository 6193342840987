<ng-container *ngIf="isCreateMode() || isUpdateMode()">
    <div class="flex flex-row">
        <form (ngSubmit)="save()" [formGroup]="form">
            <base-form-header [mode]="mode" [createLabel]="createLabel" [updateLabel]="updateLabel"></base-form-header>

            <div class="flex">
                <mat-dialog-content style="width: 500px" *ngIf='this.data?.specialMode !== "genericCharacteristic"'>
                    <base-form-input
                            label="Article générique"
                            type="autocomplete"
                            [items$]="genericProduct$"
                            (autocompleteChange)="genericProductAutocompleteChange($event)"
                            displayProperty="label"
                            controlName="genericProduct">
                    </base-form-input>

                    <base-form-input
                            label="Référence Fournisseur"
                            controlName="reference">
                    </base-form-input>
                    <base-form-input
                            label="Réf. précédente"
                            controlName="previousReference">
                    </base-form-input>
                    <base-form-input
                            label="Libellé"
                            controlName="label">
                    </base-form-input>

                    <base-form-input
                            label="Fabricant"
                            controlName="manufacturer">
                    </base-form-input>

                    <base-form-input
                            label="Modèle"
                            controlName="model">
                    </base-form-input>

                    <base-form-input
                            label="Taux TVA"
                            type="autocomplete"
                            [items$]="tva$"
                            (autocompleteChange)="tvaAutocompleteChange($event)"
                            displayProperty="genericTva.label, genericTva.rateList"
                            [displayPropertyObject]="getTvaRate.bind(this)"
                            controlName="tva">
                    </base-form-input>

                    <base-form-input
                            *ngIf="isUpdateMode()"
                            label="Compte comptable"
                            type="select"
                            [items]="accountingAccounts"
                            displayProperty="display"
                            controlName="accountingAccount">
                    </base-form-input>

                    <base-form-input
                            *ngIf="isUpdateMode()"
                            label="Compte comptable TVA"
                            type="select"
                            [items]="tvaAccountingAccounts"
                            displayProperty="display"
                            controlName="tvaAccountingAccount">
                    </base-form-input>

                    <base-form-input
                            label="Prix UA"
                            type="number"
                            controlName="priceHT">
                    </base-form-input>

                    <base-form-input
                            label="Prix T.T.C"
                            [disabled]='true'
                            type="number"
                            controlName="priceTTC">
                    </base-form-input>

                    <base-form-input
                            label="Unité d’achat"
                            controlName="purchaseUnit"
                            type="select"
                            [items]="purchaseUnits"
                    >
                    </base-form-input>

                    <base-form-input
                                     label="Unité de conditionnement"
                                     controlName="conditioningUnit">
                    </base-form-input>

<!--                    <div class="flex flex-col sm:flex-row">-->
<!--                        <base-form-input class="sm:mr-3"-->
<!--                                         label="Unité de conditionnement"-->
<!--                                         controlName="conditioningUnit">-->
<!--                        </base-form-input>-->
<!--                        <base-form-input class="sm:ml-3"-->
<!--                                         label="Quantité par UA"-->
<!--                                         type="number"-->
<!--                                         controlName="conditioningQuantity">-->
<!--                        </base-form-input>-->
<!--                    </div>-->

                    <base-form-input
                            label='Quantité minimum'
                            type='number'
                            controlName='minQuantity'
                            [minNumber]="1"
                    >
                    </base-form-input>

                    <base-form-input
                            label="Catalogue général du fournisseur"
                            controlName="generalSupplierCatalog"
                            invalidPatternMessage='Le champs doit être rempli avec une URL'
                    >
                    </base-form-input>

                    <base-form-input
                            label="Fiche technique"
                            controlName="technicalSheet"
                            invalidPatternMessage='Le champs doit être rempli avec une URL'
                    >
                    </base-form-input>

                    <base-form-input
                            label="Protocole d’utilisation"
                            controlName="safetyUsageProtocol"
                            invalidPatternMessage='Le champs doit être rempli avec une URL'
                    >
                    </base-form-input>

                    <quill-editor
                            placeholder='Informations complémentaires'
                            [modules]="quillConfig"
                            [formControl]='form?.controls?.comment'
                    >
                    </quill-editor>

                    <ng-container *ngIf="isRevisionProductAmount">
                        <base-form-input
                                label='Condition de révision'
                                type='number'
                                controlName='revisionAmount'
                        >
                        </base-form-input>
                    </ng-container>

                    <div class="flex flex-col sm:flex-row">
                        <base-form-input
                                class="sm:mr-3"
                                label="Stockable"
                                type="checkbox"
                                controlName="stockable"
                                [disabled]="!canStockablePermission">

                        </base-form-input>

                        <base-form-input
                                class="sm:mr-3"
                                label="Disponible au eShop"
                                type="checkbox"
                                controlName="isAvailable">
                        </base-form-input>

                        <base-form-input
                                class="sm:mr-3"
                                label="Tout le périmètre"
                                type="checkbox"
                                controlName="isFullPerimeter">
                        </base-form-input>
                    </div>


                </mat-dialog-content>

                <mat-dialog-content fxLayout="column" *ngIf='this.data?.specialMode === "genericCharacteristic"'>

                    <base-form-input
                            [disabled]="true"
                            label="Caractéristique"
                            controlName="disableCharacteristicLabel"
                    >
                    </base-form-input>
                    <base-form-input
                            [disabled]="true"
                            label="Unité"
                            controlName="disableCharacteristicUnit"
                    >
                    </base-form-input>
                    <base-form-input
                            label="Valeur"
                            controlName="value"
                            type="number"
                    >
                    </base-form-input>

                </mat-dialog-content>
                <div *ngIf="!form.get('isFullPerimeter').value" class="ml-10 w-1/2" style="min-width: 500px; max-height: 800px; overflow-y: auto;">

                    <vex-contract-details-user-perimeter
                            [entity] = "contract"
                            [parentItems]="perimeter"
                            [isCompressed]="true"
                            (selectedItemsChange)="onSelectedItemsChange($event)"
                            class=''>
                    </vex-contract-details-user-perimeter>

                </div>
            </div>

            <mat-dialog-actions align="end">
                <base-form-input
                        type="submit"
                        [mode]="mode"
                        [loading]="loading"
                        entityLabel="PRODUCT"
                        (onCreate)="createItem()"
                        (onUpdate)="updateItem()"
                >
                </base-form-input>
            </mat-dialog-actions>
        </form>

    </div>


</ng-container>

<ng-container *ngIf="isDeleteMode()">
    <form-modal-delete-base
            [loading]="loading"
            [label]="data.name"
            (onClose)="close()"
            (onDelete)="deleteItem()"
    >
    </form-modal-delete-base>
</ng-container>

<ng-container *ngIf="isImportMode()">
    <vex-form-import-base
            [modalTitle]='"Importer des articles"'
            [entityLabel]='"PRODUCT"'
            [form]="importForm"
            [service]="service"
            [loading]="loading"
            [mode]="mode"
            (saveForm)="save()"
            (importItems)="importItems()"
            (close)="close()"
            [customExport]='true'
            (customExportItems)='exportProductContractTemplate()'
    ></vex-form-import-base>
</ng-container>

<ng-container *ngIf='isArchiveMode()'>
    <form-modal-delete-base
            (onClose)='close()'
            (onDelete)='archiveItem()'
            title="Archiver l'article"
            defaultMessage='Êtes-vous sûr de vouloir archiver ce produit ?'
    >
    </form-modal-delete-base>
</ng-container>

