import {Component, OnInit} from '@angular/core';
import {EstablishmentService} from '../../establishment.service';
import {FeiColumn} from '../../../../../../core/base/interfaces/fei-column.interface';
import {MatTableDataSource} from '@angular/material/table';
import {EquipmentModel} from '../../../../../../core/models/equipment.model';
import {MatDialog} from '@angular/material/dialog';
import {
    EstablishmentActivityCreateUpdateDeleteComponent
} from '../../modals/establishment-activity-create-update-delete/establishment-activity-create-update-delete.component';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import {ActivityModel} from '../../../../../../core/models/activity.model';
import {PageEvent} from '@angular/material/paginator';
import {scaleIn400ms} from '../../../../../../../@vex/animations/scale-in.animation';
import {fadeInRight400ms} from '../../../../../../../@vex/animations/fade-in-right.animation';
import {stagger40ms} from '../../../../../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../../../../../@vex/animations/fade-in-up.animation';
import {scaleFadeIn400ms} from '../../../../../../../@vex/animations/scale-fade-in.animation';
import {ActivatedRoute} from '@angular/router';
import {ENUM_PERMISSIONS} from '../../../../../../core/enums/permission.enum';
import {AuthService} from '../../../../../../core/services/auth.service';

@Component({
    selector: 'vex-establishment-details-activite',
    templateUrl: './establishment-details-activite.component.html',
    styleUrls: ['./establishment-details-activite.component.scss'],
    animations: [
        scaleIn400ms,
        fadeInRight400ms,
        stagger40ms,
        fadeInUp400ms,
        scaleFadeIn400ms
    ]
})
export class EstablishmentDetailsActiviteComponent implements OnInit {

    displayedColumns = ['actions'];
    count = 0;
    dataSource = new MatTableDataSource<ActivityModel>();
    sortField: string;
    sortOrder: string;
    pageIndex: number;
    pageSizeAddress = 0;
    canAddActivityPermissions = false;

    feisColumns: Array<FeiColumn> = [
        {label: 'Libellé', column: 'label', propertyDisplay: null},
        {label: 'Type d\'activité', column: 'activityType', propertyDisplay: 'activityType.label'},
        {label: 'Typologie des résidents', column: 'publicType', propertyDisplay: 'publicType.label'},
        {label: 'Pourcentage', column: 'percent', propertyDisplay: null, additionalDisplay: ' %'},
        {label: 'N° Finess', column: 'finess', propertyDisplay: null},
        {label: 'Adresse', column: 'address', propertyDisplay: 'address.type'},
        {label: 'Nombre de lits permanents', column: 'nbOfPermanantBed'},
        {label: 'Nombre de lits temporaires', column: 'nbOfTemporaryBed'},
        {label: 'Capacité d\'accueil', column: 'accomodationCapacity'},
        {
            label: '',
            column: 'actions',
            canUpdate: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT),
            canDelete: this.authService.hasPermission(ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT)
        }
    ];

    loading = false;
    entityName = 'Activités';
    displayPostePercentWarning = false;
    ENUM_PERMISSION = ENUM_PERMISSIONS;

    constructor(
        public service: EstablishmentService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService
    ) {
        this.displayedColumns = this.feisColumns.map(x => x.column);
    }

    ngOnInit(): void {
        this.initData();
    }

    initData() {
        this.loading = true;
        this.service.entity$.subscribe(establishment => {
            let paginatedActivity = establishment?.activity || [];

            // Add sorting logic here
            if (this.sortField && this.sortOrder) {
                paginatedActivity.sort((a, b) => {
                    let result = 0;
                    if (this.sortOrder === 'asc') {
                        result = a[this.sortField] > b[this.sortField] ? 1 : -1;
                    } else {
                        result = a[this.sortField] < b[this.sortField] ? 1 : -1;
                    }
                    return result;
                });
            }

            // Add pagination logic here
            if (this.pageIndex && this.pageSizeAddress) {
                paginatedActivity = paginatedActivity.slice(
                    (this.pageIndex - 1) * this.pageSizeAddress,
                    this.pageIndex * this.pageSizeAddress
                );
            }

            this.dataSource = new MatTableDataSource<ActivityModel>(paginatedActivity);
            this.count = establishment?.activity?.length;
            this.displayPostePercentWarning = establishment?.activity.reduce((p, c) => p + c.percent, 0) !== 100;
            this.loading = false;
        });
    }


    pageEvent(event: PageEvent) {
        this.pageIndex = event.pageIndex + 1;
        this.pageSizeAddress = event.pageSize;
        this.initData();
    }

    sortEvent(event: any) {
        console.log(event);
        this.sortField = event.active;
        this.sortOrder = event.direction;
        this.initData();
    }

    async createActivity() {

        this.dialog.open(EstablishmentActivityCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Create,
                establishment: await this.service.entity
            }
        }).afterClosed().subscribe((establishment: EquipmentModel) => {
            if (establishment) {
                this.initData();
            }
        });
    }

    async updateItem(e) {

        this.dialog.open(EstablishmentActivityCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Update,
                defaults: e,
                establishment: await this.service.entity
            }
        }).afterClosed().subscribe((establishment: EquipmentModel) => {
            if (establishment) {
                this.initData();
            }
        });
    }

    async deleteItem(e) {

        this.dialog.open(EstablishmentActivityCreateUpdateDeleteComponent, {
            data: {
                mode: CrudModeEnum.Delete,
                establishment: await this.service.entity,
                defaults: e,
                name: e.label
            }
        }).afterClosed().subscribe((establishment: EquipmentModel) => {
            if (establishment) {
                this.initData();
            }
        });
    }
}
