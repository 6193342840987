<ng-container *ngIf="isUpdateMode() || isReadMode()">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]="mode" [createLabel]="createLabel" [updateLabel]="updateLabel"></base-form-header>

        <mat-dialog-content fxLayout="column">
          <base-form-input
            *ngIf="isPredik()"
            label="Article générique"
            type="autocomplete"
            [items$]="genericProduct$"
            (autocompleteChange)="genericProductAutocompleteChange($event)"
            displayProperty="label"
            controlName="genericProduct">
          </base-form-input>
            <base-form-input
                    label="Libellé"
                    [disabled]="isReadMode()"
                    controlName="label">
            </base-form-input>

            <!--  Demande d'Augustine  -->
            <mat-form-field color="primary" class="flex-auto w-full">
                <mat-label>SIRET Fournisseur</mat-label>
                <input disabled placeholder="n° SIRET Frs" matInput
                       [value]="form?.controls?.supplier?.value?.siretNumber">
            </mat-form-field>

            <base-form-input
                    [disabled]="isReadMode()"
                    label="Référence Fournisseur"
                    controlName="reference">
            </base-form-input>


            <base-form-input
                 [disabled]="isReadMode()"
                 class="sm:mr-3"
                 label="Référence précédente"
                 controlName="previousReference"
            >
            </base-form-input>

            <base-form-input
                    label="Modèle"
                    type="text"
                    controlName="model"
                    [disabled]="isReadMode()"
            >
            </base-form-input>

            <base-form-input
                    label="Unité d’achat/Colisage"
                    controlName="purchaseUnit"
                    type="select"
                    [items]="purchaseUnit"
                    [disabled]="isReadMode()"
            >
            </base-form-input>

            <base-form-input
                 label="Unité de conditionnement (UC)"
                 controlName="conditioningUnit"
                 [disabled]="isReadMode()"
            >
            </base-form-input>

            <div class="flex space-x-3">
                <base-form-input class="w-1/2"
                                 [disabled]="isReadMode()"
                                 label="Prix HT"
                                 type="number"
                                 controlName="priceHT">
                </base-form-input>
                <base-form-input class="w-1/2"
                                 [disabled]=true
                                 label="Prix TTC"
                                 type="number"
                                 controlName="priceTTC">
                </base-form-input>
            </div>


            <base-form-input
                    label="Catalogue général du fournisseur"
                    controlName="generalSupplierCatalog"
                    invalidPatternMessage='Le champs doit être rempli avec une URL'
                    [disabled]="isReadMode()"
            >
            </base-form-input>

            <base-form-input
                    label="Fiche technique"
                    controlName="technicalSheet"
                    invalidPatternMessage='Le champs doit être rempli avec une URL'
                    [disabled]="isReadMode()"
            >
            </base-form-input>

            <base-form-input
                    label="Protocole d’utilisation"
                    controlName="safetyUsageProtocol"
                    invalidPatternMessage='Le champs doit être rempli avec une URL'
                    [disabled]="isReadMode()"
            >
            </base-form-input>

            <div class="pb-2 text-input">
                <p>Informations complémentaires</p>
            </div>
            <quill-editor
                placeholder='Veuillez saisir les informations complémentaires ici'
                [modules]="quillConfig"
                [formControl]='form?.controls?.comment'
                [disabled]="isReadMode()"
            >
            </quill-editor>

            <base-form-input
                    label="Disponible dans le catalogue"
                    type="checkbox"
                    controlName="isAvailable"
                    [disabled]="isReadMode()">
            </base-form-input>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [mode]='mode'
                    [loading]='loading'
                    entityLabel='PRODUCT'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isCreateMode() ">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]="mode" [createLabel]="createLabel" [updateLabel]="updateLabel"></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                    *ngIf="hasPermission()"
                    label="Article générique"
                    type="autocomplete"
                    [items$]="genericProduct$"
                    (autocompleteChange)="genericProductAutocompleteChange($event)"
                    displayProperty="label"
                    controlName="genericProduct">
            </base-form-input>

            <base-form-input
                    label="Libellé"
                    controlName="label">
            </base-form-input>

            <base-form-input
                    label="Fournisseur"
                    type="autocomplete"
                    [items$]="suppliers$"
                    (autocompleteChange)="supplierAutocompleteChange($event)"
                    displayProperty="label"
                    controlName="supplier">
            </base-form-input>

            <!--  Demande d'Augustine  -->
            <mat-form-field color="primary" class="flex-auto w-full">
                <mat-label>SIRET Fournisseur</mat-label>
                <input disabled placeholder="n° SIRET Frs" matInput
                       [value]="form?.controls?.supplier?.value?.siretNumber">
            </mat-form-field>

            <base-form-input
                    label="Référence Fournisseur"
                    controlName="reference">
            </base-form-input>

            <base-form-input
                    class="sm:mr-3"
                    label="Référence précédente"
                    controlName="previousReference"
            >
            </base-form-input>

            <base-form-input
                    label="Modèle"
                    type="text"
                    controlName="model"
            >
            </base-form-input>

            <base-form-input
                    label="Unité d’achat/Colisage"
                    controlName="purchaseUnit"
                    type="select"
                    [items]="purchaseUnit"
            >
            </base-form-input>

            <base-form-input
                    label="Taux TVA"
                    type="autocomplete"
                    [items$]="tva$"
                    (autocompleteChange)="tvaAutocompleteChange($event)"
                    displayProperty="label"
                    controlName="tva">
            </base-form-input>

            <base-form-input
                    label="Prix UA"
                    type="number"
                    controlName="priceHT">
            </base-form-input>

            <base-form-input
                    label="Unité de conditionnement (UC)"
                    controlName="conditioningUnit"
            >
            </base-form-input>

            <div class="pb-2 text-input">
                <p>Informations complémentaires</p>
            </div>
            <quill-editor
                    placeholder='Veuillez saisir les informations complémentaires ici'
                    [modules]="quillConfig"
                    [formControl]='form?.controls?.comment'
            >
            </quill-editor>

            <base-form-input
                    *ngIf='hasPermission()'
                    label="Disponible dans le catalogue"
                    type="checkbox"
                    controlName="isAvailable"
                    [disabled]='isReadMode()'
            >
            </base-form-input>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [mode]='mode'
                    [loading]='loading'
                    entityLabel='PRODUCT'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="isDeleteMode() ">
    <form-modal-delete-base
            [loading]="loading"
            [label]="data.name"
            (onClose)="close()"
            (onDelete)="deleteItem()"
    >
    </form-modal-delete-base>
</ng-container>

<ng-container *ngIf="isArchiveMode() ">
    <form-modal-archive-base
            [loading]="loading"
            [label]="data.name"
            (onClose)="close()"
            (onArchive)="archiveItem()"
    >
    </form-modal-archive-base>
</ng-container>

<ng-container *ngIf="isImportMode()">
    <vex-form-import-base
            [modalTitle]='"Importer des articles"'
            [entityLabel]='"PRODUCT"'
            [form]="importForm"
            [service]="service"
            [loading]="loading"
            [mode]="mode"
            (saveForm)="save()"
            (importItems)="importItems()"
            (close)="close()"
    ></vex-form-import-base>
</ng-container>
