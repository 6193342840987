import { Component } from '@angular/core';
import { EstablishmentService } from '../../establishment/establishment.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SupplierService } from '../../supplier/supplier.service';
import { SupplierModel } from '../../../../../core/models/supplier.model';
import {
    GenericEngagementFormatEnum,
    genericEngagementTranslationObject
} from '../../../../../core/enums/generic-engagement-format.enum';
import { ContractStatusEnum, contractStatusTranslationObject } from '../../../../../core/enums/contract-status.enum';
import { AuthService } from '../../../../../core/services/auth.service';
import { UserProfileModel } from '../../../../../core/models/user-profile.model';
import { UserProfileService } from '../../user-profile/user-profile.service';
import { ContractService } from '../contract.service';
import { ContractModel } from '../../../../../core/models/contract.model';
import {
    SidenavBaseFilterComponent
} from '../../../../../core/base/components/sidenav/base-filter/sidenav-base-filter.component';
import { ActivityTypeService } from '../../../settings/activity-type/activity-type.service';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { AutocompleteService } from '../../../../../core/services/scroll.service';
import { safeMap } from '../../../../../core/utils/array-utils';
import { EstablishmentModel } from '../../../../../core/models/establishment.model';

@Component({
    selector: 'contract-filter',
    templateUrl: './contract-filter.component.html',
    styleUrls: ['./contract-filter.component.scss']
})
export class ContractFilterComponent extends SidenavBaseFilterComponent {

    contractStatusOptions = [];
    showEstablishments: boolean;
    genericEngagementFormat = Object.values(GenericEngagementFormatEnum).map(g => genericEngagementTranslationObject(g));

    constructor(
        public establishmentService: EstablishmentService,
        public suppliersService: SupplierService,
        public authService: AuthService,
        public userProfileService: UserProfileService,
        public contractService: ContractService,
        public activityService: ActivityTypeService,
        public autocompleteService: AutocompleteService,
        public snackbarService: SnackbarService
    ) {
        super(snackbarService, autocompleteService);


        this.addForm('standardForm', new FormGroup({
            technicalIdentifier: new FormControl(),
            establishments: new FormControl(),
            suppliers: new FormControl(),
            genericEngagementFormat: new FormControl(),
            supplierContractRef: new FormControl(),
            status: new FormControl(),
            businessReferentUsers: new FormControl(),
            activities: new FormControl(),
        }));

        this.addForm('applicationPeriodForm', new FormGroup({
            comparison: new FormControl(),
            min: new FormControl(),
            max: new FormControl(),
        }));

        this.addForm('endApplicationPeriodForm', new FormGroup({
            comparison: new FormControl(),
            min: new FormControl(),
            max: new FormControl(),
        }));

        this.addForm('noticePeriodForm', new FormGroup({
            comparison: new FormControl(),
            min: new FormControl(),
            max: new FormControl(),
        }));

        this.addForm('revisionPeriodForm', new FormGroup({
            comparison: new FormControl(),
            min: new FormControl(),
            max: new FormControl(),
        }));

        const allStatuses = Object.values(ContractStatusEnum).map(status => contractStatusTranslationObject(status));

        this.contractStatusOptions = this.authService.isReferentMetier()
            ? allStatuses
            : allStatuses.filter(status => status.value !== ContractStatusEnum.DRAFT);

        this.showEstablishments = this.authService.currentUserValue().affectations.length > 1;
    }

    initialize(): void {
        this.autocompleteEstablishment('');
        this.autocompleteSupplier('');
        this.autocompleteBusinessReferentUsers('');
        this.autocompleteContractSupplierContractRef('');
        this.autocompleteActivity('');
    }

    buildCustomFilters(): any {
        const {
            suppliers,
            supplierContractRef,
            establishments,
            status,
            businessReferentUsers,
            genericEngagementFormat,
            ...rest
        } = this.forms.standardForm.getRawValue();

        return {
            establishments: safeMap(establishments, (x: EstablishmentModel) => x._id),
            genericEngagementFormat: safeMap(genericEngagementFormat, (x: any) => x.value),
            suppliers: safeMap(suppliers, (x: SupplierModel) => x._id),
            referentUserIds: safeMap(businessReferentUsers, (x: UserProfileModel) => x._id),
            supplierContractRef: safeMap(supplierContractRef, (x: ContractModel) => x.supplierContractReference),
            status: safeMap(status, (s: any) => s.value),
            ...rest,
            startValidityDate: { ...this.forms.applicationPeriodForm.getRawValue() },
            endValidityDate: { ...this.forms.endApplicationPeriodForm.getRawValue() },
            noticeDate: { ...this.forms.noticePeriodForm.getRawValue() },
            revisionDate: { ...this.forms.revisionPeriodForm.getRawValue() },
        };
    }

    autocompleteEstablishment(search: string = '', pageToGo?: 'next' | 'previous') {
        this.autocompleteService.autocompleteInfiniteScroll(
            search,
            'establishments',
            '_id',
            pageToGo,
            (page, size) =>
                this.establishmentService.findAll(page, size, 'label', 'asc', search, { onlyMine: true })
        )?.subscribe();
    }

    autocompleteSupplier(search: string = '', pageToGo?: 'next' | 'previous') {
        this.autocompleteService.autocompleteInfiniteScroll(
            search,
            'suppliers',
            '_id',
            pageToGo,
            (page, size) =>
                this.suppliersService.findAll(page, size, 'label', 'asc', search)
        )?.subscribe();
    }

    autocompleteBusinessReferentUsers(search: string = '', pageToGo?: 'next' | 'previous') {
        this.autocompleteService.autocompleteInfiniteScroll(
            search,
            'businessReferentUsers',
            '_id',
            pageToGo,
            (page, size) =>
                this.userProfileService.findAll(page, size, 'lastName', 'asc', search, { isBusinessReferent: true })
        )?.subscribe();
    }

    autocompleteContractSupplierContractRef(search: string = '', pageToGo?: 'next' | 'previous') {
        this.autocompleteService.autocompleteInfiniteScroll(
            search,
            'supplierContractRef',
            '_id',
            pageToGo,
            (page, size) =>
                this.contractService.findAll(page, size, 'supplierContractReference', 'asc', search, { supplierContractRefOnly: true })
        )?.subscribe();
    }

    autocompleteActivity(search: string = '', pageToGo?: 'next' | 'previous') {
        this.autocompleteService.autocompleteInfiniteScroll(
            search,
            'activities',
            '_id',
            pageToGo,
            (page, size) =>
                this.activityService.findAll(page, size, 'label', 'asc', search)
        )?.subscribe();
    }

    onScrollLine(pageToGo: 'next' | 'previous', filterField: string): void {
        switch (filterField) {
            case 'establishments':
                this.autocompleteEstablishment('', pageToGo);
                break;
            case 'suppliers':
                this.autocompleteSupplier('', pageToGo);
                break;
            case 'businessReferentUsers':
                this.autocompleteBusinessReferentUsers('', pageToGo);
                break;
            case 'supplierContractRef':
                this.autocompleteContractSupplierContractRef('', pageToGo);
                break;
            case 'activities':
                this.autocompleteActivity('', pageToGo);
                break;
            default:
                console.error('Unknown filterField:', filterField);
        }
    }
}
