import {Component, OnInit} from '@angular/core';
import {EstablishmentService} from '../establishment.service';
import {ActivatedRoute} from '@angular/router';
import {DetailsBaseComponent} from '../../../../../core/base/components/details-base/details-base.component';
import {EstablishmentModel} from '../../../../../core/models/establishment.model';
import {CustomerGroupService} from '../../../settings/customer-group/customer-group.service';
import {AuthService} from '../../../../../core/services/auth.service';
import {ENUM_PERMISSIONS} from '../../../../../core/enums/permission.enum';

@Component({
    selector: 'vex-establishment-details',
    templateUrl: './establishment-details.component.html',
    styleUrls: ['./establishment-details.component.scss']
})
export class EstablishmentDetailsComponent extends DetailsBaseComponent<EstablishmentModel> implements OnInit {
    init = false;
    establishmentId: any;
    defaultTabs = [
        {
            label: 'INFORMATIONS',
            route: './',
            routerLinkActiveOptions: {exact: true},
            permission: ENUM_PERMISSIONS.READ_ESTABLISHMENT,
            index: 1
        },
        {
            label: 'CONTACT INTERNE',
            route: './contact',
            permission: ENUM_PERMISSIONS.READ_ESTABLISHMENT,
            index: 2
        },
        {
            label: 'ACTIVITÉS',
            route: './activite',
            permission: ENUM_PERMISSIONS.READ_ESTABLISHMENT,
            index: 3
        },
        {
            label: 'PLAN ANALYTIQUE',
            route: './analytics',
            index: 4,
        },
        {
            label: 'BATIMENTS',
            route: './buildings',
            permission: ENUM_PERMISSIONS.READ_ESTABLISHMENT,
            index: 6
        },
        {
            label: 'ACTEURS ACHATS',
            route: './validators',
            permission: ENUM_PERMISSIONS.READ_ESTABLISHMENT,
            index: 7
        }
    ];

    constructor(
        public service: EstablishmentService,
        public activatedRoute: ActivatedRoute,
        private customerGroupService: CustomerGroupService,
        public authService: AuthService
    ) {
        super(service, activatedRoute, authService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.init = false;

        this.subscription.add(
            this.customerGroupService.getCurrent().subscribe(data => {
                this.addTabs(
                    {
                        label: 'PLAN ANALYTIQUE',
                        route: './analytics',
                        additionalCondition: data?.data.comptable?.analyticPlan,
                        permission: ENUM_PERMISSIONS.READ_ESTABLISHMENT,
                        index: 5
                    }
                );
            })
        );

        const item = this.service.entity$.subscribe(establishment => {
                if (establishment?._id && !this.init) {
                    this.init = true;
                    this.authService.getCurrentUserPermissions$().subscribe(permissions => {
                        if (permissions.includes(ENUM_PERMISSIONS.READ_ESTABLISHMENT)) {
                            this.service.getContact(establishment._id).subscribe();
                        }
                    });
                    this.service.findAllAddress(null, null, null, null, null, {establishmentId: establishment._id}).subscribe();
                }
            }
        );

        this.subscription.add(item);


        this.activatedRoute.paramMap.subscribe(params => {
            this.establishmentId = params.get('id');
        });

    }
}
