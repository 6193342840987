<ng-container *ngIf="canCreateMode || canUpdateMode">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header [mode]='mode' createLabel='Ajouter un fournisseur'
                          [updateLabel]='form.get("label").value'></base-form-header>

        <mat-dialog-content fxLayout="column">

            <base-form-input
                    label='Type'
                    type='select'
                    [items]='supplierType'
                    controlName='type'>
            </base-form-input>

            <base-form-input
                    label='Dénomination'
                    controlName='label'>
            </base-form-input>

            <!--            <base-form-input-->
            <!--                *ngIf='!isIntern'-->
            <!--                label='N° TVA'-->
            <!--                controlName='tva'>-->
            <!--            </base-form-input>-->

            <base-form-input
                    *ngIf='!isIntern'
                    label='Compte auxiliaire comptable'
                    controlName='subsidiarAccount'
            >
            </base-form-input>

            <base-form-input
                    *ngIf='isFrench && !isIntern'
                    label='N° Siren'
                    controlName='sirenNumber'
                    invalidPatternMessage='Le format du N°Siren est invalide.'
            >
            </base-form-input>


            <base-form-input
                    *ngIf='isFrench && !isIntern'
                    label='N° Siret'
                    controlName='siretNumber'
            >
            </base-form-input>


            <base-form-input
                    class="mb-2"
                    *ngIf='!isIntern'
                    label='Soumis à TVA'
                    controlName='submittedToTva'
                    type="checkbox"
            >
            </base-form-input>

            <base-form-input
                    *ngIf='!isIntern'
                    [disabled]='!submittedToTva'
                    label='N° TVA intracom'
                    controlName='tva'
                    invalidPatternMessage='Le format du numéro de TVA est invalide.'
                    customErrorMessage='Le numéro de TVA ne correspond pas au siren.'
            >
            </base-form-input>


            <base-form-input
                    *ngIf='isFrench && !isIntern'
                    label='Code APE'
                    controlName='codeApe'
                    invalidPatternMessage='Le champ doit contenir exactement 4 chiffres et 1 lettre majuscule en fin de chaîne.'
            >
            </base-form-input>


            <!--                        <base-form-input-->
            <!--                                *ngIf='isFrench && !isIntern'-->
            <!--                                label="Famille d'achat"-->
            <!--                                [items]='families'-->
            <!--                                controlName='famillies'-->
            <!--                                type='multiple'-->
            <!--                                customErrorMessage="Au moins une famille d'achat doit être sélectionnée"-->
            <!--                                displayProperty='link'-->
            <!--                                [customMultipleSelecteTemplate]="customSelectDisplay"-->
            <!--                        >-->
            <!--                        </base-form-input>-->

            <!-- Chips famille d'achat ou poste de dépense -->


            <mat-form-field class="example-chip-list" appearance="fill" style="width: 600px;">
                <mat-label>Famille d'achat</mat-label>
                <mat-chip-list #chipList aria-label="Famille d'achat">
                    <mat-chip
                            *ngFor="let item of form.get('famillies').value"
                            (removed)="remove(item)">
                        {{item.link}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <input
                           placeholder="Nouvelle famille"
                           #itemInput
                           [formControl]="itemCtrl"
                           [matAutocomplete]="auto"
                           [matChipInputFor]="chipList"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                           (keydown)="onKeyDown($event)"
                    >
                </mat-chip-list>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let item of filteredFamilies | async" [value]="item">
                        {{item?.link}}
                    </mat-option>
                </mat-autocomplete>
                <div *ngIf="isDuplicate" style="color: red;">Cette famille a déjà été sélectionnée.</div>
            </mat-form-field>

            <base-form-input
                    *ngIf='!isFrench && !isIntern'
                    label='Identifiant entreprise'
                    controlName='dunsNumber'
            >
            </base-form-input>

            <p class='mb-3' *ngIf='!isIntern'>Adresse SIRET fournisseur</p>
            <mat-divider class="mb-6" *ngIf='!isIntern'></mat-divider>

            <ng-container [formGroup]='addressForm' *ngIf='!isIntern'>
                <div class="flex flex-col sm:flex-row">
                    <base-form-input
                            class='sm:mr-3'
                            label='Adresse'
                            controlName='street'>
                    </base-form-input>
                    <base-form-input
                            class='sm:ml-3'
                            label='Adresse additionnelle'
                            controlName='additionalAddress'>
                    </base-form-input>
                </div>

                <div class="flex flex-col sm:flex-row">
                    <base-form-input
                            class='sm:mr-3'
                            label='Code postal'
                            controlName='postalCode'
                            invalidPatternMessage='Le format du code postal est invalide.'
                    >
                    </base-form-input>
                    <base-form-input
                            class='sm:ml-3'
                            label='Ville'
                            controlName='city'>
                    </base-form-input>
                </div>
                <base-form-input
                        label='Pays'
                        controlName='country'
                        type='autocomplete'
                        [items]='countries'
                >
                </base-form-input>
            </ng-container>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [mode]='mode'
                    [loading]='loading'
                    entityLabel='SUPPLIER'
                    (onCreate)='createItem()'
                    (onUpdate)='updateItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>
</ng-container>

<ng-container *ngIf="canDeleteMode">
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
        <div>{{ data.name }}</div>
        <button type="button" mat-icon-button (click)="close()" tabindex="-1">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <p>Etes-vous sûr de vouloir supprimer {{ data.name }} ?</p>
    </mat-dialog-content>


    <mat-dialog-actions align="end">
        <button mat-button (click)="close()">NON</button>
        <button mat-button color="primary" (click)="deleteItem()">OUI</button>
    </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="canImportMode">
    <vex-form-import-base
            [modalTitle]='"Importer des fournisseurs"'
            [entityLabel]='"Fournisseur"'
            [form]='importForm'
            [loading]='loading'
            [service]='service'
            [mode]='mode'
            (saveForm)='save()'
            (importItems)='importItems()'
            [canDownloadTemplateModel]="authService.hasPermission(ENUM_PERMISSIONS.READ_SUPPLIER)"
    ></vex-form-import-base>
</ng-container>

<ng-container *ngIf='canArchiveMode'>
    <form-modal-delete-base
            (onClose)='close()'
            (onDelete)='archiveItem()'
            title="Archiver les fournisseurs"
            defaultMessage='Êtes-vous sûr de vouloir archiver ce fournisseur ?'
    >
    </form-modal-delete-base>
</ng-container>

<!-- Liste des fammilles à renvoyer -->
<ng-template #customSelectDisplay let-element="element">
    <div *ngIf="element">
        <div class="flex flex-col mb-4" *ngFor="let item of element">
            {{ item?.link }}
        </div>
    </div>
</ng-template>

