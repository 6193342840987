import { SupplierModel } from './supplier.model';
import { TvaModel } from './tva.model';
import { GenericPrestationModel } from './generic-prestation.model';
import { CustomerGroupComptableLine } from './customer-group.model';
import {ContractModel, ContractPerimeterModel} from './contract.model';
import { EstablishmentModel } from './establishment.model';
import { RecurrencePrestationTypeEnum } from '../enums/recurrence-prestation-type.enum';
import {FormControl} from "@angular/forms";

export class PrestationModel {
    _id: string;
    technicalIdentifier: string;
    reference: string;
    previousReference: string;
    label: string;
    priceHT: number;
    priceTTC: number;
    supplier: SupplierModel;
    tva: TvaModel;
    tvaAccountingAccount: CustomerGroupComptableLine;
    accountingAccount: CustomerGroupComptableLine;
    genericPrestation: GenericPrestationModel;
    // category: CategoryModel;
    quantity?: number;
    interneDispo?: boolean;
    hasContract?: boolean;

    totalTVA?: number;
    quoteId?: string;
    isAvailable?: boolean;
    categories?: string[];
    purchaseUnit?: string;
    purchaseValue?: number;
    interventionRequired?: boolean;
    interventionType?: string;
    contractId?: string;
    contract?: ContractModel;
    minQuantity?: number;
    revisionAmount?: number;
    revisionPrestationAmount?: number;
    isPlanned?: boolean;
    isExpired?: boolean;
    isArchive?: boolean;
    isFullPerimeter?: boolean;
    perimeter: ContractPerimeterModel[] ;
    purchaseValueRemaining?: number;
    comment?: string;

    purchasePackageValue?: number;

    // cas d'une prestation forfaitaire
    isPackageService?: boolean; // au forfait
    mainEstablishment?: EstablishmentModel;
    recurrencePrestationType?: RecurrencePrestationTypeEnum;

    // price
    totalHT?: number;
    totalTTC?: number;
    priceHTPerUnit?: number;
    priceTTCPerUnit?: number;
    packagePriceHT?: number;
    packagePriceTTC?: number;

    generalSupplierCatalog: string;
    technicalSheet: string;
    safetyUsageProtocol: string;
}
