import {Component, Inject, OnInit} from '@angular/core';
import {CrudModeEnum} from '../../../../../../core/base/enum/crud-mode.enum';
import {ErrorApi} from '../../../../../../core/models/api/error-api';
import {Observable, Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SupplierService} from '../../supplier.service';
import {ActivatedRoute} from '@angular/router';
import {EstablishmentService} from "../../../establishment/establishment.service";
import {ClientNumberModel} from "../../../../../../core/models/client-number-model";
import {EstablishmentModel} from "../../../../../../core/models/establishment.model";
import {map} from "rxjs/operators";
import { AuthService } from '../../../../../../core/services/auth.service';

@Component({
    selector: 'vex-supplier-famille-create-update-delete',
    templateUrl: './supplier-client-number-create-update-delete.component.html',
    styleUrls: ['./supplier-client-number-create-update-delete.component.scss']
})
export class SupplierClientNumberCreateUpdateDeleteComponent implements OnInit {

    protected subscription = new Subscription();
    defaults: ClientNumberModel;
    mode: CrudModeEnum = CrudModeEnum.Create;
    form: FormGroup;
    loading: boolean = false;
    supplierId: string;
    establishments$: Observable<EstablishmentModel[]>;
    establishments: Array<any> = [];
    excludeIds: Array<any> = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<SupplierClientNumberCreateUpdateDeleteComponent>,
        private supplierService: SupplierService,
        private establishmentService: EstablishmentService,
        public activatedRoute: ActivatedRoute,
        public authService: AuthService
    ) {
        if (this.data) {
            this.defaults = this.data?.defaults ?? {} as ClientNumberModel;
            this.mode = this.data.mode;
        }
    }

    canCreateMode: boolean = false;
    canUpdateMode: boolean = false;
    canDeleteMode: boolean = false;
    canArchiveMode: boolean = false;

    ngOnInit(): void {
        this.initData();
        this.autocompleteEstablishment();
        this.initForm();
    }

    initData() {

        this.canCreateMode = this.isCreateMode();
        this.canUpdateMode = this.isUpdateMode();
        this.canDeleteMode = this.isDeleteMode();
        this.canArchiveMode = this.isArchiveMode();

        this.supplierId = this.data.id;
        this.data.entity?.clientNumbers?.map(clientNumber => {
            if (!clientNumber.isArchive) { this.excludeIds.push(clientNumber.establishment._id); }
        });
    }

    initForm() {
        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            establishment: new FormControl(this.defaults?.establishment, [Validators.required]),
            clientNumber: new FormControl(this.defaults?.clientNumber, [Validators.required])
        });
    }

    save(): void {
        if (this.isCreateMode()) {
            this.createItem();
        } else if (this.isUpdateMode()) {
            this.updateItem();
        }
    }

    close(data?: any): void {
        this.dialogRef.close(data);
    }

    isDeleteMode() {
        return this.mode === CrudModeEnum.Delete;
    }

    isCreateMode() {
        return this.mode === CrudModeEnum.Create;
    }

    isUpdateMode() {
        return this.mode === CrudModeEnum.Update;
    }

    isArchiveMode() {
        return this.mode === CrudModeEnum.Archive;
    }

    createItem() {
        this.loading = true;
        this.defaults = this.form.value;
        if (this.form.valid) {

            // @ts-ignore
            this.defaults.establishment = this.defaults.establishment._id;

            const sub = this.supplierService.createClientNumber(this.supplierId, this.defaults).subscribe(
                res => {
                    this.close(res);
                    this.loading = false;
                },
                err => {
                    this.setErrorsMessages(err?.error?.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub)
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    updateItem() {
        this.loading = true;
        this.defaults = this.form.value;
        if (this.form.valid) {
            const sub = this.supplierService.updateClientNumber(this.supplierId, this.defaults).subscribe(
                result => {
                    this.close(result);
                    this.loading = false;
                }, error => {
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub)
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    setErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.form.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.form.controls[error.property].setErrors(err);
        });
        this.form.markAllAsTouched();
    }

    deleteItem() {
        this.loading = true;
        const sub = this.supplierService.deleteClientNumber(this.supplierId, this.defaults?._id).subscribe(
            result => {
                this.close(result);
                this.loading = false;
            }, error => {
                this.setErrorsMessages(error.error.errors);
                this.loading = false;
            }
        );
        this.subscription.add(sub);
    }

    archiveItem() {
        this.loading = true;
        const sub = this.supplierService.deleteClientNumber(this.supplierId, this.defaults?._id).subscribe(
            result => {
                this.close(result);
                this.loading = false;
            }, error => {
                this.setErrorsMessages(error.error.errors);
                this.loading = false;
            }
        );
        this.subscription.add(sub);
    }

    async autocompleteEstablishment(search = '') {
        const user = this.authService.currentUserValue();
        let establishmentIds = user.affectations.map(x => x.establishment._id);

        // remove the excludeIds from the establishmentIds
        establishmentIds = establishmentIds.filter(id => !this.excludeIds.includes(id));


        this.establishments$ = this.establishmentService.findAllAsSelect(true, null, 'desc', search, {includeIds: establishmentIds, setLimit: true}).pipe(map(res => res.data));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
