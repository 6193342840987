import { Component, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { CategoryModel } from '../../../../../../core/models/category.model';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CategoryService } from '../../../../settings/category/category.service';
import {
    UserProfileValidatorLevel,
    userProfileValidatorLevelTransalation,
    UserProfileValidatorSeuil
} from '../../../../../../core/enums/user-profile-validator-seuil.enum';
import { UserProfileService } from '../../user-profile.service';
import { UserProfileModel } from '../../../../../../core/models/user-profile.model';
import { CustomerGroupService } from '../../../../settings/customer-group/customer-group.service';
import { AuthService } from '../../../../../../core/services/auth.service';
import { ENUM_PERMISSIONS } from '../../../../../../core/enums/permission.enum';
import { map, switchMap } from 'rxjs/operators';
import { EstablishmentService } from '../../../establishment/establishment.service';

@Component({
    selector: 'vex-user-profile-details-command-validator',
    templateUrl: './user-profile-details-command-validator.component.html',
    styleUrls: ['./user-profile-details-command-validator.component.scss']
})
export class UserProfileDetailsCommandValidatorComponent implements OnInit {

    constructor(
        public service: CategoryService,
        public userProfileService: UserProfileService,
        private customerGroupService: CustomerGroupService,
        private authService: AuthService,
        private establishmentService: EstablishmentService,
    ) {
    }

    treeControl = new NestedTreeControl<CategoryModel>(node => node.children);
    dataSource = new MatTreeNestedDataSource<CategoryModel>();

    UserProfileValidatorSeuilEnum = UserProfileValidatorSeuil;

    levels = [];
    currentLevel = UserProfileValidatorLevel.LOW;

    loading = false;
    loadingExport = false;
    userProfile: UserProfileModel;
    havePermissionForInput = false;

    hasChild = (_: number, node: CategoryModel) => !!node.children && node.children.length > 0;

    ngOnInit(): void {
        // this.userProfileService.entity$.pipe(
        //     switchMap(user => this.establishmentService.getSeuilForUserProfile(user?.affectations?.map(affectation => affectation.establishment?._id) ?? []))
        // )
        //     .subscribe(result => {
        //
        //         console.log(result)
        //
        //         this.currentLevel = result.data;
        //         console.log(this.currentLevel)
        //     });
        this.initData();
    }

    initData() {
        this.loading = true;
        this.treeControl = new NestedTreeControl<CategoryModel>(node => node.children);
        this.dataSource = new MatTreeNestedDataSource<CategoryModel>();
        this.levels = Object.keys(UserProfileValidatorLevel);
        this.authService.getCurrentUserPermissions$().subscribe(permissions => {


            this.havePermissionForInput = permissions.includes(ENUM_PERMISSIONS.UPDATE_INTERNAL_CONTACT);

        });
        this.userProfileService.entity$.subscribe(user => {
            this.userProfile = user;

            this.establishmentService
                .getThresholdForUserProfile(user?.affectations?.map(affectation => affectation.establishment?._id) ?? [])
                .pipe(
                    switchMap(result => {
                        this.currentLevel = result.data;
                        return this.userProfileService.getValidator(user._id, this.currentLevel);
                    }),
                    map(result => result.data)
                ).subscribe(validators => {
                this.setCategoriesValidators(validators);
            });
        });
        // this.userProfileService.validators$.subscribe(data => this.setCategoriesValidators(data));
    }

    async setValidator(category: CategoryModel, seuil: UserProfileValidatorSeuil) {
        const userProfile = await this.userProfileService.entity;

        if (this.havePermissionForInput) {
            this.userProfileService.setValidator(userProfile._id, category._id, seuil, this.currentLevel).subscribe();
        }
    }

    setCategoriesValidators(validators) {
        // Pose pas de questions
        // setTimeout(() => {
        if (validators.length > 0) {
            this.dataSource.data = [];
            this.dataSource.data = validators;
            this.loading = false;
        }
        // });
    }

    levelTranslation(level: string) {
        return userProfileValidatorLevelTransalation(UserProfileValidatorLevel[level]);
    }

    onLevelChange() {
        this.initData();
    }

    setAllValidator(seuil: UserProfileValidatorSeuil) {
        const userProfile = this.userProfile;
        this.userProfileService.setAllValidator(userProfile._id, seuil, this.currentLevel).subscribe();

    }


}
