import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EquipmentModel } from 'src/app/core/models/equipment.model';
import { EquipmentService } from '../equipment.service';
import { FormModalBaseComponent } from 'src/app/core/base/components/form-modal-base/form-modal-base.component';
import { GenericEquipmentService } from '../../../settings/generic-equipment/generic-equipment.service';
import { Observable } from 'rxjs';
import { GenericEquipmentModel } from '../../../../../core/models/generic-equipment.model';
import { map } from 'rxjs/operators';
import { EstablishmentService } from '../../establishment/establishment.service';
import { EstablishmentModel } from '../../../../../core/models/establishment.model';
import { setValidity, ValidityType } from '../../../../../core/base/models/validity.model';
import { EquipmentTypeService } from '../../../settings/equipment-type/equipment-type.service';
import { EquipmentTypeModel } from '../../../../../core/models/equipment-type.model';
import moment from 'moment';
import { UserProfileService } from '../../user-profile/user-profile.service';
import { AuthService } from '../../../../../core/services/auth.service';
import { ENUM_PERMISSIONS } from '../../../../../core/enums/permission.enum';
import { DetentionTypeModel } from '../../../../../core/models/detention-type.model';
import { DetentionTypeService } from '../../../settings/detention-type/detention-type.service';
import { MetrikStatusService } from '../../../settings/metrik-status/metrik-status.service';
import { MetrikStatusModuleEnum } from '../../../../../core/enums/metrik-status.enum';


@Component({
    selector: 'vex-equipment-create-update',
    templateUrl: './equipment-create-update-delete.component.html',
    styleUrls: ['./equipment-create-update-delete.component.scss']
})
export class EquipmentCreateUpdateDeleteComponent extends FormModalBaseComponent<EquipmentModel> implements OnInit {

    MetrikStatusModuleEnum = MetrikStatusModuleEnum;
    genericEquipment$: Observable<GenericEquipmentModel[]>;
    establisment$: Observable<EstablishmentModel[]>;
    equipmentType$: Observable<EquipmentTypeModel[]>;
    detentionType$: Observable<DetentionTypeModel[]>;
    contacts$: Observable<any[]>;

    date: Date = new Date();

    canDownloadTemplateModel: boolean = false;
    filter = (date: Date): boolean => {
        return true;
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<EquipmentModel>>,
                public service: EquipmentService,
                private genericEquipmentService: GenericEquipmentService,
                private establishmentService: EstablishmentService,
                private equipmentTypeService: EquipmentTypeService,
                private detentionTypeService: DetentionTypeService,
                private metrikStatusService: MetrikStatusService,
                private userProfileService: UserProfileService,
                private authService: AuthService
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initData();
        this.initForm();

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canDownloadTemplateModel = permissions.includes(ENUM_PERMISSIONS.READ_EQUIPMENT);
        });
    }

    initData(){
        this.genericEquipment$ = this.genericEquipmentService.findAll().pipe(map(x => x.data));
        this.establisment$ = this.establishmentService.findAll().pipe(map(x => x.data));
        this.detentionTypeAutoCompleteChange();

        if (this.defaults?.establishment) {
            this.contactTypeAutoCompleteChange('', this.defaults?.establishment?._id);
        }
    }

    initForm(){
        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            reference: new FormControl(this.defaults?.reference, [Validators.required]),
            establishment: new FormControl(this.defaults?.establishment, [Validators.required]),
            establishmentDisable: new FormControl(this.defaults?.establishment?.label),
            serialNumber: new FormControl(this.defaults?.serialNumber, []),
            mark: new FormControl(this.defaults?.mark, [Validators.required]),
            model: new FormControl(this.defaults?.model),
            genericEquipment: new FormControl(this.defaults?.genericEquipment, [Validators.required]),
            commissioningDate: new FormControl(this.defaults?.commissioningDate, [Validators.required]),
            endManufacturerDate: new FormControl(this.defaults?.endManufacturerDate, []),
            purchaseDate: new FormControl(this.defaults?.purchaseDate, []),
            startAmortizationDate: new FormControl(this.defaults?.startAmortizationDate, null),
            endAmortizationDate: new FormControl(this.defaults?.endAmortizationDate),
            valueAmortization: new FormControl(this.defaults?.valueAmortization, []),
            depreciationPeriod: new FormControl(this.defaults?.depreciationPeriod?.value, null),
            contactIntern: new FormControl(this.defaults?.contactIntern, null),
            detentionType: new FormControl(this.defaults?.detentionType, null),
        });

        this.form.controls.purchaseDate.valueChanges.subscribe(value => {
            if (value) {
                this.filter = (date: Date) => date >= moment(value).toDate();
            } else {
                this.filter = (date: Date) => true;
            }
        });

        if (this.isUpdateMode()) {
            this.form.addControl('startAmortizationDate', new FormControl(this.defaults?.startAmortizationDate, [Validators.required]));
        }

        this.form.controls.commissioningDate.valueChanges.subscribe(value => {
            if (value) this.form.controls.startAmortizationDate.setValue(value);
        })

        this.form.valueChanges.subscribe(control => {
            // check if commissioningDate and depreciationPeriod are not empty
            if (control.commissioningDate && control.depreciationPeriod) {
                // set value of enAmortizationDate with commissioningDate + depreciationPeriod (year)
                const endDate = new Date(control.commissioningDate);
                endDate.setFullYear(endDate.getFullYear() + control.depreciationPeriod)
                this.form.controls.endAmortizationDate.setValue(endDate.toISOString(), {emitEvent: false});
            } else {
                this.form.controls.endAmortizationDate.setValue(null, {emitEvent: false});
            }
        });


        this.form.controls.valueAmortization.valueChanges.subscribe(value => {
            if (value) {
                if (value >= 500) {
                    this.form.controls.depreciationPeriod.setValidators(Validators.required);
                } else {
                    this.form.controls.depreciationPeriod.clearValidators();
                    this.form.controls.depreciationPeriod.setValue(null);
                }
            }
        });


        this.form.controls.establishment.valueChanges.subscribe(value => {
            if (value) {
                this.contactTypeAutoCompleteChange('', value?._id);

                this.contacts$ = this.userProfileService.findAll(null, null, null, null, null, {establishment: value?._id, isReferentTechnic: true})
                    .pipe(
                        // @ts-ignore
                        map(response => response.data.map(y => ({...y, fullName: `${y.firstName} ${y.lastName}`})))
                    );
                this.form.controls.contactIntern.setValue(null);
            } else {
                this.contacts$ = null;
            }
        });

    }

    beforeCreateItem() {
        this.defaults.startAmortizationDate = this.defaults.commissioningDate;
        this.defaults.depreciationPeriod = setValidity(ValidityType.YEAR, this.form.controls.depreciationPeriod.value);
        this.defaults.amortization = this.service.createAmortization(this.defaults?.depreciationPeriod?.value, this.defaults?.valueAmortization, new Date(this.defaults?.startAmortizationDate));

        this.defaults.operationalStatus = {
            metrikStatus: this.form.controls.metrikStatus.value,
            startDate: this.form.controls.metrikStartDate?.value,
            endDate: this.form.controls.metrikEndDate?.value,
        };
    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    beforeUpdateItem() {
        this.defaults.endAmortizationDate = new Date(this.form.getRawValue().endAmortizationDate);
        this.defaults.depreciationPeriod = setValidity(ValidityType.YEAR, this.form.controls.depreciationPeriod.value);
        this.defaults.amortization = this.service.createAmortization(this.defaults?.depreciationPeriod?.value, this.defaults?.valueAmortization, new Date(this.defaults?.startAmortizationDate));
        this.defaults.operationalStatus = {
            metrikStatus: this.form.controls.metrikStatus.value,
            startDate: this.form.controls.metrikStartDate?.value,
            endDate: this.form.controls.metrikEndDate?.value,
        };
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    genericEquipmentAutoCompleteChange(state: any) {
        this.genericEquipment$ = this.genericEquipmentService.findAll(null, null, null, null, state).pipe(map(x => x.data));
    }

    establishmentAutoCompleteChange($event: any) {
        this.establisment$ = this.establishmentService.findAll(null, null, null, null, $event).pipe(map(x => x.data));
    }

    equipmentTypeAutoCompleteChange($event = '') {
        this.equipmentType$ = this.equipmentTypeService.findAll(null, null, null, null, $event).pipe(map(x => x.data));
    }

    detentionTypeAutoCompleteChange($event = '') {
        this.detentionType$ = this.detentionTypeService.findAll(null, null, null, null, $event).pipe(map(x => x.data));
    }

    contactTypeAutoCompleteChange($event = '', establishmentId = null) {

        if (!establishmentId && this.form.controls.establishment.value) {
            establishmentId = this.form.controls.establishment.value._id;
        }

        this.contacts$ = this.userProfileService.findAll(null, null, null, null, $event, {establishment: establishmentId, isReferentTechnic: true})
            .pipe(
                // @ts-ignore
                map(response => response.data.map(y => ({...y, fullName: `${y.firstName} ${y.lastName}`})))
            );;
    }


}
