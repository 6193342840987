import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from "rxjs/operators";

@Injectable()
export class PermissionInterceptor implements HttpInterceptor {
    // Liste des URLs à ignorer
    private skipUrls = ['/me'];
    // Flag pour suivre si on est déjà en train de gérer une erreur
    private isHandlingError = false;

    constructor(private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Vérifier si l'URL doit être ignorée
        if (this.shouldSkipUrl(request.url)) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
              // Vérifier si on est déjà en train de gérer une erreur
              if (this.isHandlingError) {
                  return throwError(() => error);
              }

              return this.handlePermissionError(error);
          })
        );
    }

    private shouldSkipUrl(url: string): boolean {
        return this.skipUrls.some(skipUrl => url.includes(skipUrl));
    }

    private handlePermissionError(err: HttpErrorResponse): Observable<any> {
        try {
            this.isHandlingError = true;

            if (err.status === 403) {
                this.router.navigate(['/unauthorized']);
                return of(err);
            }

            return throwError(() => err);
        } finally {
            // Réinitialiser le flag une fois terminé
            this.isHandlingError = false;
        }
    }
}
