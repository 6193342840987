import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PrestationModel} from 'src/app/core/models/prestation.model';
import {PrestationService} from '../prestation.service';
import {FormModalBaseComponent} from 'src/app/core/base/components/form-modal-base/form-modal-base.component';
import {SupplierService} from '../../supplier/supplier.service';
import {TvaService} from '../../../settings/tva/tva.service';
import {Observable} from 'rxjs';
import {SupplierModel} from '../../../../../core/models/supplier.model';
import {TvaModel} from '../../../../../core/models/tva.model';
import {map} from 'rxjs/operators';
import {GenericPrestationService} from '../../../settings/generic-prestation/generic-prestation.service';
import {GenericPrestationModel} from '../../../../../core/models/generic-prestation.model';
import {CategoryModel} from '../../../../../core/models/category.model';
import {CategoryService} from '../../../settings/category/category.service';
import {CustomerGroupComptableLine} from '../../../../../core/models/customer-group.model';
import {AuthService} from '../../../../../core/services/auth.service';
import {ENUM_PERMISSIONS} from '../../../../../core/enums/permission.enum';
import {quillConfig} from '../../../../../core/config/quill.config';

@Component({
    selector: 'vex-prestation-create-update',
    templateUrl: './prestation-create-update-delete.component.html',
    styleUrls: ['./prestation-create-update-delete.component.scss',
        '../../../../../../../node_modules/quill/dist/quill.snow.css',
        '../../../../../../@vex/styles/partials/plugins/_quill.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PrestationCreateUpdateDeleteComponent extends FormModalBaseComponent<PrestationModel> implements OnInit {

    suppliers$: Observable<SupplierModel[]>;
    genericPrestations$: Observable<GenericPrestationModel[]>;
    tva$: Observable<TvaModel[]>;
    tva: TvaModel[];
    categories$: Observable<CategoryModel[]>;
    purchaseValueLabel =  'Nombre d\'interventions';
    tvaAccountingAccounts: CustomerGroupComptableLine[];

    disabledTva = false;

    purchaseUnit = ['Intervention', 'Heure'];


    public readonly quillConfig = quillConfig;

    urlPattern: RegExp = /^(https?:\/\/[^\s/$.?#].[^\s]*|www\.[a-zA-Z0-9-]+\.[a-z]{2,})$/i;



    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<PrestationModel>>,
                public service: PrestationService,
                private genericPrestationService: GenericPrestationService,
                private supplierService: SupplierService,
                private tvaService: TvaService,
                public categoryService: CategoryService,
                private authService: AuthService
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit() {
        super.ngOnInit();

        this.disabledTva = true;

        this.genericPrestationAutocompleteChange(this.defaults?.genericPrestation?.label);
        this.supplierAutocompleteChange(this.defaults?.supplier?.label);
        this.tvaAutocompleteChange(this.defaults?.tva?.genericTva?.label);
        if (this.defaults.tva) {
            this.tvaAccountingAccounts = this.defaults.tva.accountingAccounts;
            if (this.tvaAccountingAccounts) {
                this.tvaAccountingAccounts.map(x => x.display = x.label + ' - ' + x.accountNumber);
                this.defaults.tvaAccountingAccount = this.tvaAccountingAccounts
                    .find(x => x.accountNumber === this.defaults.tvaAccountingAccount?.accountNumber);
            }
        }

        this.form = new FormGroup({
            _id: new FormControl(this.defaults?._id),
            technicalIdentifier: new FormControl(this.defaults?.technicalIdentifier),
            reference: new FormControl(this.defaults?.reference, []),
            previousReference: new FormControl(this.defaults?.previousReference, []),
            label: new FormControl(this.defaults?.label, [Validators.required]),
            genericPrestation: new FormControl(this.defaults?.genericPrestation, []),
            priceHT: new FormControl(this.defaults?.priceHT?.toFixed(5), [Validators.required]),
            priceTTC: new FormControl(Number.parseFloat((Math.round(this.defaults?.priceTTC * 100) / 100)?.toFixed(5))),
            purchaseUnit: new FormControl(this.defaults?.purchaseUnit, [Validators.required]),
            purchaseValue: new FormControl(this.defaults?.purchaseValue, [Validators.required]),
            supplier: new FormControl(this.data?.supplier ? this.data?.supplier : this.defaults.supplier ? this.defaults.supplier : null, [Validators.required]),
            tva: new FormControl(this.defaults?.tva, [Validators.required]),
            tvaAccountingAccount: new FormControl({value: this.defaults?.tvaAccountingAccount, disabled: !this.defaults.tva} ),
            quoteId: new FormControl(this.data?.id ? this.data?.id : this.defaults.quoteId ? this.defaults.quoteId : null),
            isAvailable: new FormControl(this.defaults?.isAvailable),
            interventionRequired: new FormControl(this.defaults?.interventionRequired),
            comment: new FormControl(this.defaults ? this.defaults.comment : null),
            generalSupplierCatalog : new FormControl(this.defaults ? this.defaults.generalSupplierCatalog : null, [Validators.pattern(this.urlPattern)]),
            technicalSheet : new FormControl(this.defaults ? this.defaults.technicalSheet : null, [Validators.pattern(this.urlPattern)]),
            safetyUsageProtocol : new FormControl(this.defaults ? this.defaults.safetyUsageProtocol : null, [Validators.pattern(this.urlPattern)]),
            // tslint:disable-next-line:max-line-length
            // category: new FormControl(this.isReadMode() ? this.defaults?.category?.link : this.defaults?.category, [Validators.required]),
        });

        if (this.isCreateMode()) {
            this.form.get('isAvailable').setValue(true);
            this.form.get('interventionRequired').setValue(false);
        }

        if (this.isUpdateMode()) {
        }

        this.form.controls.tva?.valueChanges.subscribe((e) => {
            if (e) {

            this.calculatePriceTTC();

            this.tvaAccountingAccounts = e?.accountingAccounts.map(x => {
                    x.display = x.accountNumber + ' - ' + x.label;
                    return x;
                });

            this.form.controls.tvaAccountingAccount.enable();
            }
        });



        this.form.controls.priceHT?.valueChanges.subscribe(e => {
            this.calculatePriceTTC();
        //     if (e && e !== "NaN") {
        //         const tva = this.form.getRawValue()?.tva?.historical[this.form.getRawValue()?.tva?.historical.length - 1].taux;
        //         this.form.controls.priceHT.disable();
        //         let number = e / (1 + (tva / 100));
        //         this.form.controls.priceHT.setValue(Math.round(number * 100) / 100);
        //     } else {
        //         this.form.controls.priceHT.enable();
        //         this.form.controls.priceHT.setValue(null);
        //     }
        });

        if (!this.isReadMode()){
            this.purchaseUnit.push('Prestation');

        }

        this.form.controls.interventionRequired.valueChanges.subscribe(value => {
            if (!value){
                const index = this.purchaseUnit.findIndex(el => el === 'Prestation');
                if (index === -1) {
                    this.purchaseUnit.push('Prestation');
                }


                this.form.controls.purchaseUnit.setValue('Prestation');
                this.form.controls.purchaseUnit.disable();
                this.purchaseValueLabel = 'Nombre de prestations';

            } else {
                const index = this.purchaseUnit.findIndex(el => el === 'Prestation');
                if (index !== -1) {
                    this.purchaseUnit.splice(index, 1);
                }
                // this.form.controls.purchaseUnit.setValue(null)
                this.form.controls.purchaseUnit.enable();
                this.purchaseValueLabel = 'Nombre d\'interventions';
            }
        });


        this.service.entity$.subscribe( entity => {
            this.defaults = entity;
        });
        console.log(this.data, 'data pur')
        console.log(this.defaults, 'datat defaut')
    }


    calculatePriceTTC() {
        const tva = this.form.getRawValue()?.tva?.taux;
        const number = this.form.getRawValue()?.priceHT * (1 + (tva / 100));
        this.form.controls.priceTTC.setValue(Number(number.toFixed(5)));
    }
    hasPermission() {
        return this.authService.isReferentMetier();
    }
    beforeCreateItem() {
        // @ts-ignore
        // this.defaults.category = this.defaults.category?._id;
    }
x
    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    beforeUpdateItem() {
        // @ts-ignore
        // this.defaults.category = this.defaults.category?._id;
        if (this.form.get('priceHT') !== null) {
            this.defaults.priceHT = +this.form.get('priceHT').value;
        }
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    categoriesChange(state: any) {
        this.categories$ = this.categoryService
            .findAll(null, null, null, null, state)
            .pipe(map(d => d.data));
    }

    genericPrestationAutocompleteChange(state: any = '') {

        if (this.defaults.contractId){
            this.genericPrestations$ = this.genericPrestationService.findAll(null, 50, 'label', 'asc', state, {contractEngagementIds: [this.defaults.contractId]}).pipe(map(d => d.data));

        }else {
            this.genericPrestations$ = this.genericPrestationService
              .findAll(null, null, 'label', 'asc', state)
              .pipe(map(d => d.data));
        }


    }


    isPredik()
    {
        return this.authService.isPredik();
    }

    supplierAutocompleteChange(state: any = '') {
        this.suppliers$ = this.supplierService
            .findAll(null, null, 'label', 'asc', state)
            .pipe(map(d => d.data));
    }

    tvaAutocompleteChange(state: any = '') {
        this.tva$ = this.tvaService
            .findAll(null, null, 'label', 'asc', state)
            .pipe(map(d => d.data));
    }

    isArchived() {
        return this.defaults.isArchive;
    }

}
