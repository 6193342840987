<base-sidenav (onClose)="close()" (onClear)="clear()" (onValidate)="validate()" [standardForms]="standardForms" ></base-sidenav>


<ng-template #standardForms>
    <form [formGroup]="getFormGroup('standardForm')">

        <base-form-input
            label="Identifiant technique"
            withoutPadding="true"
            appearance="outline"
            controlName="technicalIdentifier"
        >
        </base-form-input>

        <base-form-input
            label="Réf. Fournisseur"
            withoutPadding="true"
            appearance="outline"
            controlName="reference"
        >
        </base-form-input>

        <base-form-input
            (autocompleteChange)="autocompleteSuppliers($event)"
            [items$]="getItems$('suppliers')"
            (onScroll)="onScrollLine($event, 'suppliers')"
            appearance="outline"
            controlName="suppliers"
            displayProperty="label"
            label="Fournisseur"
            type="search-multiple"
            withoutPadding="true"
        ></base-form-input>

        <base-form-input
            (autocompleteChange)="autocompleteGenericProducts($event)"
            [items$]="getItems$('genericProducts')"
            (onScroll)="onScrollLine($event, 'genericProducts')"
            appearance="outline"
            controlName="genericProducts"
            displayProperty="label"
            label="Article générique"
            type="search-multiple"
            withoutPadding="true"
        ></base-form-input>

        <base-form-input
            (autocompleteChange)="autocompleteProductsFields($event, 'label')"
            [items$]="getItems$('label')"
            (onScroll)="onScrollLine($event, 'label')"
            label="Libellé"
            withoutPadding="true"
            appearance="outline"
            displayProperty="label"
            controlName="labels"
            type="search-multiple"
        ></base-form-input>

        <base-form-input
            (autocompleteChange)="autocompleteProductsFields($event, 'manufacturer')"
            [items$]="getItems$('manufacturer')"
            (onScroll)="onScrollLine($event, 'manufacturer')"
            label="Fabricant/Marque"
            withoutPadding="true"
            appearance="outline"
            displayProperty="manufacturer"
            controlName="manufacturer"
            type="search-multiple"
        ></base-form-input>

<!--        <base-form-input-->
<!--            [items]="[{label: 'Oui', value: true}, {label: 'Non', value: false}]"-->
<!--            label="Périssable"-->
<!--            displayProperty="label"-->
<!--            appearance="outline"-->
<!--            type="select"-->
<!--            controlName="isPerishable"-->
<!--        ></base-form-input>-->

        <base-form-input
            [items]="[{label: 'Oui', value: true}, {label: 'Non', value: false}]"
            label="Disponible au catalogue"
            displayProperty="label"
            appearance="outline"
            type="select"
            controlName="isAvailable"
        ></base-form-input>

    </form>
</ng-template>