import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SupplierService} from "../../supplier.service";
import {ActivatedRoute} from "@angular/router";
import {AddressModel} from "../../../../../../core/models/address.model";
import {CrudModeEnum} from "../../../../../../core/base/enum/crud-mode.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {ErrorApi} from "../../../../../../core/models/api/error-api";
import {AddressType} from "../../../../../../core/enums/address-type.enum";
import {Country} from "../../../../../../core/enums/countries.enum";
// test gitflow
@Component({
  selector: 'vex-supplier-address-create-update-delete',
  templateUrl: './supplier-address-create-update-delete.component.html',
  styleUrls: ['./supplier-address-create-update-delete.component.scss']
})
export class SupplierAddressCreateUpdateDeleteComponent implements OnInit {

  protected subscription = new Subscription();
  defaults: AddressModel;
  mode: CrudModeEnum = CrudModeEnum.Create;
  form: FormGroup;
  loading: boolean = false;
  supplierId: string;
  typeItem: AddressType[];
  countryItem: Country[];

  constructor(
      @Inject(MAT_DIALOG_DATA) public data,
      public dialogRef: MatDialogRef<SupplierAddressCreateUpdateDeleteComponent>,
      private supplierService: SupplierService,
      public activatedRoute: ActivatedRoute,
  ) {
    if (this.data) {
      this.defaults = this.data?.defaults ?? {} as AddressModel;
      this.mode = this.data.mode;
    }
  }

  canCreateMode : boolean = false;
  canUpdateMode : boolean = false;
  canDeleteMode : boolean = false;

  ngOnInit(): void {
    this.initData();
    this.initForm();
  }

  initData() {

    this.canCreateMode = this.isCreateMode();
    this.canUpdateMode = this.isUpdateMode();
    this.canDeleteMode = this.isDeleteMode();

    this.supplierId = this.data.id;
    this.typeItem = Object.values(AddressType);
    this.countryItem = Object.values(Country);
  }

  initForm(){
    this.form = new FormGroup({
      _id: new FormControl(this.defaults?._id),
      label: new FormControl(this.defaults?.label, [Validators.required]),
   //   type: new FormControl(this.defaults?.type, [Validators.required]),
      street: new FormControl(this.defaults?.street, [Validators.required]),
      additionalAddress: new FormControl(this.defaults?.additionalAddress),
      postalCode: new FormControl(this.defaults?.postalCode, [Validators.required]),
      city: new FormControl(this.defaults?.city, [Validators.required]),
      country: new FormControl(this.defaults?.country, [Validators.required])
    });
  }

  save(): void {
    if (this.isCreateMode()) {
      this.createItem();
    } else if (this.isUpdateMode()) {
      this.updateItem();
    }
  }

  close(data?: any): void {
    this.dialogRef.close(data);
  }

  isDeleteMode() {
    return this.mode === CrudModeEnum.Delete;
  }

  isCreateMode() {
    return this.mode === CrudModeEnum.Create;
  }

  isUpdateMode() {
    return this.mode === CrudModeEnum.Update;
  }

  createItem() {
    this.loading = true;
    this.defaults = this.form.value;
    if (this.form.valid) {
      const sub = this.supplierService.createAddress(this.supplierId, this.defaults).subscribe(
          res => {
            this.close(res);
            this.loading = false;
          },
          err => {
            this.setErrorsMessages(err.error.errors);
            this.loading = false;
          }
      );
      this.subscription.add(sub);
    } else {
      this.form.markAllAsTouched();
      this.loading = false;
    }
  }

  updateItem() {
    this.loading = true;
    this.defaults = this.form.value;

    if (this.form.valid) {
      const sub = this.supplierService.updateAddress(this.supplierId, this.defaults).subscribe(
          result => {
            this.close(result);
            this.loading = false;
          }, error => {
            this.setErrorsMessages(error.error.errors);
            this.loading = false;
          });
      this.subscription.add(sub);
    } else {
      this.form.markAllAsTouched();
      this.loading = false;
    }
  }

  setErrorsMessages(errors: Array<ErrorApi>) {
    errors?.forEach(error => {
      let err = this.form.controls[error.property].errors;
      if (err === null) {
        err = {messages: []};
      }
      err.messages.push(error.message);
      this.form.controls[error.property].setErrors(err);
    });
    this.form.markAllAsTouched();
  }

  deleteItem() {
    this.loading = true;
    const sub = this.supplierService.deleteAddress(this.supplierId, this.defaults?._id).subscribe(
        result => {
          this.close(result);
          this.loading = false;
        }, error => {
          this.setErrorsMessages(error.error.errors);
          this.loading = false;
        }
    );
    this.subscription.add(sub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
