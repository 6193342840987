<ng-container class="h10">
    <form (ngSubmit)="save()" [formGroup]="form">
        <base-form-header createLabel='Ajouter une date de fin de service' [updateLabel]='' (onClose)="close(false)"></base-form-header>

        <mat-dialog-content fxLayout="column" *ngIf="!isConfirmed">


          <base-form-input
            label='Date de fin de service'
            controlName='endServiceDate'
            type='date'
            [minDate]="today"
          >
          </base-form-input>

          <metrik-status
            [form]="form"
            [module]="MetrikStatusModuleEnum.EQUIPMENT"
            [operationalStatus]='this.defaults?.operationalStatus'
            [isDisabledDate]="true"
            [isOnlyProcessExited]="true"
          >
          </metrik-status>
        </mat-dialog-content>

        <ng-container *ngIf="isConfirmed">
            {{ confirmationText }}
        </ng-container>

        <mat-dialog-actions align="end">
            <base-form-input
                    type='submit'
                    [mode]='mode'
                    entityLabel='EQUIPMENT'
                    [loading]='loading'
                    (onCreate)='createItem()'
            >
            </base-form-input>
        </mat-dialog-actions>
    </form>

</ng-container>
