import {BaseService} from '../../../../core/base/base.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RateList, GenericTVAModel} from '../../../../core/models/generic-tva.model';
import { BehaviorSubject, Observable } from 'rxjs';
import {ObjectApiInterface} from '../../../../core/base/interfaces/object-api.interface';
import {map} from 'rxjs/operators';
import { isArray } from 'chart.js/helpers';

@Injectable({
    providedIn: 'root'
})
export class GenericTvaService extends BaseService<GenericTVAModel> {
    defaultSort = 'createdAt';
    protected _genericTvas = new BehaviorSubject<GenericTVAModel[]>(null);
    readonly genericTvas$ = this._genericTvas.asObservable();

    constructor(public http: HttpClient) {
        super(http, 'generic-tva');
    }
    // findAll(): Observable<ObjectApiInterface<GenericTVAModel[]>> {
    //     return this.http.get<ObjectApiInterface<GenericTVAModel[]>>(`${this.baseUrlApi}/list`)
    //         .pipe(map(x => {
    //             this._genericTvas.next(x.data);
    //             return x;
    //         }));
    // }

    getCurrentRateCustomerGroup(rateList: RateList[], date = new Date()) {
        const filteredRates = rateList.filter((rate: RateList) => {
            return new Date(rate.startDate) <= date;
        });
        return filteredRates.reduce((latestRate: RateList, currentRate: RateList) => {
            return !latestRate || new Date(currentRate.startDate) > new Date(latestRate.startDate)
                ? currentRate
                : latestRate;
        }, null);
    }


    // New function to return the first index of the rateList (the last date added)
    getCurrentRate(rateList: RateList[], date = new Date()) {
        return isArray(rateList) ? rateList[0] : null;
    }

    addRate(idTva: string, data: RateList): Observable<ObjectApiInterface<GenericTVAModel>> {
        return this.http.post<ObjectApiInterface<GenericTVAModel>>(`${this.baseUrlApi}/${idTva}/addRate`, data)
            .pipe(map(x => {
                this._entity.next(x.data);
                return x;
            }));
    }
}
