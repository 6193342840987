import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ObjectApiInterface} from '../../../base/interfaces/object-api.interface';
import {filter, map} from 'rxjs/operators';
import {ConfigService} from '../../../../../@vex/services/config.service';
import {SnackbarService} from '../../../services/snackbar.service';
import ApexCharts from 'apexcharts';
import {b64ToBlob} from '../../../helpers/b64ToBlob.helper';
import {ContentTypeEnum} from '../../../base/enum/content-type.enum';
import {FileSaverService} from 'ngx-filesaver';

export enum IdentifierEnum {
    MESDEMANDES_ESHOP = 'MESDEMANDES_ESHOP',
    MESDEMANDES_ETICKET = 'MESDEMANDES_ETICKET',
    QUOTE_STATUSES = 'QUOTE_STATUSES',
    BC_STATUSES = 'BC_STATUSES',
    BCR_STATUSES = 'BCR_STATUSES',
    BILL_STATUSES = 'BILL_STATUSES',
    CONTRACT_VALIDATION = 'CONTRACT_VALIDATION',
}

export enum ChartTypesEnum {
    DONUT = 'donut',
    BAR = 'bar',
}

export enum DataIncidentEnum {
    ESTABLISHMENTS_WITHOUT_BUILDING = 'ESTABLISHMENTS_WITHOUT_BUILDING',
    ESTABLISHMENTS_WITHOUT_ACCOUNTANT = 'ESTABLISHMENTS_WITHOUT_ACCOUNTANT',
    ESTABLISHMENTS_WITHOUT_AXIS = 'ESTABLISHMENTS_WITHOUT_AXIS',
    BUILDINGS_WITHOUT_ACTIVITY = 'BUILDINGS_WITHOUT_ACTIVITY',
    BUILDINGS_WITHOUT_ESTABLISHMENT = 'BUILDINGS_WITHOUT_ESTABLISHMENT',
    BUILDINGS_WITHOUT_ROOM = 'BUILDINGS_WITHOUT_ROOM',
    EQUIPMENT_WITHOUT_CONTACT_INTERN = 'EQUIPMENT_WITHOUT_CONTACT_INTERN',
    SUPPLIERS_WITHOUT_FAMILY = 'SUPPLIERS_WITHOUT_FAMILY',
    FAMILY_WITHOUT_ACTOR = 'FAMILY_WITHOUT_ACTOR',
    ESTABLISHMENTS_WITHOUT_ACTIVITY_ADDRESS = 'ESTABLISHMENTS_WITHOUT_ACTIVITY_ADDRESS',
    ESTABLISHMENTS_WITHOUT_DELIVERY_ADDRESS = 'ESTABLISHMENTS_WITHOUT_DELIVERY_ADDRESS',
    ESTABLISHMENTS_WITHOUT_BILLING_ADDRESS = 'ESTABLISHMENTS_WITHOUT_BILLING_ADDRESS',
    EQUIPMENT_WITHOUT_DATA = 'EQUIPMENT_WITHOUT_DATA',
}

export class ChartData {
    labels: string[] = [];
    data: number[] = [];
    identifier: IdentifierEnum;
    type: ChartTypesEnum;
}

export class DashboardDataResponse {
    charts: ChartData[] = [];
    dataIncidents: DataIncident[] = [];

    constructor(charts: ChartData[], dataIncidents: DataIncident[]) {
        this.charts = charts;
        this.dataIncidents = dataIncidents;
    }
}

class ChartExportResponse {
    b64: string;
    fileName: string;

    constructor(b64: string, fileName: string) {
        this.b64 = b64;
        this.fileName = fileName;
    }
}

export class DataIncident {
    identifier: DataIncidentEnum;
    value: number;
}

export class DetailsDataIncident {
    id: string; // id of target resource
    subId?: string; // id of sub resource (example : id of category of establishment)
    label: string; // label of target resource
    identifier: DataIncidentEnum; // identifier of data incident
}

@Injectable()
export class DashboardService implements OnDestroy {

    private _dashboardData: BehaviorSubject<DashboardDataResponse> = new BehaviorSubject<DashboardDataResponse>(null);
    readonly dashboardData$: Observable<DashboardDataResponse> = this._dashboardData.asObservable().pipe(filter((chart) => !!chart));
    private chartsRendered: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private _loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly loaded$: Observable<boolean> = this._loaded.asObservable();
    private subscription: Subscription = new Subscription();
    private baseUrlApi = '/api/dashboard';

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private snackbar: SnackbarService,
        private fileSaver: FileSaverService) {
        this._init();
    }

    public static dataIncidentIdentifierTranslation(dataIncidentIdentifier: DataIncidentEnum): string {
        switch (dataIncidentIdentifier) {
            case DataIncidentEnum.ESTABLISHMENTS_WITHOUT_BUILDING:
                return 'Établissement(s) sans bâtiment';
            case DataIncidentEnum.ESTABLISHMENTS_WITHOUT_ACCOUNTANT:
                return 'Établissement(s) sans comptable';
            case DataIncidentEnum.ESTABLISHMENTS_WITHOUT_AXIS:
                return 'Établissement(s) sans plan analytique';
            case DataIncidentEnum.BUILDINGS_WITHOUT_ACTIVITY:
                return 'Bâtiment(s) sans activité';
            case DataIncidentEnum.BUILDINGS_WITHOUT_ESTABLISHMENT:
                return 'Bâtiment(s) sans établissement';
            case DataIncidentEnum.BUILDINGS_WITHOUT_ROOM:
                return 'Bâtiment(s) sans salle';
            case DataIncidentEnum.EQUIPMENT_WITHOUT_CONTACT_INTERN:
                return 'Équipement(s) sans référent';
            case DataIncidentEnum.SUPPLIERS_WITHOUT_FAMILY:
                return 'Fournisseurs(s) sans poste de dépenses';
            case DataIncidentEnum.FAMILY_WITHOUT_ACTOR:
                return 'Poste(s) de dépense où manque au moins un acteur';
            case DataIncidentEnum.ESTABLISHMENTS_WITHOUT_ACTIVITY_ADDRESS:
                return 'Établissement(s) sans adresse d\'activité';
            case DataIncidentEnum.ESTABLISHMENTS_WITHOUT_DELIVERY_ADDRESS:
                return 'Établissement(s) sans adresse de livraison';
            case DataIncidentEnum.ESTABLISHMENTS_WITHOUT_BILLING_ADDRESS:
                return 'Établissement(s) sans adresse de facturation';
            case DataIncidentEnum.EQUIPMENT_WITHOUT_DATA:
                return 'Équipement(s) incomplets';
            default:
                return dataIncidentIdentifier;
        }
    }

    public getDataIncident(incident: DataIncidentEnum): Observable<DetailsDataIncident[]> {
        return this.http.get<ObjectApiInterface<DetailsDataIncident[]>>(`${this.baseUrlApi}/data-incident/${incident}`)
            .pipe(map((value: ObjectApiInterface<DetailsDataIncident[]>) => {
                return value.data;
            }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public getDataIncidents(): Observable<DataIncident[]> {
        return this.dashboardData$.pipe(
            map((data: DashboardDataResponse) => {
                return data.dataIncidents;
            })
        );
    }

    public refreshDashboardData(establishmentsIds: string[]) {
        this._loaded.next(false);

        this.http
            .get<ObjectApiInterface<DashboardDataResponse>>('/api/dashboard/charts-config-v2', {
                params: new HttpParams()
                    .set('establishmentsIds', establishmentsIds.join(','))
            })
            .pipe(map((value: ObjectApiInterface<DashboardDataResponse>) => {
                return value.data;
            }))
            .subscribe({
                    next: (charts: DashboardDataResponse) => {
                        this._dashboardData.next(charts);
                    }
                }
            );
    }

    public exportChartData(establishmentsIds: string[], chart: IdentifierEnum) {
        this.http
            .get<ObjectApiInterface<ChartExportResponse>>('/api/dashboard/export-chart-data/' + chart, {
                params: new HttpParams()
                    .set('establishmentsIds', establishmentsIds.join(','))
            })
            .pipe(
                map((value: ObjectApiInterface<ChartExportResponse>) => {
                    return value.data;
                })
            )
            .subscribe((chart: ChartExportResponse) => {
                const b = b64ToBlob(chart.b64, ContentTypeEnum.excel);
                this.fileSaver.save(b, chart.fileName + '.xlsx');
            });
    }

    public getNotificationsCount(): Observable<{ severityMedium: number; severityLow: number; severityHigh: number }> {
        return this.http.get<ObjectApiInterface<{
            severityMedium: number;
            severityLow: number;
            severityHigh: number
        }>>(`${this.baseUrlApi}/notifications-count`)
            .pipe(map((value: ObjectApiInterface<{
                severityMedium: number;
                severityLow: number;
                severityHigh: number
            }>) => {
                return value.data;
            }));
    }

    public getCharts(): Observable<ChartData[]> {
        return this.dashboardData$.pipe(
            map((data: DashboardDataResponse) => {
                return data.charts;
            })
        );
    }

    private _init() {
        // Used to render/re-render the charts when we get them from backend
        this.subscription.add(
            this.dashboardData$.subscribe((charts: DashboardDataResponse) => {
                this._renderWhenReady(charts);
            })
        );
    }

    private _renderWhenReady(chartsDataResponse: DashboardDataResponse) {
        const charts = chartsDataResponse.charts;
        const totalCharts = charts.length;
        this.chartsRendered.next(0);

        const baseLegend = {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            fontWeight: 400,
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            },
            labels: {
                colors: ['black'],  // or 'rgb(0, 73, 104)' for thematic consistency
                offsetX: 0,
                style: {
                    fontSize: '14px'
                }
            }
        };

        for (const chart of charts) {
            // We define the options for the bar charts
            const barOptions = {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: false, // Used to stack multiple bars on top of each other, useless for now
                    toolbar: {
                        show: false // Small menu to download the chart in PNG, SVG, etc. (might be configurable, idk, for now I'll just disable it)
                    }
                },
                series: [{
                    name: 'Statuts',
                    data: chart.data
                }],
                xaxis: {
                    categories: chart.labels,
                    labels: {
                        show: false,
                        style: {
                            colors: ['black']  // or 'rgb(0, 73, 104)' for thematic consistency
                        }
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                colors: [
                    'rgb(0, 73, 104)',       // Original primary
                    'rgb(0, 93, 124)',       // Slightly brighter shade
                    'rgb(0, 113, 144)',      // Brighter shade
                    'rgb(0, 133, 164)',      // Even brighter shade
                    'rgb(0, 153, 184)'       // Lightest shade
                ],
                plotOptions: {
                    bar: {
                        distributed: true,
                        columnWidth: '50%',
                        horizontal: true, // Here you can change the orientation of the chart
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: ['black']  // or 'rgb(0, 73, 104)' for thematic consistency
                        }
                    }
                },
                grid: {
                    borderColor: 'rgb(230, 230, 233)'
                },
                legend: {
                    ...baseLegend,
                    show: false
                },
            };

            // We define the options for the donut charts
            const donutOptions = {
                chart: {
                    type: 'donut',
                    height: 350,
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '65%', // Adjusts the size of the donut hole
                            labels: {
                                show: true,
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: 'Total',
                                    fontSize: '22px',
                                    fontWeight: 600,
                                    color: '#373d3f',
                                    formatter(w) {
                                        return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                    }
                                }
                            }
                        }
                    }
                },
                series: chart.data,
                labels: chart.labels,
                legend: baseLegend,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: '100%'
                        },
                        legend: {
                            show: false
                        }
                    }
                }],
                dataLabels: {
                    enabled: true,
                    formatter(val, opts) {
                        return opts.w.globals.series[opts.seriesIndex];
                    },
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                    },
                    dropShadow: {
                        enabled: false
                    }
                },
                tooltip: {
                    y: {
                        formatter(val, opts) {
                            const chartsTotal: number = opts.globals?.seriesTotals?.reduce((a, b) => a + b, 0);
                            const pourcentage: number = val * 100 / chartsTotal;

                            return pourcentage ? pourcentage.toFixed(2) + '%' : val;
                        }

                    }
                },
                colors: ['rgb(0, 73, 104)', 'rgb(70, 168, 223)'],  // You can add more colors if there are more data points
            };

            let options;

            // We choose the options depending on the chart type
            switch (chart.type) {
                case ChartTypesEnum.DONUT:
                    options = donutOptions;
                    break;
                case ChartTypesEnum.BAR:
                    options = barOptions;
                    break;
                default:
                    options = null;
            }

            // We wait for the chart to be rendered in the DOM before rendering it
            const interval = setInterval(() => {
                if (document.querySelector('#' + chart.identifier)) {
                    new ApexCharts(document.querySelector('#' + chart.identifier), options).render().then(() => {
                        // Increase the rendered chart count
                        const currentRendered = this.chartsRendered.value + 1;
                        this.chartsRendered.next(currentRendered);

                        // Check if all charts are rendered
                        if (currentRendered === totalCharts) {
                            this._loaded.next(true);
                        }
                    }).catch((err) => {
                        this.snackbar.danger('Une erreur est survenue lors du chargement des ' +
                            'graphiques. Veuillez réessayer plus tard.');
                        console.error(err);
                    });
                    clearInterval(interval);
                }
            }, 1000);
        }
    }
}


