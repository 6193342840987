<ng-container *ngIf='!loading; else loadingContent'>
    <button type='button' mat-icon-button (click)='close(false)' tabindex='-1' class="sticky top-0 right-10 w-full flex justify-end">
        <mat-icon class=" p-2 rounded-full bg-white" [icIcon]='icClose'></mat-icon>
    </button>
    <ng-container *ngIf="!errorMessage; else errorContent">
        <div class='card w-full h-full'>
<!--            <object class='mt-6 w-full p-6' style="height: 800px" *ngIf='url' [data]='url' type='application/pdf'>-->
<!--                <img [src]="url"/>-->
<!--            </object>-->
            <object [data]='url' type="application/pdf"
                    class="w-full h-full"
            >
                <iframe class="h-full w-full"
                        [src]="url"
                >
                    <p>This browser does not support PDF!</p>
                </iframe>
            </object>
        </div>

        <div class="mt-4" fxLayout='row' fxLayoutAlign='space-between center'>
            <div></div>
            <div *ngIf='showToPayButton'>
                <!-- TODO PERMISSION
                <button mat-raised-button *hasPermission="[ENUM_PERMISSION.VALIDATE_FM_PURCHASE_INVOICE_PAYMENT]" (click)="close(true)" color="primary">A payer</button>
                -->
                <button mat-raised-button (click)="close(true)" color="primary">A payer</button>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-template #errorContent>
    <div class="card p-4 mt-6" fxLayout="column" fxLayoutAlign="center center">
        <span class="·text-red text-lg">Impossible de visualiser le document</span>
        <img src="assets/img/illustrations/it_support.svg" style="height: 168px; width: 152px">
    </div>
</ng-template>

<ng-template #loadingContent>
    <div class='card h-64 mt-6'>
        <div class='flex justify-center items-center h-full'>
            <mat-spinner color='primary'></mat-spinner>
        </div>
    </div>
</ng-template>
