<div class='mt-6 flex flex-col md:flex-row md:items-start'>
    <div class='flex-auto'>
        <div class="card mt-6 ">
            <!-- PREVIOUS PERMISSION
            [createPermission]="[ENUM_PERMISSION.ADD_FM_BF_SUPPLIER]"
            [importPermission]="[ENUM_PERMISSION.IMPORT_FM_BF_SUPPLIER]"
            -->
            <base-data-table-header
                    [label]='entityName'
                    [loading]='loadingData'
                    [showArchives]="false"
                    (onSearchChange)='onSearchChange($event)'
                    (onClearTextFilter)='clearTextFilter()'
                    (onCreate)='addClientNumber()'
                    [showSearch]=false
                    [backgroundColorWhite]="true"
                    [createPermission]="[ENUM_PERMISSION.UPDATE_SUPPLIER]"
                    [importPermission]="[ENUM_PERMISSION.UPDATE_SUPPLIER]"
            ></base-data-table-header>

            <!-- PREVIOUS PERMISSION
            [updatePermission]="[ENUM_PERMISSION.UPDATE_FM_BF_SUPPLIER_INFO_BUYING_FAMILY]"
            [deletePermission]="[ENUM_PERMISSION.DELETE_FM_BF_SUPPLIER_INFO]"
            -->
            <base-data-table
                    [count]='count'
                    [pageSize]='pageSize'
                    [sortField]='sortField'
                    [sortOrder]='sortOrder'
                    (onPageEvent)='pageEvent($event)'
                    (onSortEvent)='sortEvent($event)'
                    (onUpdateItem)='updateClientNumber($event)'
                    (onDeleteItem)='deleteClientNumber($event)'
                    (onArchiveItem)='archiveItem($event)'
                    [dataSource]='dataSourceCode'
                    [displayedColumns]='displayedColumnsCode'
                    [feisColumns]='feisColumns'
                    [showPaginator]='false'
                    [showSort]='false'
            ></base-data-table>
        </div>
    </div>
</div>
