import {BaseService} from '../../../../core/base/base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GenericMotifModel} from '../../../../core/models/generic-motif.model';
import {ObjectApiInterface} from "../../../../core/base/interfaces/object-api.interface";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class GenericMotifService extends BaseService<GenericMotifModel> {
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'generic-motif');
    }

    findAll(page?: number,
            perPage?: number,
            sortField: string = this.defaultSort,
            sortOrder: string = 'desc',
            search?: string,
            filters?: any): Observable<ObjectApiInterface<any[]>> {
        return this.http.get<ObjectApiInterface<any[]>>(`${this.baseUrlApi}/list`, {
                params: new HttpParams()
                    .set('page', page?.toString() ?? '1')
                    .set('perPage', perPage?.toString() ?? '500')
                    .set('sort', `${sortField}@${sortOrder}`)
                    .set('search', search ?? '')
                    .set('filters', JSON.stringify(filters) ?? '')
            }
        );
    }
}
