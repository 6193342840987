import {Component, Inject, OnInit} from "@angular/core";
import {CrudModeEnum} from "../../../../../../core/base/enum/crud-mode.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
    FormModalBaseComponent
} from "../../../../../../core/base/components/form-modal-base/form-modal-base.component";
import {FileSaverService} from "ngx-filesaver";
import {EstablishmentModel} from "../../../../../../core/models/establishment.model";
import {EstablishmentService} from "../../establishment.service";
import {ErrorApi} from "../../../../../../core/models/api/error-api";
import {b64ToBlob} from "../../../../../../core/helpers/b64ToBlob.helper";
import {ContentTypeEnum} from "../../../../../../core/base/enum/content-type.enum";
import {AuthService} from "../../../../../../core/services/auth.service";
import {ENUM_PERMISSIONS} from "../../../../../../core/enums/permission.enum";

@Component({
    selector: "vex-establishment-analytics-create-update-delete",
    templateUrl: "./establishment-analytics-create-update-import.component.html",
    styleUrls: ["./establishment-analytics-create-update-delete.component.scss"]
})
export class EstablishmentAnalyticsCreateUpdateDeleteComponent extends FormModalBaseComponent<EstablishmentModel> implements OnInit {

    loading = false;
    mode: CrudModeEnum = CrudModeEnum.Update;
    form: FormGroup;
    importForm: FormGroup;
    importDefaults: any;
    types = [];
    months = [];
    defaults: any;
    downloadModel = false;

    canDownloadTemplatePermissions: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<EstablishmentModel>>,
                public service: EstablishmentService,
                private fileSaver: FileSaverService,
                private authService: AuthService) {
        super(data, dialogRef, service);
    }

    ngOnInit(): void {
        this.initData();
    }

    initData() {
        this.mode = this.data.mode;
        this.defaults = this.data.defaults;

        if (this.isImportMode()) {
            this.importForm = new FormGroup({
                file: new FormControl("", [Validators.required])
            });
        }

        if (this.isCreatedMode()) {
            this.form = new FormGroup({
                _id: new FormControl(this.defaults?._id),
                label: new FormControl(this.defaults?.label, [Validators.required]),
                accountNumber: new FormControl(this.defaults?.accountNumber, [Validators.required])
            });
        }

        if (this.isUpdateMode() || this.isArchiveMode()) {
            this.form = new FormGroup({
                _id: new FormControl(this.defaults?._id),
                label: new FormControl(this.defaults?.label),
                accountNumber: new FormControl(this.defaults?.accountNumber)
            });
        }

        this.authService.getCurrentUserPermissions$().subscribe(permissions => {
            this.canDownloadTemplatePermissions = permissions.includes(ENUM_PERMISSIONS.READ_ESTABLISHMENT)
        })

    }

    //Create mode
    isCreatedMode(): boolean {
        return this.mode === CrudModeEnum.Create;
    }

    async createItem() {
        const entity = await this.service.entity;

        if (this.form.valid) {
            this.loading = true;
            this.defaults = this.form.getRawValue();
            this.service.createAnalyticsLine(entity._id, this.defaults).subscribe(res => {
                this.dialogRef.close(res);
            }, error => {
                this.setErrorsMessages(error.error.errors);
                this.loading = false;
            });
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    async updateItem() {
        const entity = await this.service.entity;

        this.defaults = this.form.getRawValue();

        this.service.updateAnalyticsLine(entity._id, this.defaults).subscribe(res => {
            this.dialogRef.close(res);
        }, error => {
            this.setErrorsMessages(error.error.errors);
            this.loading = false;
        });
    }

    async archiveItem() {
        const entity = await this.service.entity;

        this.defaults = this.form.getRawValue();

        this.service.archiveAnalyticsLine(entity._id, this.defaults).subscribe(res => {
            this.dialogRef.close(res);
        }, error => {
            this.setErrorsMessages(error.error.errors);
            this.loading = false;
        });
    }

    //update mode
    isUpdateMode(): boolean {
        return this.mode === CrudModeEnum.Update;
    }

// Import mode
    isImportMode() {
        return this.mode === CrudModeEnum.Import;
    }

    async importItems() {
        this.importDefaults = this.importForm.value;
        const entity = await this.service.entity;

        if (this.importForm.valid) {
            this.loading = true;
            let formData = new FormData();

            if (!this.importDefaults?.file?._files?.length) {
                this.importForm.controls["file"].setErrors({required: true});
                this.importForm.markAllAsTouched();
                return;
            }

            formData.append("file-excel", this.importDefaults.file._files[0]);
            this.importDefaults = formData;
            const sub = this.service.importAnalytic(entity._id, this.importDefaults).subscribe(
                result => {
                    this.dialogRef.close(result);
                }, error => {
                    this.setImportErrorsMessages(error.error.errors);
                    this.loading = false;
                });
        } else {
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    exportTemplate() {

        this.service.downloadExcelAnalyticLinesTemplate().subscribe((x: any) => {

            const b = b64ToBlob(x.data.b64, ContentTypeEnum.excel);
            this.fileSaver.save(b, x.data.fileName);
        });
    }

// global functions

    save() {
        if (this.isCreatedMode()) {
            this.createItem();
        } else if (this.isUpdateMode()) {
            this.updateItem();
        } else if (this.isImportMode()) {
            this.importItems();
        }
    }

    setErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.form.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.form.controls[error.property].setErrors(err);
        });
        this.form.markAllAsTouched();
    }

    setImportErrorsMessages(errors: Array<ErrorApi>) {
        errors?.forEach(error => {
            let err = this.importForm.controls[error.property].errors;
            if (err === null) {
                err = {messages: []};
            }
            err.messages.push(error.message);
            this.importForm.controls[error.property].setErrors(err);
        });
        this.importForm.markAllAsTouched();
    }
}
