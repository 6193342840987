<base-sidenav (onClose)="close()" (onClear)="clear()" (onValidate)="validate()" [standardForms]="standardForms" ></base-sidenav>

<ng-template #standardForms>
    <form [formGroup]="getFormGroup('standardForm')">

        <base-form-input
            label="Identifiant technique"
            withoutPadding="true"
            appearance="outline"
            controlName="technicalIdentifier"
        >
        </base-form-input>

        <base-form-input
            label="Référence Equipement"
            withoutPadding="true"
            appearance="outline"
            controlName="reference"
        >
        </base-form-input>

      <base-form-input
        [items]="[{label: 'Oui', value: true}, {label: 'Non', value: false}]"
        label="Équipement fonctionnel"
        withoutPadding="true"
        displayProperty="label"
        appearance="outline"
        type="select"
        controlName="isAvailable"
      ></base-form-input>

      <base-form-input
        [items$]="metrikStatus$"
        (autocompleteChange)="metrikStatusAutocompleteChange($event)"
        appearance="outline"
        withoutPadding="true"
        label='Statut de fonctionnement'
        controlName='metrikStatus'
        type='search-multiple'
        displayProperty="displayLabel"
      >
      </base-form-input>

        <base-form-input
            (autocompleteChange)="autocompleteEquipmentInfiniteScrollField($event, 'mark')"
            [items$]="getItems$('mark')"
            (onScroll)="onScrollLine($event, 'mark')"
            appearance="outline"
            controlName="mark"
            displayProperty="mark"
            label="Marque"
            type="search-multiple"
            withoutPadding="true"
        ></base-form-input>

        <base-form-input
            (autocompleteChange)="autocompleteEquipmentInfiniteScrollField($event, 'model')"
            [items$]="getItems$('model')"
            (onScroll)="onScrollLine($event, 'model')"
            appearance="outline"
            controlName="model"
            displayProperty="model"
            label="Modèle"
            type="search-multiple"
            withoutPadding="true"
        ></base-form-input>

        <base-form-input
            (autocompleteChange)="autocompleteEquipmentInfiniteScrollField($event, 'serialNumber')"
            [items$]="getItems$('serialNumber')"
            (onScroll)="onScrollLine($event, 'serialNumber')"
            label="Numéro de série"
            withoutPadding="true"
            appearance="outline"
            displayProperty="serialNumber"
            controlName="serialNumber"
            type="search-multiple"
        ></base-form-input>

        <base-form-input
            [items$]="getItems$('users')"
            (autocompleteChange)='autocompleteUsers($event)'
            (onScroll)="onScrollLine($event, 'users')"
            appearance="outline"
            label="Référent Equipement"
            displayProperty='fullName'
            controlName='contactType'
            type='search-multiple'
            withoutPadding="true"
        ></base-form-input>

        <base-form-input
            [items$]="getItems$('genericEquipments')"
            (autocompleteChange)='autocompleteGenericEquipments($event)'
            (onScroll)="onScrollLine($event, 'genericEquipments')"
            appearance="outline"
            label="Générique équipement"
            displayProperty='label'
            controlName='genericEquipments'
            type='search-multiple'
            withoutPadding="true"
        ></base-form-input>



        <base-form-input
            *ngIf="showEstablishments"
            [items$]="getItems$('establishments')"
            (autocompleteChange)='autocompleteEstablishment($event)'
            (onScroll)="onScrollLine($event, 'establishments')"
            appearance="outline"
            label='Etablissements'
            displayProperty='label'
            controlName='establishments'
            type='search-multiple'
            withoutPadding="true"
        ></base-form-input>
    </form>

    <form [formGroup]="getFormGroup('commissioningDate')">
        <base-form-input
            appearance="outline"
            controlName='comparison'
            type="select-between"
            label="Date de mise en service"
            typeSelection='date'
            selection="between"
            withoutPadding="true"
        > </base-form-input>
    </form>

</ng-template>
