import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DetailsBaseComponent} from '../../../../../core/base/components/details-base/details-base.component';
import {SupplierService} from '../supplier.service';
import {SupplierModel} from '../../../../../core/models/supplier.model';
import {AuthService} from "../../../../../core/services/auth.service";
import {SupplierContactService} from "../supplier-contact.service";
import {HttpClient} from "@angular/common/http";
import {ENUM_PERMISSIONS} from "../../../../../core/enums/permission.enum";

// @ts-ignore
// @ts-ignore
@Component({
    selector: 'vex-supplier-details',
    templateUrl: './supplier-details.component.html',
    styleUrls: ['./supplier-details.component.scss']
})
export class SupplierDetailsComponent extends DetailsBaseComponent<SupplierModel> implements OnInit {

    supplierId: string;
    defaultTabs = [
        {
            label: 'INFORMATIONS',
            route: './',
            routerLinkActiveOptions: {exact: true},
            index: 1,
            permission : ENUM_PERMISSIONS.READ_SUPPLIER
        },
        {
            label: 'CONTACTS FOURNISSEURS',
            route: './contact',
            index: 2,
            permission : ENUM_PERMISSIONS.READ_SUPPLIER
        },
        {
            label: 'N° CLIENT',
            route: './client-number',
            index: 3
        },
        {
            label: 'DOCUMENTS',
            route: './document',
            index: 4
        }
    ];

    constructor(public service: SupplierService,
                public activatedRoute: ActivatedRoute,
                public authService: AuthService,
                private route: ActivatedRoute,
                public http: HttpClient,
                public supplierContactService: SupplierContactService) {
        super(service, activatedRoute, authService);
    }

    ngOnInit() {
        super.ngOnInit();
        // get the supplier id;
        this.activatedRoute.params.subscribe(params => {
            this.supplierId = params['id'];
            super.ngOnInit();
        });
    }
}
