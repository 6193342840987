<div class="flex flex-row items-stretch justify-center gap-6">
    <div id="establishment-details-validators-card" class='w-1/3 card overflow-auto mt-6 p-6'>
        <mat-progress-bar *ngIf='loading' mode="indeterminate" color="primary"></mat-progress-bar>

        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle (click)="selectCategory(node)" class="cursor-pointer">
                <div [ngStyle]="{ 'padding-left': node.level === 0 ? '40px': 0 }" style='padding-left: 40px'></div>
                {{node.label}}
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <div class="mat-tree-node">
                    <button mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'Toggle ' + node.label" (click)='openLevel(node)'>
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <p class="cursor-pointer" (click)="selectCategory(node)">{{node.label}}</p>
                    <!--                <div *ngIf="node.children.length > 0"-->
                    <!--                     class="item-badge">-->
                    <!--                    {{ node.children.length }}-->
                    <!--                </div>-->
                    <mat-progress-bar *ngIf="node.isLoading"
                                      mode="indeterminate"
                                      class="ml-6"></mat-progress-bar>
                </div>
                <!-- There is inline padding applied to this div using styles.
                    This padding value depends on the mat-icon-button width.  -->
                <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                     role="group">
                    <ng-container *ngIf='!node.isLoading' matTreeNodeOutlet></ng-container>
                </div>
            </mat-nested-tree-node>
        </mat-tree>
    </div>
    <div id="establishment-details-selected-validators-card" class="w-full card overflow-auto mt-6 p-6">
        <ng-container *ngIf="selectedCategory; else noSelectedCateogryTemplate">
            <ng-container *ngTemplateOutlet="selectedCategoryTemplate"></ng-container>
        </ng-container>
    </div>
</div>

<ng-template #selectedCategoryTemplate>
    <p class="text-3xl font-semibold my-6">{{ selectedCategory?.label }}</p>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-4">
        <div class="flex flex-row justify-center items-center px-6 h-16 sticky left-0 pl-2 bg-transparent" fxLayout="row" fxLayoutAlign="center center">
            <nav mat-tab-nav-bar class='bg-transparent'>
                <a mat-tab-link
                   *ngFor="let tab of tabs"
                   (click)="switchTab(tab)"
                   [active]="activeTab == tab">
                    <span>{{tab.label}}</span>
                </a>
            </nav>
        </div>
    </div>
    <ng-container>
            <form [formGroup]="form" class="grid grid-cols-2">
                <div  class="flex flex-col justify-center items-start col-span-1">
                    <p class="mb-6 text-2xl font-semibold">Référents métier</p>
                    <ng-container formArrayName="buisnessReferents">
                        <ng-container *ngFor="let buisnessReferentForm of buisnessReferents.controls; let i = index">
                            <ng-container [formGroup]="buisnessReferentForm">
                                <div class="flex flex-row justify-start items-center">
                                    <base-form-input
                                            label="Référent métier"
                                            controlName="buisnessReferent"
                                            type="autocomplete"
                                            [items$]="referents$"
                                            displayProperty="fullName"
                                            (autocompleteChange)="autocompleteReferent($event)"
                                            (optionChange)="updateValidators()"
                                            [disabled]="canUpdateActorsPermission"
                                    >
                                    </base-form-input>
                                    <button *hasPermission="[ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT]" class="mb-6" mat-icon-button (click)="removeField('business-referent', i)">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *hasPermission="[ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT]">
                        <button mat-mini-fab *ngIf="buisnessReferents.controls.length < 2" (click)="addFields('business-referent')">
                            <mat-icon>add</mat-icon>
                        </button>
                        </ng-container>
                    </ng-container>
                    <p class="my-6 text-2xl font-semibold">Approvisionneurs</p>
                    <ng-container formArrayName="purchasers">
                        <ng-container *ngFor="let purchaserForm of purchasers.controls; let i = index">
                            <ng-container [formGroup]="purchaserForm">
                                <div class="flex flex-row justify-start items-center">
                                    <base-form-input
                                            label="Approvisionneur"
                                            controlName="purchaser"
                                            type="autocomplete"
                                            [items$]="purchasers$"
                                            displayProperty="fullName"
                                            (autocompleteChange)="autocompletePurchaser($event)"
                                            (optionChange)="updateValidators()"
                                            [disabled]="canUpdateActorsPermission"
                                    >
                                    </base-form-input>
                                    <button *hasPermission="[ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT]" class="mb-6" mat-icon-button (click)="removeField('purchaser', i)">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </ng-container>
                        <button *hasPermission="[ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT]" mat-mini-fab (click)="addFields('purchaser')">
                            <mat-icon>add</mat-icon>
                        </button>
                    </ng-container>
                    <p class="my-6 text-2xl font-semibold">Validateurs n+1</p>
                    <ng-container formArrayName="referentsN1">
                        <ng-container *ngFor="let referentN1Form of referentsN1.controls; let i = index">
                            <ng-container [formGroup]="referentN1Form">
                                <div class="flex flex-row justify-start items-center">
                                    <base-form-input
                                            label="Référent n+1"
                                            controlName="referentN1"
                                            type="autocomplete"
                                            [items$]="refN1$"
                                            displayProperty="fullName"
                                            (autocompleteChange)="autocompleteRefN1($event)"
                                            (optionChange)="updateValidators()"
                                            [disabled]="canUpdateActorsPermission"
                                    >
                                    </base-form-input>
                                    <button *hasPermission="[ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT]" class="mb-6" mat-icon-button (click)="removeField('referent-n1', i)">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </ng-container>
                        <button *hasPermission="[ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT]" mat-mini-fab (click)="addFields('referent-n1')">
                            <mat-icon>add</mat-icon>
                        </button>
                    </ng-container>

                    <p class="my-6 text-2xl font-semibold">Validateurs n+2</p>
                    <ng-container formArrayName="referentsN2">
                        <ng-container *ngFor="let referentN2Form of referentsN2.controls; let i = index">
                            <ng-container [formGroup]="referentN2Form">
                                <div class="flex flex-row justify-start items-center">
                                    <base-form-input
                                            label="Référent n+2"
                                            controlName="referentN2"
                                            type="autocomplete"
                                            [items$]="refN2$"
                                            displayProperty="fullName"
                                            (autocompleteChange)="autocompleteRefN2($event)"
                                            (optionChange)="updateValidators()"
                                            [disabled]="canUpdateActorsPermission"
                                    >
                                    </base-form-input>
                                    <button *hasPermission="[ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT]" class="mb-6" mat-icon-button (click)="removeField('referent-n2', i)">
                                        <mat-icon>remove</mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </ng-container>
                        <button *hasPermission="[ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT]" mat-mini-fab (click)="addFields('referent-n2')">
                            <mat-icon>add</mat-icon>
                        </button>
                    </ng-container>

                  <p class="my-6 text-2xl font-semibold">Approbateur de facture sans commande</p>
                  <base-form-input
                    label="Approbateur de facture sans commande"
                    controlName="approver"
                    type="autocomplete"
                    [items$]="approver$"
                    displayProperty="fullName"
                    (autocompleteChange)="autocompleteApprover($event)"
                    (optionChange)="updateValidators()"
                    [disabled]="canUpdateActorsPermission"
                  >
                  </base-form-input>

                </div>
                <div class="col-span-1">
                    <div class="flex flex-col gap-6">
                        <p class="mb-6 text-2xl font-semibold">Paramètres</p>
                        <ng-container *hasPermission="[ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT]">
                        <mat-slide-toggle *ngIf="selectedCategory.level !== 2" formControlName="applyToChildren">Appliquer les changements aux sous-familles</mat-slide-toggle>
                        </ng-container>
                        <mat-slide-toggle formControlName="autorizationOrder" *hasPermission="[ENUM_PERMISSIONS.UPDATE_ESTABLISHMENT]">Autorisation sans commande</mat-slide-toggle>
                    </div>
                </div>
            </form>
    </ng-container>
</ng-template>

<ng-template #noSelectedCateogryTemplate>
    <div class="w-full h-full flex flex-col justify-center items-center">
        <span class="text-2xl mb-6">Veuillez séléctionner une famille</span>
        <img class="w-full max-w-lg mx-auto mb-6 h-64" src="assets/img/illustrations/checklist.svg" alt="waiting">
    </div>
</ng-template>
