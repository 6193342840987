import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import icAdd from '@iconify/icons-ic/twotone-add';
import { FeiColumn } from '../../../../../../core/base/interfaces/fei-column.interface';
import { MatTableDataSource } from '@angular/material/table';
import { BuildingService } from '../../building.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CrudModeEnum } from '../../../../../../core/base/enum/crud-mode.enum';
import {
  BuildingDetailsRoomCreateUpdateDeleteComponent
} from './building-details-room-create-update-delete/building-details-room-create-update-delete.component';
import { BuildingDetailsRoomFloorComponent } from './building-details-room-floor/building-details-room-floor.component';
import { BuildingModel } from '../../../../../../core/models/building.model';
import icClose from '@iconify/icons-ic/twotone-close';
import icSearch from '@iconify/icons-ic/twotone-search';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ENUM_PERMISSIONS } from '../../../../../../core/enums/permission.enum';
import { scaleIn400ms } from '../../../../../../../@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '../../../../../../../@vex/animations/fade-in-right.animation';
import { scaleFadeIn400ms } from '../../../../../../../@vex/animations/scale-fade-in.animation';
import { dropdownAnimation } from '../../../../../../../@vex/animations/dropdown.animation';
import { popoverAnimation } from '../../../../../../../@vex/animations/popover.animation';
import { AuthService } from '../../../../../../core/services/auth.service';
import { DEFAULT_PAGE_SIZE } from '../../../../../../core/config/base-table.config';

@Component({
  selector: 'vex-building-details-room',
  templateUrl: './building-details-room.component.html',
  styleUrls: ['./building-details-room.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    scaleFadeIn400ms,
    scaleFadeIn400ms,
    dropdownAnimation,
    popoverAnimation
  ],
})
export class BuildingDetailsRoomComponent implements OnInit, AfterViewInit, OnDestroy {

  ENUM_PERMISSION = ENUM_PERMISSIONS;
  dataSourceRoom: MatTableDataSource<any>;
  displayedColumnsRoom = [];
  feisColumnsRoom: Array<FeiColumn> = [
    { label: 'Catégorie de salle générique', column: 'genericRoomCategory', propertyDisplay: 'genericRoom.genericRoomCategory.label', isPredik: true },
    { label: 'Salle générique', column: 'genericRoom', propertyDisplay: 'genericRoom.label', isPredik: true},
    { label: 'Etablissement', column: 'establishment', propertyDisplay: 'establishment.label'},
    { label: 'Libellé Client', column: 'label'},
    { label: 'Etage', column: 'stage', propertyDisplay: 'stage.label'},
    { label: 'Surface (m2)', column: 'surface', type: 'number'},
    { label: 'Nombre de lits', column: 'nbOfBed'},
    {
      label: '',
      column: 'actions',
      canUpdate : this.authservice.hasPermission(ENUM_PERMISSIONS.UPDATE_BUILDING),
      canDelete : this.authservice.hasPermission(ENUM_PERMISSIONS.UPDATE_BUILDING) }
  ];
  icAdd = icAdd;
  icClose = icClose;
  icSearch = icSearch;
  displayRoomWarning = false;
  subscription = new Subscription();
  @ViewChild('searching') searching: ElementRef;
  count: number;
  pageSize = DEFAULT_PAGE_SIZE;
  pageIndex = 1;
  sortField: string;
  sortOrder: string;
  search = '';

  errors: Array<string> = [];

  constructor(
      public buildingService: BuildingService,
      private activatedRoute: ActivatedRoute,
      private dialog: MatDialog,
      private authservice: AuthService
  ) {
    this.displayedColumnsRoom = this.feisColumnsRoom.map(x => x.column);
  }

  ngAfterViewInit() {
    this.subscription.add(
        fromEvent(this.searching.nativeElement, 'keyup')
            .pipe(
                map(value => {
                  return value;
                }),
                debounceTime(200),
                distinctUntilChanged(),
                tap((value) => {
                  console.log(value);
                  this.onSearchChange(this.searching.nativeElement.value);
                })
            )
            .subscribe()
    );
  }

  ngOnInit(): void {
    // const sub = this.buildingService.entity$.subscribe(entity => {
    //
    //   console.log(entity);
    //
    //
    //     if (entity) this.initData(entity);
    //
    // })
    // this.subscription.add(sub);
    this.initData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initData() {
    // this.buildingService.searchRooms('');
    this.subscription.add(
        this.buildingService.entity$.subscribe(entity => {
          if (entity) { this.buildingService.findAllRoom(entity._id, this.pageIndex, this.pageSize, this.sortField, this.sortOrder, this.search).subscribe(rooms => {
            this.errors = [];
            if (rooms && (rooms.customData !== entity?.surface)) {
              this.errors.push(`La surface totale des salles est de ${rooms.customData} m2 alors que la surface du bâtiment est de ${entity?.surface} m2`);
            }
            const nbMaxOfBed = entity?.establishments?.reduce((acc, establishment) => acc + establishment.nbOfPermanentBed, 0);
            const nbOfBed = rooms.data.reduce((acc, room) => acc + (room.nbOfBed ?? 0), 0);
            if (nbOfBed !== nbMaxOfBed) {
                this.errors.push(`Le nombre total de lits est de ${nbOfBed} alors que le nombre de lits maximum est de ${nbMaxOfBed}`);
            }
            this.dataSourceRoom = new MatTableDataSource<any>(rooms.data);
            this.count = rooms.totalData;
          });
          }
        })
    );
  }

  async createRoom() {
    const entity = await this.buildingService.entity;
    this.dialog.open(BuildingDetailsRoomCreateUpdateDeleteComponent, {
      data: {
        building: entity,
        mode: CrudModeEnum.Create
      }
    }).afterClosed().subscribe((room) => {
      if (room) {
        this.initData();
      }
    });
  }

  clearTextFilter() {
    this.searching.nativeElement.value = '';
    this.search = '';
    this.initData();
  }

  onSearchChange(event) {
    this.pageIndex = 1;
    this.search = event;
    this.initData();
  }

  async updateItemRoom(element: any) {
    const entity = await this.buildingService.entity;
    this.dialog.open(BuildingDetailsRoomCreateUpdateDeleteComponent, {
      data: {
        building: entity,
        defaults: element,
        mode: CrudModeEnum.Update
      }
    }).afterClosed().subscribe((room) => {
      if (room) {
        console.log('room - update', room);
        this.initData();
      }
    });
  }

  async deleteItemRoom(element: any) {
    const entity = await this.buildingService.entity;
    this.dialog.open(BuildingDetailsRoomCreateUpdateDeleteComponent, {
      data: {
        building: entity,
        defaults: element,
        name: `${element.label}`,
        mode: CrudModeEnum.Delete
      }
    }).afterClosed().subscribe((room) => {
      if (room) {
        console.log('room - delete', room);
        this.initData();
      }
    });
  }

  async openItemFloor(element: any) {
    const entity = await this.buildingService.entity;
    this.dialog.open(BuildingDetailsRoomFloorComponent, {
      data: {
        building: entity,
        defaults: element,
        name: `${element.label}`,
        mode: CrudModeEnum.Delete
      }
    }).afterClosed().subscribe((room) => {
      if (room) {
        this.initData();
      }
    });
  }

  async importItems() {
    const entity = await this.buildingService.entity;
    this.dialog.open(BuildingDetailsRoomCreateUpdateDeleteComponent, {
      data: {
        building: await this.buildingService.entity,
        mode: CrudModeEnum.Import
      }
    }).afterClosed().subscribe((building: BuildingModel) => {
      if (building) {
        this.buildingService.findById(entity._id).subscribe((res) => {
          this.initData();
        });
      }
    });
  }

  pageEvent(event: PageEvent): void {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.initData();
  }

  sortEvent(event: Sort): void {
    this.sortField = event.active;
    this.sortOrder = event.direction;
    this.initData();
  }
}
