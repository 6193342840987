import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { MetrikStatusModuleEnum } from '../../../enums/metrik-status.enum';
import { MetrikStatusModel } from '../../../models/metrik-status.model';
import { MetrikStatusService } from '../../../../pages/application/settings/metrik-status/metrik-status.service';
import { OperationalStatusModel } from '../../../models/equipment.model';
import { GenericAdditionalCostModel } from '../../../models/generic-additional-cost.model';

@Component({
  selector: 'metrik-status',
  templateUrl: './metrik-status.component.html',
  styleUrls: ['./metrik-status.component.scss']
})
export class MetrikStatusComponent implements OnInit, OnDestroy {

  public metrikStatus$: Observable<MetrikStatusModel[]>;
  public loadingSave = false;
  public minEndDate = new Date();
  protected subscription = new Subscription();
  @Input() form: FormGroup;
  @Input() module: MetrikStatusModuleEnum;
  @Input() operationalStatus: OperationalStatusModel;
  @Input() isDisabledDate: boolean = false;
  @Input() isOnlyProcessExited: boolean = false;

  constructor(
      private metrikStatusService: MetrikStatusService,
  ) {
  }

  ngOnInit() {
    this.initData();
    this.initForm();
    this.setMinEndDate();
  }

  initData() {
    this.metrikStatusAutocompleteChange('');
  }

  initForm() {
    if (this.operationalStatus?.metrikStatus) {
      this.operationalStatus.metrikStatus.displayLabel = this.operationalStatus?.metrikStatus?.reason
          ? `${this.operationalStatus?.metrikStatus?.type} - ${this.operationalStatus.metrikStatus.reason}`
          : this.operationalStatus.metrikStatus.type;
    }

    this.form.addControl('metrikStatus', new FormControl(
        this.isOnlyProcessExited && !this.operationalStatus?.metrikStatus?.isProcessExited
        ? null
        : this.operationalStatus?.metrikStatus,
        [Validators.required]
    ));
    this.form.addControl('metrikEndDate', new FormControl(this.operationalStatus?.endDate, []));
    this.form.addControl('metrikStartDate', new FormControl(this.operationalStatus?.startDate, []));

    this.subscription.add(
        this.form.controls.metrikStatus.valueChanges.subscribe((metrik: MetrikStatusModel | null) => {
          if (!metrik?.isDateNeeded || this.isDisabledDate) {
            this.form.controls.metrikEndDate.setValue(null);
            this.form.controls.metrikStartDate.setValue(null);
          }
        })
    );

    this.subscription.add(
        this.form.controls.metrikStartDate.valueChanges.subscribe((value) => {
          this.setMinEndDate();
        })
    );

  }

  metrikStatusAutocompleteChange(search: any = '') {
    const filters = {
      modules: [this.module],
      requiredFields: ['*']
    }

    if (this.isOnlyProcessExited) {
      filters['isProcessExited'] = true;
    }

    this.metrikStatus$ = this.metrikStatusService
        .findAll(null, null, 'type', 'asc', search, filters)
        .pipe(map(x => {
          return x.data.map((metrik) => {
            return {
              ...metrik,
              displayLabel: metrik.reason ?`${metrik.type} - ${metrik.reason}` : metrik.type
            };


          })
        }))
  }

  setMinEndDate() {
    const today = new Date();
    const startDateValue = this.form.controls.metrikStartDate?.value;
    const startDate = startDateValue ? new Date(startDateValue) : null;

    this.minEndDate = startDate && startDate > today ? new Date(startDate) : today;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
