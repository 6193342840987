<mat-form-field [appearance]="appearance" [ngClass]="{'without-padding': withoutPadding}" class="flex-auto w-full" [matMenuTriggerFor]="menu" #trigger="matMenuTrigger">
    <mat-label>{{ label }}</mat-label>
    <input class="flex-auto w-full" type="text" matInput readonly
           [value]="getSelectedItemsText()">

    <mat-menu #menu="matMenu" class="custom-width-menu">
        <div class="flex-auto w-full select-all-search-container p-4" [style.width.px]="menuWidth">
            <mat-checkbox class="pl-2 pr-3"
                          (change)="toggleSelectAll($event.checked)"
                          (click)="$event.stopPropagation();">
            </mat-checkbox>

            <div class="search-container">
                <mat-icon>search</mat-icon>
                <input class="flex-auto w-full bg-transparent search-input" placeholder="Rechercher..."
                       [formControl]="searchControl" (click)="$event.stopPropagation()">
                <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="EFFACER"
                        (click)="$event.stopPropagation(); clearSearch()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>

        <div class="scrollable-options">
            <div *ngIf="isLoading" class="loading-spinner">
                <mat-spinner diameter="30"></mat-spinner>
            </div>

            <cdk-virtual-scroll-viewport *ngIf="!isLoading && filteredItems.length > 0"
                                         itemSize="20"
                                         (scrolledIndexChange)="onScrollLine($event)"
                                         class="scrollable-options">
                <mat-checkbox class="option-item" *cdkVirtualFor="let item of filteredItems"
                              [checked]="item.selected"
                              (change)="updateSelectedItems(item, $event.checked)"
                              (click)="$event.stopPropagation();">
                    <div class="py-1 pl-2">{{ displayFn(item) }}</div>
                </mat-checkbox>
            </cdk-virtual-scroll-viewport>

            <p *ngIf="!isLoading && !hasData">Aucun élément à afficher.</p>
        </div>


    </mat-menu>

    <base-form-input-errors
            [control]="formGroup.controls[controlName]"
            [controlName]="controlName"
            [errorMessages]="defaultErrorMessage"
            [invalidPatternMessage]="invalidPatternMessage"
            [customErrorMessage]="customErrorMessage">

    </base-form-input-errors>
</mat-form-field>
