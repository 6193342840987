import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SnackbarService} from '../services/snackbar.service';

@Injectable()
export class ErrorCrudInterceptor implements HttpInterceptor {

    constructor(
        private snackbarService: SnackbarService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => this.handleHttpError(request, error))
        );
    }

    private handleHttpError(request: HttpRequest<any>, error: HttpErrorResponse): Observable<never> {

        const errorMessageHeader = request.headers.get('Error-CRUD');

        console.error(
            `URL: ${error.url}\n` +
            `Error status: ${error.status}\n` +
            `Error message: ${error.error?.message}`
        );

        if (error.error.isErrorToDisplayForUser) {
            switch (errorMessageHeader) {
                case 'CREATE':
                    this.snackbarService.danger(error.error.message);
                    break;
                case 'DELETE':
                    this.snackbarService.danger(error.error.message);
                    break;
                case 'UPDATE':
                    this.snackbarService.danger(error.error.message);
                    break;
                // case 'IMPORT':
                //     this.snackbarService.danger(error.error.message);
                //     break;
                // case 'DOWNLOAD':
                //     this.snackbarService.danger(error.error.message);
                //     break;
                default:
                    this.snackbarService.danger(error.error.message);
                    break;
            }

        }
        return throwError(error);
    }
}
