<vex-ged-layout [loading]="loading">
    <base-data-table
            [dataSource]='dataSource'
            [displayedColumns]='displayedColumns'
            [feisColumns]='feisColumns'
            [showPaginator]="false"
            (onDeleteItem)="openDelete($event)"
            (onDownloadItem)="downloadDocument($event.DWDOCID)"
            [showSort]="false"
    ></base-data-table>
<!--    <ng-template #customAction let-row="value">-->
<!--        <button *hasPermission="[ENUM_PERMISSION.DOWNLOAD_FM_REF_PURCHASE_SUPPLIER_DOCUMENT]" class="mat-menu-item"-->
<!--                (click)="downloadDocument(row.DWDOCID)">-->
<!--            <mat-icon>file_download</mat-icon>-->
<!--            Télécharger-->
<!--        </button>-->
<!--    </ng-template>-->
    <div dragFormZone *hasPermission="[ENUM_PERMISSION.UPDATE_SUPPLIER]">
        <div fxLayout="column" *ngIf="!loading">
            <div class="w-full" [formGroup]="form">
                <mat-form-field class="w-full">
                    <mat-label>Type de document</mat-label>
                    <mat-select formControlName="documentType">
                        <mat-option *ngFor='let type of documentTypes' [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <vex-drop-zone [files]="files"></vex-drop-zone>
            <button mat-raised-button color="primary" class="mt-4" fxFlexAlign="end" (click)="validForm()"
                    [loading]="loadingSave"
                    [disabled]="form.invalid || files.length === 0">ENREGISTRER
            </button>
        </div>
    </div>

</vex-ged-layout>

