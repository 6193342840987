import { Injectable } from '@angular/core';
import { FloorTypeModel } from '../../../../core/models/floor-type.model';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../core/base/base.service';
import {DetentionTypeModel} from "../../../../core/models/detention-type.model";

@Injectable({
    providedIn: 'root'
})
export class DetentionTypeService extends BaseService<DetentionTypeModel>{
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'detention-type');
    }
}


