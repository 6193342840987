import {Injectable} from '@angular/core';
import {QuoteModel} from 'src/app/core/models/quote-model';
import {HttpClient} from '@angular/common/http';
import {BaseService} from 'src/app/core/base/base.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ObjectApiInterface} from '../../../../core/base/interfaces/object-api.interface';
import {IQuoteDismissModalData} from "./quotes-modals/quotes-dismiss-quote-modal/quotes-dismiss-quote-modal.component";

@Injectable({
    providedIn: 'root'
})
export class QuotesService extends BaseService<QuoteModel> {
    defaultSort = 'technicalIdentifier';

    constructor(public http: HttpClient) {
        super(http, 'quote');
    }

    duplicate(_id: string): Observable<any> {
        return this.http.get<ObjectApiInterface<QuoteModel>>(`${this.baseUrlApi}/duplicate/${_id}`).pipe(map(x => {
            // this._entity.next(x.data);
            return x;
        }));
    }

    createFromRequirementBasketValidation(data: any): Observable<any> {
        return this.http.post<ObjectApiInterface<any>>(`${this.baseUrlApi}/createFromRequirementBasketValidation`, data).pipe(map(x => {
            // this._entity.next(x.data);
            return x;
        }));
    }



    sendMail(_id: string): Observable<any> {
        return this.http.get<ObjectApiInterface<QuoteModel>>(`${this.baseUrlApi}/sendMail/${_id}`).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    // setSelected(_id: string, selected: boolean): Observable<any> {
    //     return this.http.put<ObjectApiInterface<QuoteModel>>(`${this.baseUrlApi}/setSelected/${_id}`, { selected }).pipe(map(x => {
    //         this._entity.next(x.data);
    //         return x;
    //     }));
    // }

    setSelected(data: any, currentQuoteId?: string | string[]): Observable<any> {
        return this.http.put<ObjectApiInterface<QuoteModel>>(`${this.baseUrlApi}/setSelected/${currentQuoteId}`, data).pipe(map(quote => {
            this._entity.next(quote.data);
            return quote;
        }));
    }

    updateDescription(_id: string, description: string): Observable<any> {
        return this.http.put<ObjectApiInterface<QuoteModel>>(`${this.baseUrlApi}/updateDescription/${_id}`, description).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }

    checkDocumentIsNotEmpty(id: string) {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/ged/checkDocumentIsNotEmpty/${id}/QUOTE`).pipe(map(x => x.data));
    }

    checkDocumentIsEmpty(id: string) {
        return this.http.get<ObjectApiInterface<any>>(`${this.baseUrlApi}/ged/checkDocumentIsNotEmpty/${id}/QUOTE`).pipe(map(x => !x.data));
    }

    setSelectedManyQuote(data: any, currentQuoteIds?: string[]): Observable<any> {
        return this.http.put<ObjectApiInterface<QuoteModel[]>>(`${this.baseUrlApi}/setSelectedManyQuote/${currentQuoteIds}`, data).pipe(map(quote => {
            console.log(quote, 'quote qui rest retenouré dabord en premier');
            quote.data.map((quote) => {
                this._entity.next(quote);
            });
            return quote;
        }));
    }

    sendValidationQuote(_id: string, validatorQuote: string): Observable<any> {
        return this.http.put<ObjectApiInterface<QuoteModel>>(`${this.baseUrlApi}/sendValidationQuote/${_id}`, { validatorQuote }).pipe(map(x => {
            this._entity.next(x.data);
            return x;
        }));
    }


    dismissQuote(_id: string, data: IQuoteDismissModalData): Observable<QuoteModel> {
        return this.http.put<ObjectApiInterface<QuoteModel>>(`${this.baseUrlApi}/dismissQuote/${_id}`, {data}).pipe(map(x => {
            this._entity.next(x.data);
            return x.data;
        }));
    }
}


