<ng-container class="h10">

    <base-form-input
        label='Motif'
        controlName='motif'
        type='autocomplete'
        [items$]="genericMotifs$"
        (autocompleteChange)="genericReasonAutocompleteChange($event)"
        displayProperty='label'
    >
    </base-form-input>

    <div *ngIf="showComment">

        <h3>{{ commentTitle }}</h3>
        <p class="mb-2">{{ subCommentTitle }}</p>

        <div class="card">
            <quill-editor
                class='h-200'
                [modules]="customModule"
                placeholder="Insérez le commentaire ici"
                [formControl]='form?.controls?.comment'
                (onEditorCreated)="onEditorCreated($event)"
                [readOnly]="false"
            >
            </quill-editor>
            <mat-error style="font-size: 75%"
                       *ngIf="form?.controls?.comment.invalid && form?.controls?.comment?.touched">
                Le champ commentaire est obligatoire
            </mat-error>
        </div>
    </div>

</ng-container>