<div class='mt-6 flex flex-col md:flex-row md:items-start'>
   <div class='flex-auto'>
      <div id="establishment-details-analytics-card" class="card mt-6">
         <!--            <div class="px-gutter py-4 border-b"  fxLayout='row' fxLayoutAlign='space-between center'>-->
         <!--                <h2 class="title m-0">Lignes de compte comptable</h2>-->
         <!--                <button color='primary' mat-raised-button type='button' (click)='importItems()'>IMPORT</button>-->
         <!--            </div>-->

         <base-data-table-header
                 [label]='"Sections analytiques"'
                 [loading]='loadingData'
                 [showSearch]=false
                 [showArchives]="canShowArchivePermissions"
                 (onShowArchived)="onShowArchived($event)"
                 [canImport]="true"
                 (onCreate)='createAnalyticLine()'
                 (onImport)='importItems()'
                  [backgroundColorWhite]="true"
                 [createPermission]="[ENUM_PERMISSION.UPDATE_ESTABLISHMENT]"
                 [importPermission]="[ENUM_PERMISSION.UPDATE_ESTABLISHMENT]"
         ></base-data-table-header>
         <!-- TODO PERMISSION [updatePermission]="[ENUM_PERMISSION.UPDATE_CP_ESTABLISHMENT_ANALYTICS]"
                 [deletePermission]="[ENUM_PERMISSION.ARCHIVE_CP_ESTABLISHMENT_ANALYTICS]"-->
         <base-data-table
                 @stagger
                 [count]='count'
                 [pageSize]='pageSize'
                 [sortField]='sortField'
                 [sortOrder]='sortOrder'
                 [dataSource]='dataSource'
                 [displayedColumns]='displayedColumns'
                 [feisColumns]='feisColumns'
                 (onPageEvent)='pageEvent($event)'
                 (onSortEvent)='sortEvent($event)'
                 (onArchiveItem)="archiveItem($event)"
                 (onUpdateItem)="updateItem($event)"
         ></base-data-table>
      </div>

   </div>
</div>
<ng-template #loading>
   <div class='px-gutter py-4' gdColumns='1fr 1fr' gdColumns.xs='1fr' gdGap='16px'>
      <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
         <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
         <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
      </div>

      <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
         <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
         <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
      </div>

      <div class='py-3' fxLayout='row' fxLayoutAlign='start center'>
         <vex-skeleton class='w-14 mr-2' heigthTailwind='h-12' [circle]='true'></vex-skeleton>
         <vex-skeleton class='w-full' heigthTailwind='h-12'></vex-skeleton>
      </div>
   </div>
</ng-template>
