<div
    class="bg-card  ltr:pr-4 rtl:pr-4 rtl:ml-4 ltr:border-r rtl:border-l search-bar-component"
    [ngStyle]="{ width: searchWidthFull ? '100%' : '400px' }"
    style="border-radius: 6px"
    fxFlex.lt-md="auto"
    fxLayout="row"
    fxLayoutAlign="start center"
>
    <mat-form-field
        class="border-0 md:w-2/5 w-4/5"
        appearance="outline"
        floatLabel="never"
    >
        <ng-container *ngIf="showCatalogPermission">
        <input
            class="hidden md:flex"
            type="text"
            placeholder="Toutes les catégories"
            aria-label="Groupe"
            matInput
            [formControl]="genericControl"
            [matAutocomplete]="groupAuto"
        />
        </ng-container>
        <input
            class="md:hidden"
            type="text"
            placeholder="Catégories"
            aria-label="Groupe"
            matInput
            [formControl]="genericControl"
            [matAutocomplete]="groupAuto"
        />
        <mat-icon *ngIf="!genericControl.value" matSuffix>arrow_drop_down</mat-icon>
        <button *ngIf="genericControl.value" matSuffix mat-icon-button aria-label="Effacer" (click)="clearSelection()">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #groupAuto="matAutocomplete" panelWidth="auto" [displayWith]="displayGeneric" (optionSelected)="emitSearchData()">
            <ng-container *ngFor="let group of generic$ | async">
                <mat-optgroup [label]="group.type">
                    <mat-option *ngFor="let item of group.items" [value]="item">
                        {{ item.label }}
                    </mat-option>
                </mat-optgroup>
            </ng-container>
        </mat-autocomplete>
    </mat-form-field>

    <!--    <ic-icon [icIcon]="icSearch" class="ml-4" size="20px"></ic-icon>-->
    <input *ngIf="canSearchPermission"
        matInput
        class="px-4 py-3 border-0 outline-none w-full bg-transparent"
        placeholder="Recherche..."
        [formControl]="search"

    />
    <button
        *ngIf="search.value"
        matSuffix
        mat-icon-button
        aria-label="EFFACER"
        (click)="clearTextFilter()"
    >
        <ic-icon [icIcon]="icClose" size="20px"></ic-icon>
    </button>

    <base-data-table-dropdown *ngIf='popupFilter' [content]="templateref" [count]="countPopupFilter" #popup
                              (eventClear)="clearFilter()">
        <ng-template #templateref>
            <base-data-table-dropdown-template #template *ngIf='popupFilter' [width]=popupWidth>
                <div class='title mb-4' header>
                    {{ popupTitle }}
                </div>
                <div content>
                    <ng-container [ngTemplateOutlet]='contentPopupTemplate'></ng-container>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <button mat-raised-button color="primary" (click)="clearFilter()">EFFACER FILTRES</button>
                        <button mat-raised-button color="primary" (click)="validateFilter()">OK</button>
                    </div>
                </div>
            </base-data-table-dropdown-template>
        </ng-template>
    </base-data-table-dropdown>


    <ng-template #defaultPopupTemplate>
        <form [formGroup]="popupForm" >
            <base-form-input
                    label='Afficher les 20 derniers articles commandés'
                    type='checkbox'
                    controlName='isLastOrder'>
                >
            </base-form-input>
            <base-form-input
                    label='Stockable'
                    type='checkbox'
                    controlName='isInStock'>
                >
            </base-form-input>

        </form>
    </ng-template>


</div>

