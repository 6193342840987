import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { IconModule } from '@visurel/iconify-angular';
import { TopbarComponent } from './topbar/topbar.component';
import { MatMenuModule } from '@angular/material/menu';
import { ToolbarUserModule } from '../../../@vex/layout/toolbar/toolbar-user/toolbar-user.module';
import { ToolbarNotificationsModule } from '../../../@vex/layout/toolbar/toolbar-notifications/toolbar-notifications.module';
import { NavigationItemModule } from '../../../@vex/components/navigation-item/navigation-item.module';
import { QuicklinkModule } from 'ngx-quicklink';
import { NavigationModule } from '../../../@vex/layout/navigation/navigation.module';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { StatusBarItemComponent } from './status-bar-item/status-bar-item.component';
import { IconWarningComponent } from './icon-warning/icon-warning.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TabBadgeComponent } from './tab-badge/tab-badge.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import { MatInputModule } from '@angular/material/input';
import {BaseModule} from "../base/base.module";
import {MatChipsModule} from "@angular/material/chips";
import {MatBadgeModule} from "@angular/material/badge";
import {BadgeComponent} from "./vex-badge/vex-badge.component";
import {CatalogComponent} from "./catalog/catalog.component";
import {CatalogBasketComponent} from "./catalog/component/catalog-basket/catalog-basket.component";
import {CatalogActionsComponent} from "./catalog/component/catalog-actions/catalog-actions.component";
import {BasketWithDetailsComponent} from "./basket-with-details/basket-with-details.component";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import { ErrorNotificationComponent } from './error-notification/error-notification.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {StatusToTextPipe} from "../enums/basketRowStatusEnum";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTabsModule} from "@angular/material/tabs";
import {MatPaginatorModule} from "@angular/material/paginator";
import {
    EquipmentCreateUpdateDeleteComponent
} from "../../pages/application/management/equipment/equipment-create-update-delete/equipment-create-update-delete.component";
import {TreeCheckboxComponent} from "./tree-checkbox/tree-checkbox.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTreeModule} from "@angular/material/tree";
import {MatListModule} from "@angular/material/list";

@NgModule({
    declarations: [
        TopbarComponent,
        SkeletonComponent,
        StatusBarItemComponent,
        IconWarningComponent,
        TabBadgeComponent,
        SearchBarComponent,
        TreeCheckboxComponent,
        ErrorNotificationComponent,
        BadgeComponent,
        CatalogComponent,
        CatalogBasketComponent,
        CatalogActionsComponent,
        BasketWithDetailsComponent,
        StatusToTextPipe,
        EquipmentCreateUpdateDeleteComponent,
    ],
    exports: [
        TopbarComponent,
        SkeletonComponent,
        StatusBarItemComponent,
        IconWarningComponent,
        TabBadgeComponent,
        SearchBarComponent,
        TreeCheckboxComponent,
        BadgeComponent,
        CatalogComponent,
        CatalogBasketComponent,
        CatalogActionsComponent,
        BasketWithDetailsComponent,
        ErrorNotificationComponent,
        EquipmentCreateUpdateDeleteComponent,
        StatusToTextPipe
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatIconModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        FlexModule,
        IconModule,
        MatMenuModule,
        NavigationModule,
        ExtendedModule,
        ToolbarUserModule,
        ToolbarNotificationsModule,
        NavigationItemModule,
        QuicklinkModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        BaseModule,
        MatChipsModule,
        MatBadgeModule,
        FormsModule,
        MatTableModule,
        MatSortModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatTabsModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatTreeModule,
        MatListModule,
    ]
})
export class UiModule {
}
