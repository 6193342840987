export class ScrollManager {
    private currentPage = 0;
    private maxPages = 0;
    private loadedPages: Set<number> = new Set();

    setMaxPages(maxPages: number) {
        this.maxPages = maxPages;
    }

    getNextPage(): number | null {
        if (this.currentPage < this.maxPages) {
            this.currentPage++;
            return this.currentPage;
        }
        return null;
    }

    isPageLoaded(page: number): boolean {
        return this.loadedPages.has(page);
    }

    markPageLoaded(page: number) {
        this.loadedPages.add(page);
    }
}
