import { Injectable } from '@angular/core';
import { FloorTypeModel } from '../../../../core/models/floor-type.model';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../../core/base/base.service';
import {DetentionTypeModel} from "../../../../core/models/detention-type.model";
import {MetrikStatusModel} from "../../../../core/models/metrik-status.model";

@Injectable({
    providedIn: 'root'
})
export class MetrikStatusService extends BaseService<MetrikStatusModel>{
    defaultSort = 'createdAt';

    constructor(public http: HttpClient) {
        super(http, 'metrik-status');
    }
}


