import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DEFAULT_SCROLL_SIZE } from '../config/base-table.config';
import { ScrollManager } from './scroll-manager.service';
import { ObjectApiInterface } from '../base/interfaces/object-api.interface';

interface AutocompleteState<T> {
    subject: BehaviorSubject<T[]>;
    paginationManager: ScrollManager;
    currentPage: number;
}

@Injectable({
    providedIn: 'root',
})
export class AutocompleteService {
    private states: { [key: string]: AutocompleteState<any> } = {};

    constructor() {}

    private initializeStateIfNeeded<T>(filterName: string): void {
        if (filterName !== '' && !this.states[filterName]) {
            this.states[filterName] = {
                subject: new BehaviorSubject<T[]>([]),
                paginationManager: new ScrollManager(),
                currentPage: 1,
            };
        }
    }

    clearStates(): void {
        Object.values(this.states).forEach((state) => {
            if (state.subject instanceof BehaviorSubject) {
                // const temp = state.subject.value;
                // state.subject.next([]);
                // state.subject.next(temp);
            }
        });
    }

    getItems$<T>(filterName: string): Observable<T[]> {
        this.initializeStateIfNeeded<T>(filterName);
        return this.states[filterName].subject.asObservable();
    }

    autocompleteInfiniteScroll<T>(
        search: string,
        filterName: string,
        property: string = '_id',
        pageToGo: 'next' | 'previous',
        serviceFn: (page: number, size: number, filterName: string, search: string) => Observable<ObjectApiInterface<T[]>>
    ): Observable<T[]> {
        this.initializeStateIfNeeded<T>(filterName);

        const state = this.states[filterName];

        if (!state) {
            return of([]);
        }

        if (!!pageToGo && pageToGo === 'next') {
            state.currentPage = state.paginationManager.getNextPage();
        }

        if (state.paginationManager.isPageLoaded(state.currentPage) && search === '') {
            console.log('page already loaded');
            return of([]);
        }

        return serviceFn(state.currentPage, DEFAULT_SCROLL_SIZE, filterName, search).pipe(
            map((response: any) => {
                state.paginationManager.setMaxPages(response.totalPage);
                return response.data;
            }),
            map((newData) => {
                const existingData = state.subject.value || [];
                const existingIndex = new Map(existingData.map((item) => [item[property], item]));

                for (const item of newData) {
                    const key = item[property];
                    const existingItem = existingIndex.get(key);

                    if (!existingItem) {
                        existingIndex.set(key, item);
                    } else if (!existingItem.selected) {
                        existingIndex.set(key, item);
                    }
                }

                const uniqueData = Array.from(existingIndex.values());
                state.subject.next(uniqueData);
                state.paginationManager.markPageLoaded(state.currentPage);
                return uniqueData;
            })
        );
    }
}


