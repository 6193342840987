<base-sidenav (onClose)="close()" (onClear)="clear()" (onValidate)="validate()" [standardForms]="standardForms" ></base-sidenav>


<ng-template #standardForms>
    <form [formGroup]="getFormGroup('standardForm')">

        <base-form-input
            label="ID Prestation"
            withoutPadding="true"
            appearance="outline"
            controlName="technicalIdentifier"
        >
        </base-form-input>

        <base-form-input
            label="Réf. Fournisseur"
            withoutPadding="true"
            appearance="outline"
            controlName="reference"
        >
        </base-form-input>

        <base-form-input
            (autocompleteChange)="autocompleteSuppliers($event)"
            [items$]="getItems$('suppliers')"
            (onScroll)="onScrollLine($event, 'suppliers')"
            appearance="outline"
            controlName="suppliers"
            displayProperty="label"
            label="Fournisseur"
            type="search-multiple"
            withoutPadding="true"
        ></base-form-input>

        <base-form-input
            (autocompleteChange)="autocompleteGenericPrestations($event)"
            [items$]="getItems$('genericPrestations')"
            (onScroll)="onScrollLine($event, 'genericPrestations')"
            appearance="outline"
            controlName="genericPrestations"
            displayProperty="label"
            label="Prestation générique"
            type="search-multiple"
            withoutPadding="true"
        ></base-form-input>

        <base-form-input
            (autocompleteChange)="autocompletePrestationFields($event, 'label')"
            [items$]="getItems$('label')"
            (onScroll)="onScrollLine($event, 'label')"
            label="Libellé"
            withoutPadding="true"
            appearance="outline"
            displayProperty="label"
            controlName="labels"
            type="search-multiple"
        ></base-form-input>

        <base-form-input
            [items]="[{label: 'Interventions', value: 'Intervention'}, {label: 'Heures', value: 'Heure'}]"
            label="Unité d'achat"
            displayProperty="label"
            appearance="outline"
            type="select"
            controlName="purchaseUnit"
        ></base-form-input>

        <base-form-input
            [items]="[{label: 'Oui', value: true}, {label: 'Non', value: false}]"
            label="Disponible au catalogue"
            displayProperty="label"
            appearance="outline"
            type="select"
            controlName="isAvailable"
        ></base-form-input>

    </form>
</ng-template>