import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormModalBaseComponent} from '../../../../../core/base/components/form-modal-base/form-modal-base.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {EnergyIdService} from '../energy-id.service';
import {EstablishmentService} from '../../establishment/establishment.service';
import {EstablishmentModel} from '../../../../../core/models/establishment.model';
import {ObjectApiInterface} from '../../../../../core/base/interfaces/object-api.interface';
import {BuildingService} from '../../building/building.service';
import {BuildingModel} from '../../../../../core/models/building.model';
import {Observable} from 'rxjs';
import {ResponseTypeEnum} from '../../../../../core/base/enum/response-type.enum';
import {CrudModeEnum} from '../../../../../core/base/enum/crud-mode.enum';
import {EnergyIdModel} from '../../../../../core/models/energy-id.model';
import {map} from 'rxjs/operators';
import {GenericEnergyIdService} from '../../../settings/generic-energy-id/generic-energy-id.service';
import {GenericEnergyIdModel} from '../../../../../core/models/generic-energy-id.model';
import {GenericEnergyIdFormatEnum, GenericEnergyIdRestrictEnum} from '../../../../../core/enums/generic-energy-id.enum';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {SnackbarService} from '../../../../../core/services/snackbar.service';
import {ENUM_PERMISSIONS} from '../../../../../core/enums/permission.enum';
import {AuthService} from '../../../../../core/services/auth.service';
import { AutocompleteService } from '../../../../../core/services/scroll.service';

@Component({
    selector: 'vex-energy-id-create-update-delete',
    templateUrl: './energy-id-create-update-delete.component.html',
    styleUrls: ['./energy-id-create-update-delete.component.scss']
})
export class EnergyIdCreateUpdateDeleteComponent extends FormModalBaseComponent<EnergyIdModel> implements OnInit {
    genericEnergyIds$: Observable<GenericEnergyIdModel[]>;
    buildings$: Observable<BuildingModel[]>;
    defaultCustomFieldLabel = 'Choisissez d\'abord un type d\'énergie';
    customFieldLabel = this.defaultCustomFieldLabel;
    energyIdIdCustomError = null;
    isIDEnergyAlreayExist = false;
    itemCtrl = new FormControl();
    separatorKeysCodes: number[] = [ENTER, COMMA];
    isDuplicate = false;

    establishmentsOfBuilding: EstablishmentModel[] = [];
    favoriteBuildings: BuildingModel[] = [];
    uniqueEstablishmentsSet = new Set<EstablishmentModel>();
    isRequireBuilding = false;
    @ViewChild('itemInput') itemInputBuilding: ElementRef<HTMLInputElement>;

    canDownloadTemplateModel = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public dialogRef: MatDialogRef<FormModalBaseComponent<EnergyIdModel>>,
                private genericEnergyIdService: GenericEnergyIdService,
                public service: EnergyIdService,
                public establishmentService: EstablishmentService,
                public buildingService: BuildingService,
                private snackBarService: SnackbarService,
                private authService: AuthService,
                private autoCompleteService: AutocompleteService
    ) {
        super(data, dialogRef, service);
    }

    ngOnInit() {
        this.checkPermission();
        super.ngOnInit();
        this._initForm();
        this._initData();
        this.onAutocompleteInput();
        this.buildings$ = this.autoCompleteService.getItems$('buildings');
    }

    beforeCreateItem() {
        this.defaults.buildings = this.form.get('buildings').value;
        if (this.defaults.buildings.length <= 0) {
            this.isRequireBuilding = true;
        }
    }

    beforeUpdateItem() {
        super.beforeUpdateItem();
        this.defaults._id = this.data.defaults._id;
        if (this.defaults.buildings.length <= 0) {
            this.isRequireBuilding = true;
        }
    }

    createItem(): void {
        this.loading = true;
        this.defaults = this.form.getRawValue();
        this.beforeCreateItem();

        const result = this._parseData(this.defaults);
        if (this.form.valid) {
            const sub = this.service.create(this._parseData(this.defaults)).subscribe(
                result => {
                    this.afterCreateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Create, result.message);
                }, error => {
                    console.log(error, 'error');
                    if (error.error.statusCode === 5501) {
                        this.isIDEnergyAlreayExist = true;
                        this.energyIdIdCustomError = `Cet ID Energie existe déjà pour ce type d\'énergie`;
                    }

                    this.afterCreateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                }
            );
            this.subscription.add(sub);
        } else {
            console.log(this.form, 'form');
            this.onFormInvalid();
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    updateItem(): void {
        this.loading = true;
        this.defaults = this.form.getRawValue();
        this.beforeUpdateItem();

        if (this.isUpdateMode() && this.establishmentsOfBuilding.length <= 0) {
            this.data.establishments = this.defaults.establishments?.map(establishment => establishment._id) ?? null;
            this.establishmentsOfBuilding = this.defaults.establishments;
            this.establishmentsOfBuilding.length === 0 ? this.establishmentsOfBuilding = this.data.establishments : null;
        }

        if (this.form.valid && this.form.get('buildings').value.length > 0) {
            const sub = this.service.update(this._parseData(this.defaults)).subscribe(
                result => {
                    this.afterUpdateItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Update, result.message);
                }, error => {

                    if (error.error.statusCode === 5501) {
                        this.isIDEnergyAlreayExist = true;
                        this.energyIdIdCustomError = `Cet ID Energie existe déjà pour ce type d\'énergie`;
                    }

                    this.afterUpdateItem(null, error);
                    this.setErrorsMessages(error.error.errors);
                    this.loading = false;
                });
            this.subscription.add(sub);
        } else {
            this.onFormInvalid();
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }


    importItems() {

        const isImporting = true;
        const importStartTime = Date.now();

        this.importDefaults = this.importForm.value;
        this.beforeImportItem();
        if (this.importForm.valid) {
            this.loading = true;
            const sub = this.service.importItems(this.importDefaults).subscribe(
                result => {
                    this.afterImportItem(result, null);
                    this.setSnackbar(ResponseTypeEnum.Success, CrudModeEnum.Import, result.message);
                }, error => {
                    this.afterImportItem(null, error);
                    this.setImportErrorsMessages(error.error.errors);
                    this.loading = false;
                });

            setTimeout(() => {
                if (isImporting && !sub.closed) {
                    const importDuration = Date.now() - importStartTime;
                    if (importDuration >= 2000) {
                        this.snackbarService.warning('L\'importation peut prendre quelques secondes. Veuillez patienter.', 15000);
                    }
                }
            }, 2000);

            this.subscription.add(sub);
        } else {
            this.onFormInvalid();
            this.form.markAllAsTouched();
            this.loading = false;
        }
    }

    onFormInvalid() {
        super.onFormInvalid();

    }

    afterCreateItem(result?: any, error?: any) {
        if (result) {
            this.close(result.data);
        }
    }

    afterUpdateItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    afterImportItem(result?: any, error?: any) {
        if (result) {
            this.close(true);
        }
    }

    private _initForm() {
        if (this.isCreateMode()) {
            this.form = new FormGroup({
                genericEnergyId: new FormControl(null, Validators.required),
                energyIdId: new FormControl(null, Validators.required),
                buildings: new FormControl([], Validators.minLength(1)),
                establishments: new FormControl([]),
                favoriteBuilding: new FormControl(null, Validators.required)
            });
        }
        if (this.isUpdateMode()) {
            this.form = new FormGroup({
                genericEnergyId: new FormControl(this.defaults.genericEnergyId ? this.defaults.genericEnergyId : null, Validators.required),
                energyIdId: new FormControl(this.defaults.energyIdId ? this.defaults.energyIdId : null, Validators.required),
                buildings: new FormControl(this.defaults.buildings ? this.defaults.buildings : [], Validators.minLength(1)),
                establishments: new FormControl(this.defaults.establishments ? this.defaults.establishments : [], Validators.minLength(1)),
                favoriteBuilding: new FormControl(this.defaults.favoriteBuilding ? this.defaults.favoriteBuilding : null, Validators.required)
            });

            this.form.get('genericEnergyId').valueChanges.subscribe(val => {
                this.form.get('energyIdId').setValue(null);
            });

            this.favoriteBuildings = this.form.get('buildings').value;

        }

        // we disable energyIdId by default because it will be enabled when genericEnergyId is chosen
        if (this.isCreateMode()) {
            this.getFormControl('energyIdId').disable({
                onlySelf: true,
                emitEvent: false
            });
        }

        if (this.isUpdateMode()) {
            this.getFormControl('energyIdId').enable({
                onlySelf: true,
                emitEvent: false
            });
        }


        // Trigger events based on changes of form
        this.subscription.add(
            this.form.valueChanges.subscribe(val => {
                const genericEnergyId: GenericEnergyIdModel = val?.genericEnergyId;
                const buildings = val?.buildings;
                this.isIDEnergyAlreayExist = false;

                if (buildings) {
                    this.updateEstablishments(buildings);
                }

                if (genericEnergyId && this.getFormControl('genericEnergyId')?.valid) {
                    const energyIdIdFormControl: AbstractControl = this.getFormControl('energyIdId');

                    // Set custom field label depending on chosen generic energy id
                    this.customFieldLabel = val.genericEnergyId.fieldLabel;

                    // Then set the validators depending on the generic energy id chosen
                    const validators: ValidatorFn[] = [];

                    if (genericEnergyId.format !== GenericEnergyIdFormatEnum.NO_RESTRICTION) {
                        this.energyIdIdCustomError = null;

                        switch (genericEnergyId.restrict) {

                            case GenericEnergyIdRestrictEnum.FIXED:
                                validators.push(Validators.minLength(genericEnergyId.charactersNumber));
                                validators.push(Validators.maxLength(genericEnergyId.charactersNumber));
                                validators.push(Validators.required);
                                this.energyIdIdCustomError = `Le format de l'identifiant doit être de ${genericEnergyId.charactersNumber} caractères`;
                                break;
                            case GenericEnergyIdRestrictEnum.MAX:
                                validators.push(Validators.maxLength(genericEnergyId.charactersNumber));
                                validators.push(Validators.required);
                                this.energyIdIdCustomError = `Le format de l'identifiant doit être de maximum ${genericEnergyId.charactersNumber} caractères`;
                                break;
                        }

                        switch (genericEnergyId.format) {
                            case GenericEnergyIdFormatEnum.ALPHANUMERIC:
                                console.log('ALPHANUMERIC');
                                validators.push(Validators.pattern('^[a-zA-Z0-9]+$'));
                                this.energyIdIdCustomError += '\nL\'identifiant doit être alphanumérique';
                                break;
                            case GenericEnergyIdFormatEnum.NUMERIC:
                                console.log('NUMERIC');
                                validators.push(Validators.pattern('^[0-9]+$'));
                                this.energyIdIdCustomError += '\nL\'identifiant doit être numérique';
                                break;
                        }

                        energyIdIdFormControl.setValidators(validators);
                        energyIdIdFormControl.updateValueAndValidity({
                            onlySelf: true,
                            emitEvent: false
                        });
                    }

                    // Finally we enable the field
                    energyIdIdFormControl.enable({
                        onlySelf: true,
                        emitEvent: false
                    });
                } else {
                    // If for some reason the generic energy id is unselected, we make sure that energyIdId is reset
                    const energyIdIdFormControl: AbstractControl = this.getFormControl('energyIdId');

                    // First we disable it
                    energyIdIdFormControl.disable({
                        onlySelf: true,
                        emitEvent: false
                    });

                    // Then we reset its label, validators and value
                    this.customFieldLabel = this.defaultCustomFieldLabel;
                    energyIdIdFormControl.clearValidators();
                    energyIdIdFormControl.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false
                    });
                    energyIdIdFormControl.setValue(null, {
                        onlySelf: true,
                        emitEvent: false
                    });
                }
            })
        );
    }

    getFormControl(controlName: string): AbstractControl {
        return this.form.get(controlName);
    }

    private _initData() {
        this.genericEnergyIds$ = this.genericEnergyIdService.findAll().pipe(
            map((x: ObjectApiInterface<GenericEnergyIdModel[]>) => x.data)
        );

        this.buildings$ = this.buildingService.findAll(
            null,
            null,
            'label',
            'asc',
            null,
            {
                onlyMine: true,
                excludeItemsWithoutEstablishments: true,
            }
        ).pipe(
            map((x: ObjectApiInterface<BuildingModel[]>) => x.data)
        );
    }

    private _parseData(defaults: EnergyIdModel) {

        const data: any = {
            genericEnergyId: defaults.genericEnergyId._id,
            energyIdId: defaults.energyIdId,
            buildings: defaults.buildings?.map(building => building._id) ?? null,
            establishments: defaults.establishments?.map(establishment => establishment._id) ?? null,
            favoriteBuilding: defaults.favoriteBuilding
        };

        this.isUpdateMode() ? data._id = defaults._id : null;
        return data;
    }

    genericEnergyIdsAutoCompleteChange(state: any) {
        this.genericEnergyIds$ = this.genericEnergyIdService.findAll(null,
            null,
            null,
            null,
            state
        ).pipe(map((x: ObjectApiInterface<GenericEnergyIdModel[]>) => x.data));
    }


    updateEstablishments(buildings: any[]) {
        const uniqueEstablishments = new Map();
        const establishmentFormControl = this.getFormControl('establishments');

        buildings.forEach(building => {
            building.establishments.forEach(establishment => {
                uniqueEstablishments.set(establishment._id, establishment);
            });
        });

        this.establishmentsOfBuilding = Array.from(uniqueEstablishments.values());

        establishmentFormControl.setValue(this.establishmentsOfBuilding, { emitEvent: false });
        establishmentFormControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });

        this.isRequireBuilding = false;
        this.onAutocompleteInput();
        this.favoriteBuildings = buildings;
    }

    onAutocompleteInput(event?: Event, pageToGo?: 'next' | 'previous'): void {
        const inputValue = (event?.target as HTMLInputElement)?.value ?? '';

        this.autoCompleteService.autocompleteInfiniteScroll(
            inputValue,
            'buildings',
            '_id',
            pageToGo,
            (page, size) =>
                this.buildingService.findAll(page, size, 'label', 'asc', inputValue, {
                    onlyMine: true,
                    excludeItemsWithoutEstablishments: true,
                })
        )?.subscribe();
    }

    checkPermission() {
        this.subscription.add(
            this.authService.getCurrentUserPermissions$().subscribe(permissions => {
                if (permissions.includes(ENUM_PERMISSIONS.READ_ENERGY_ID)) {
                    this.canDownloadTemplateModel = true;
                }
            })
        );
    }

    genericBuildingsAutoCompleteChange(state: any) {
        this.buildings$ = this.buildingService.findAll(null,
            null,
            null,
            null,
            state
        ).pipe(map((x: ObjectApiInterface<BuildingModel[]>) => x.data));
    }

    onSelectBuildings(event: Array<BuildingModel>): void {
        console.log('event', event);
        this.updateEstablishments(event);
    }

    onScrollLine(pageToGo: 'next' | 'previous', filterField: string): void {
        switch (filterField) {
            case 'buildings':
                this.onAutocompleteInput(null, pageToGo);
                break;
        }
    }
}
